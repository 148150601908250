/* eslint-disable */
<template>
  <div class="create-booking">
    <AdminNavigation ref="navigation" indexProps="5" />
    <div class="top-panel no-sub-title">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>{{ setTitle() }}</h1></div>
      </div>
    </div>
    <div class="panel no-sub-title">
      <AdminBookingsMenu :key="0" :indexProps="0" />
      <div class="content">
        <div class="top-detail pt-2">
          <div class="row m-0">
            <div class="col-4 p-0 center-title">
              <div class="row center-title m-0 display-in-row" v-if="!bookingObject.isSupplementary">
                <div class="col-2 p-0" v-if="booking.bookingDateTime != ''">
                  <div class="border-image">
                    <div class="border-image-number">{{formatDate(booking.bookingDateTime, 'DD', 'YYYY-MM-DD HH:mm:ss')}}</div>
                    <div class="border-image-text">{{formatDate(booking.bookingDateTime, 'MMM, YYYY hh:mm A', 'YYYY-MM-DD HH:mm:ss')}}</div>
                  </div>
                </div>
                <div class="col center-title ml-4">
                  {{getShortLocationName()}}
                </div>
              </div>
            </div>
            <div class="col-3 center-title p-0">
              <div v-if="this.clientCase|| booking.claimantFullName != '' && booking.dateOfBirth != '' && booking.claimTypeName != ''">
                <div class="name" v-if="booking.claimantFullName">{{booking.claimantFullName}}</div>
                <p v-if="booking.dateOfBirth != ''">D.O.B.  {{formatDate(booking.dateOfBirth, 'DD/MM/YYYY')}}</p>
                <p v-if="booking.claimTypeName != ''">Type:  {{booking.claimTypeName}}</p>
                <p v-if="booking.caseOwnerName != ''">Case Owner:  {{booking.caseOwnerName}}</p>
              </div>
            </div>
            <div class="col p-0">
              <div class="row m-0">
                <div class="col-2 p-0">
                  <div class="rounded-image medium-rounded-image m-t-0 m-t-b" v-if="specialist.profileThumbnail">
                    <div class="profile-image-content"><img :src="specialist.profileThumbnail" /></div>
                  </div>
                  <div class="box-col" v-else>
                    <div class="rounded-image medium-rounded-image m-t-0 m-t-b">
                      <div class="profile-image-content notice-background"><s class="text-initials">{{getInitials(specialist)}}</s></div>
                    </div>
                  </div>
                </div>
                <div class="col center-title ml-4">
                  <div>
                    <div class="name">
                      {{specialist.fullName}}
                      <img v-if="requiresTravel" class="icon-20" src="@/assets/images/travel_black_circle_icon.svg" />
                      <img v-if="videoLinkAvailable == 2" class="icon-20 ml-2" src="@/assets/images/video_black_icon.svg" />
                    </div>
                    <div>
                      {{getSpecialistInfo(specialist.practiceLocationId, specialist.locations)}}
                    </div>
                    <div>
                      {{getBookingTypeById(this.booking.bookingTypeId)}}
                    </div>
                    <div v-if="booking.videoLinkTestStatus" class="is-icon"><img src="@/assets/images/video_black_icon.svg" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="box case-container remove-min-height">
          <div class="box-heading d-flex align-items-center">case Details</div>
          <div class="box-body">
            <div class="box-row">
              <div class="box-col box-col-left left-label">Client</div>
              <div class="box-col box-col-right align-items-center">
                <Dropdown
                  class="client-selecter select-dropdown w-95"
                  :options="listClients"
                  v-on:selected="clientSelected"
                  v-on:filter="filterClients"
                  :maxItem="10000"
                  placeholder="Select a Client">
                </Dropdown>
              </div>
            </div>
            <div class="box-row" v-if="client.id">
              <div class="box-col box-col-left">&nbsp;</div>
              <div class="box-col box-col-right">
                <span>Client: {{ client.name }}</span>
                <span class="ml-4">
                  {{getCaseOwnerName()}}
                </span>&nbsp;&nbsp;
                <span class="ml-4">P:
                  {{getCaseOwnerPhoneNumber()}}
                </span>&nbsp;&nbsp;
                <u @click="openEmailModal('client')">
                  <span class="ml-4">E:
                    {{ getCaseOwnerEmail() }}
                  </span>
                </u>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">case Details</div>
              <div class="box-col box-col-right">
                <div class="right-element">
                  <router-link v-if="client.id" :to="{ name: 'admin-manage-case', query: { clientId: client.id } }" target="_blank">
                    <img src="@/assets/images/add_black_icon.svg" />
                  </router-link>
                </div>
                <Dropdown
                  class="case-selecter select-dropdown w-95"
                  :options="listCases"
                  v-on:selected="caseSelected"
                  v-on:filter="filterCases"
                  :disabled="false"
                  name="zipcode"
                  :maxItem="10000"
                  placeholder="Select a case">
                </Dropdown>
                <div v-if="hasError && !validateCase" class="text-danger">
                  case Details is required.
                </div>
              </div>
            </div>
            <div class="box-row" v-if="clientCase.id != ''">
              <div class="box-col box-col-left left-label">
                Claimant Details
              </div>
              <div class="box-col box-col-right">
                D.O.B: {{ formatDate(clientCase.dateOfBirth, "DD/MM/YYYY")
                }}<span class="ml-3">Type: {{ clientCase.claimTypeName }}</span>
              </div>
            </div>
            <div class="box-row" v-if="clientCase.id != ''">
              <div class="box-col box-col-left">&nbsp;</div>
              <div class="box-col box-col-right">
                Ph: {{ clientCase.claimantPhone1
                }}<span class="ml-3">M: {{ clientCase.claimantMobile }}</span
                ><span class="ml-3">Ph: {{ clientCase.claimantPhone2 }}</span
                ><span class="ml-3"
                  ><u @click="openEmailModal('claimant')"
                    >E: {{ clientCase.claimantEmail }}</u
                  ></span
                >
              </div>
            </div>
            <div class="box-row" v-if="clientCase.id != ''">
              <div class="box-col box-col-left left-label">Address</div>
              <div class="box-col box-col-right">{{ formattedAddress }}</div>
            </div>

            removed it form case section and added it to booking section
            <div class="box-row" v-if="clientCase.id != ''">
              <div class="box-col box-col-left left-label">
                Date of Accident
              </div>
              <div class="box-col box-col-right">
                {{
                  clientCase.accidentDate == "0000-00-00"
                    ? ""
                    : clientCase.overPeriodofTime
                    ? "Over a period of time"
                    : formatDate(clientCase.accidentDate, "DD/MM/YYYY")
                }}
              </div>
            </div>
            <div class="box-row" v-if="clientCase.id != ''">
              <div class="box-col box-col-left left-label">
                Affected Body Areas
              </div>
              <div class="box-col box-col-right">
                <div class="tag-div">
                  <vue-tags-input
                    v-model="bodyAreasTag"
                    :tags="bodyAreasTags"
                    placeholder="Affected Body Areas"
                    :disabled="true"
                  />
                </div>
              </div>
            </div>
            <div class="box-row" v-if="clientCase.id != ''">
              <div class="box-col box-col-left left-label">Other Body Areas</div>
              <div class="box-col box-col-right">
                <div class="tag-div">
                  <vue-tags-input
                    :disabled="true"
                    v-model="otherBodyAreasTag"
                    :tags="otherBodyAreasTags"
                    placeholder=""
                    @tags-changed="(newTags) => (otherBodyAreasTags = newTags)" />
                </div>
              </div>
            </div>
            <div class="box-row" v-if="clientCase.id != ''">
              <div class="box-col box-col-left left-label">Conditions</div>
              <div class="box-col box-col-right">
                <div class="tag-div" :class="{ focused: focused }">
                  <vue-tags-input
                    v-model="conditionTag"
                    :tags="conditionTags"
                    placeholder="Conditions"
                    :disabled="true"
                  />
                </div>
              </div>
            </div>
            <div class="box-row" v-if="clientCase.id != ''">
              <div class="box-col box-col-left left-label">On Behalf Of</div>
              <div class="box-col box-col-right">
                <input
                  :disabled="true"
                  type="text"
                  v-model="clientCase.onBehalfOf"
                  placeholder="Enter on behalf of"
                />
              </div>
            </div>

          </div>
        </div> -->
        <div class="box" v-if="displayBookingDetails">
          <div class="box-heading d-flex align-items-center">Booking Details</div>
          <div class="box-body">
            <!-- <div class="box-row">
              <div class="box-col box-col-left left-label">Booking Type</div>
              <div class="box-col box-col-right">
                <select
                  v-model="booking.bookingTypeId"
                  class="textbox w-100">
                  <option value="" hidden>Please choose ...</option>
                  <option :value="type.id" v-for="type in bookingTypes">
                    {{ type.name }}
                  </option>
                </select>
                <div
                  v-if="hasError && !validateBookingType"
                  class="text-danger">
                  Booking Type is required.
                </div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Booking Status</div>
              <div class="box-col box-col-right">
                <select
                  v-model="booking.bookingStatus"
                  class="textbox w-100"
                  @change="changeBookingStatusName()">
                  <option value="" hidden>Please choose ...</option>
                  <option
                    :value="bookingStatus.id"
                    v-for="bookingStatus in bookingStatuses">
                    {{ bookingStatus.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Specialist</div>
              <div class="box-col box-col-right">
                <Dropdown
                  class="specialist-selecter select-dropdown"
                  :options="listSpecialists"
                  v-on:selected="selectedSpecialist"
                  v-on:filter="filterSpecialist"
                  :disabled="false"
                  name="zipcode"
                  :maxItem="10000"
                  placeholder="Select a specialist">
                </Dropdown>
                <div v-if="hasError && !validateSpecialist" class="text-danger">
                  Specialist is required.
                </div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Location</div>
              <div class="box-col box-col-right">
                <Dropdown
                  class="select-dropdown"
                  :options="listLocations"
                  v-on:selected="selectedLocation"
                  v-on:filter="filterLocation"
                  :disabled="false"
                  name="zipcode"
                  :maxItem="10000"
                  placeholder="Select a specialist">
                </Dropdown>
                <div v-if="hasError && !validateLocation" class="text-danger">
                  Location is required.
                </div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Date &amp; Time</div>
              <div class="box-col box-col-right d-flex align-items-center calender-item">
                <div class="w-50">
                  <datepicker
                    v-model="booking.bookingDateTime"
                    format="dd/MM/yyyy"
                    :typeable="true"
                    class="w-90"
                    input-class="textbox"
                    :disabled-dates="disabledDates"
                    placeholder="dd/mm/yyyy"
                    @input="fetchTimes()"
                  ></datepicker>
                </div>
                <div class="w-35">
                  <select
                    id="timepicker"
                    v-model="booking.bookingTime"
                    @change="changeBookingTime()">
                    class="textbox w-90">
                    <option value="" disabled>Select a time slot</option>
                    <option  v-for="(item, index) in availableTimes" :value="item.time">{{ item.time }}</option>
                  </select>
                </div>
                <div v-if="hasError && !validateDateTime" class="text-danger">
                  Date & Time is required.
                </div>
              </div>
            </div> -->
            <div class="box-row" v-if="bookingObject.isNewCase">
              <div class="box-col box-col-left left-label">Client</div>
              <div class="box-col box-col-right align-items-center">
                <Dropdown
                  ref="clientDropdown"
                  class="client-selecter select-dropdown disabled-element"
                  :options="listClients"
                  v-on:selected="clientSelected"
                  v-on:filter="filterClients"
                  :maxItem="10000"
                  :disabled="true"
                  placeholder="Select a Client">
                </Dropdown>
                <div class="text-danger mt-5" v-if="hasError && !validateClientContact">
                  Client Details is required.
                </div>
              </div>
            </div>
            <div class="box-row" v-if="showClientContact">
              <div class="box-col box-col-left left-label required">Client Contact</div>
              <div class="box-col box-col-right align-items-center d-flex">
                <div class="d-flex align-items-center w-100">
                  <Dropdown ref="contactDropdown"
                            class="contact-selecter select-dropdown w-100"
                            :options="listContact"
                            v-on:selected="selectedContact"
                            v-on:filter="filterReportTo"
                            :maxItem="10000"
                            placeholder="Select a contact">
                  </Dropdown>
                  <button class="btn" data-toggle="tooltip" data-placement="right" title="Create New Contact" @click="addNewContact">
                    <img class="w-75" src="@/assets/images/add_black_icon.svg" />
                  </button>
                </div>
                <div class="text-danger mt-5" v-if="hasError && !validateCaseOwner">
                  Client Contact is required.
                </div>
              </div>
            </div>
            <div class="box-row" >
              <div :class="booking.bookingTypeName === 'Medical Negligence Request' || booking.bookingTypeName === 'FCE Lite by Video' || booking.bookingTypeName === 'FCE Lite' || booking.bookingTypeName === 'Supplementary' || booking.bookingTypeName === 'File Review' ? 'disabled-element' : ''" class="box-col box-col-left left-label required">Booking Type</div>
              <div class="box-col box-col-right">
                <select
                    :class="booking.bookingTypeName === 'Medical Negligence Request' || booking.bookingTypeName === 'FCE Lite by Video' || booking.bookingTypeName === 'FCE Lite' || booking.bookingTypeName === 'Supplementary' || booking.bookingTypeName === 'File Review' ? 'disabled-element' : ''"
                  :disabled="booking.bookingTypeName === 'Medical Negligence Request' || booking.bookingTypeName === 'FCE Lite by Video' || booking.bookingTypeName === 'FCE Lite' || booking.bookingTypeName === 'Supplementary' || booking.bookingTypeName === 'File Review'"
                  v-model="booking.bookingTypeId"
                  @change="onChangeBookingType"
                  class="textbox w-100">
                  <option value="" hidden>Please choose ...</option>
                  <option :value="type.id" v-for="type in bookingTypes" :key="type.id">
                    {{ type.name }}
                  </option>
                </select>
                <div v-if="hasError && !validateBookingType" class="text-danger">
                  Booking Type is required.
                </div>
              </div>
            </div>
            <div class="box-row" v-if="!disableCaseField" :class="disableCaseField ? 'disabled-element' : ''">
              <div class="box-col box-col-left left-label required">Claimant Name</div>
              <div class="box-col box-col-right">
                <div v-if="!isEditName" @click="openAddNameModal" :key="nameKey" class="parent is-icon full-width-div">
                  <input type="text" disabled class="child px-3 placeholder-opacity" placeholder="Please click here to enter the Claimant details"/>
                </div>
                <span v-else :key="nameKey" @click="openAddNameModal" class="padding-left-10">
                  {{ this.booking.claimantFullName }}
                </span>
                <div class="text-danger" v-if="hasError && !validateClaimantName">
                  Please enter claimant's name
                </div>
              </div>
              <AddNameModal ref="addNameModal"/>
            </div>
            <div class="box-row" v-if="!disableCaseField" :class="disableCaseField ? 'disabled-element' : ''">
              <div class="box-col box-col-left left-label">Claimant Salutation</div>
              <div class="box-col box-col-right">
                <el-select :disabled="disableCaseField" class="w-50" v-model="booking.Salutation" filterable clearable placeholder="Claimant Salutation">
                  <el-option v-for="item in salutationList" :key="item.value" :value="item.value" :label="item.value"></el-option>
                </el-select>
              </div>
            </div>
            <div class="box-row" v-if="!disableCaseField" :class="disableCaseField ? 'disabled-element' : ''">
              <div  class="box-col box-col-left left-label">Claimant Preferred Name</div>
              <div class="box-col box-col-right">
                <input :disabled="disableCaseField" class="textbox referance" type="text" placeholder="Claimant Preferred Name" v-model="booking.PreferredGivenName"/>
              </div>
            </div>
            <div class="box-row" v-if="!disableCaseField" :class="disableCaseField ? 'disabled-element' : ''">
              <div class="box-col box-col-left left-label">Date of Birth</div>
              <div class="box-col box-col-right box-col-center">
                <datepicker
                  class="w-50 date-of-birth"
                  :disabled="disableCaseField"
                  v-model="booking.dateOfBirth"
                  format="dd/MM/yyyy" :typeable="true"
                  placeholder="dd/mm/yyyy"></datepicker>
                  <div v-if="hasError && !validateDateOfBirth" class="text-danger">Date of Birth is required.</div>
              </div>
            </div>
            <div class="box-row" v-if="!disableCaseField" :class="disableCaseField ? 'disabled-element' : ''">
              <div  class="box-col box-col-left left-label">Reference Number</div>
              <div class="box-col box-col-right">
                <input :disabled="disableCaseField" class="textbox referance" type="text" placeholder="Reference Number" v-model="booking.caseReference"/>
                <div v-if="hasError && !validateReferenceNumber" class="text-danger">Reference Number can not contain ' " or `</div>
              </div>
            </div>
            <div class="box-row" :class="disableCaseField ? 'disabled-element' : ''">
              <div class="box-col box-col-left left-label">Claimant Contact Details</div>
              <div class="box-col box-col-right">
                <div v-if="!isEditContact" @click="openManageContactDetails" class="parent is-icon full-width-div">
                  <input type="text" class="child placeholder-opacity" disabled placeholder="Please click here to enter contact details" />
                </div>
                <span v-else @click="openManageContactDetails" class="padding-left-10">
                  <span v-if="this.booking.claimantMobile" class='text-bold'>M: </span> {{ this.booking.claimantMobile }}
                  <span v-if="this.booking.claimantEmail" class='text-bold'>E: </span> {{ this.booking.claimantEmail }}
                  <span v-if="this.booking.claimantPhone1" class='text-bold'>P: </span> {{ this.booking.claimantPhone1 }}
                  <span v-if="this.booking.claimantPhone2" class='text-bold'>L: </span> {{ this.booking.claimantPhone2 }}
                </span>
                <div v-if="hasError && !validateAttendance && !disableCaseField" class="text-danger" style="position: absolute; z-index: 1; top: 30px;">Contact Details are required.</div>
                <AddClientContactModal ref="addClientContactModal" :typrProps="'fromBooking'"/>
              </div>
            </div>
            <div class="box-row" v-if="!disableCaseField">
              <div class="box-col box-col-left left-label">Claimant Address</div>
              <div class="box-col box-col-right">
                <div v-if="!isEditAddress" class="parent is-icon full-width-div" @click="openManageAddress" :key="addressKey">
                  <input class="child placeholder-opacity w-100" disabled placeholder="Please click here to update claimant's address" />
                </div>
                <span v-else @click="openManageAddress" :key="addressKey">{{booking.claimantFullAddress}}</span>
                <div  class="is-icon right-float-element" @click="openManageAddress">
                </div>
                <AddClientAddressModal ref="addClientAddressModal" :typrProps="'fromBooking'"/>
              </div>
            </div>
            <div v-if="showLinkLocation" class="box-row">
              <div class="box-col box-col-left left-label">Examinee Location for VideoLink</div>
              <div class="box-col box-col-right">
                <input :disabled="booking.bookingStatus === 6"  type="text" class="px-3" maxlength="255" v-model="booking.ClaimantLocation" placeholder="Examinee Location for VideoLink" />
              </div>
            </div>
            <div v-if="booking.bookingTypeId == 4 || booking.bookingTypeId == 7 || booking.bookingTypeId == 15" class="box-row">
              <div class="box-col box-col-left left-label">Claimant State During Booking</div>
              <div class="box-col box-col-right">
                <div class="pl-2">Which state will your claimant be located in for the appointment?</div>
                <select class="textbox" v-model="booking.claimantStateDuringBooking" :class="hasError && !validateState ? 'is-invalid' : ''">
                  <option value="">Please Select ...</option>
                  <option v-for="state in states" :value="state.value">{{ state.text }}</option>
                </select>
                <div v-if="hasError && !validateState" class="text-danger pl-2">State is required.</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Booking Details</div>
              <div class="box-col box-col-right d-flex align-items-center">
                <img
                  class="ml-2 is-icon"
                  src="@/assets/images/claimant_attendance_icon.svg"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="getAttendanceTitle"
                  :data-original-title="getAttendanceTitle"
                  @click="toggleAttendance()"
                  :class="booking.claimantConfirmed == true ? '' : 'disabled-element'"
                />
                <img
                  class="ml-4 is-icon"
                  src="@/assets/images/otfce_icon.svg"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="getOTFCETitle"
                  :data-original-title="getOTFCETitle"
                  @click="toggleOTFCE()"
                  :class="booking.otfceRequired == true ? '' : 'disabled-element'" />
                <img
                  class="ml-2 is-icon"
                  src="@/assets/images/can_ring_claimant_icon.svg"
                  @click="toggleCanRingClaimant()"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="getCanRingClaimantTitle"
                  :data-original-title="getCanRingClaimantTitle"
                  :class="booking.redHealthMayContactClaimant == true ? '' : 'disabled-element'" />
                <img
                  class="ml-2 is-icon"
                  src="@/assets/images/consent_received_icon.svg"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="getConsentReceivedTitle"
                  :data-original-title="getConsentReceivedTitle"
                  @click="toggleConsentReceived()"
                  :class="booking.claimantConsentReceived == true ? '' : 'disabled-element'" />
                <img
                  class="is-icon"
                  src="@/assets/images/call_after_appointment_icon.svg"
                  @click="toggleCallAfterAppointment()"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="getCallAfterAppointmentTitle"
                  :data-original-title="getCallAfterAppointmentTitle"
                  :class="booking.callClientAfterAppointment == true ? '' : 'disabled-element'" />
                <img
                  class="ml-4 is-icon"
                  src="@/assets/images/video_link_test_arranged_icon.svg"
                  @click="toggleVideoLinkTestArranged()"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="getVideoLinkTestArrangedTitle"
                  :data-original-title="getVideoLinkTestArrangedTitle"
                  :class="booking.videoLinkTestStatus == true ? '' : 'disabled-element'"
                  v-if="booking.bookingTypeId == 4"
                />
                <img
                  class="ml-2 is-icon"
                  src="@/assets/images/video_link_test_icon.svg"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="getHasVideoTitle"
                  :data-original-title="getHasVideoTitle"
                  @click="toggleHasVideo()"
                  :class="booking.hasVideoLink == true ? '' : 'disabled-element'"
                  v-if="booking.bookingTypeId == 4"
                />
              </div>
            </div>

            <div class="box-row">
              <div class="box-col box-col-left left-label">On behalf of</div>
              <div class="box-col box-col-right">
                <el-select v-model="booking.onBehalfOf" class="w-100" filterable clearable>
                  <el-option v-for="item in originalListClients" :key="item.id" :value="item.fullName" :label="item.fullName"></el-option>
                </el-select>
              </div>
            </div>

            <div class="box-row">
              <div class="box-col box-col-left left-label">Date of Accident/Event</div>
              <div class="box-col box-col-right box-col-center" style="display: flex;">
                <datepicker
                  :disabled="booking.overPeriodofTime"
                  class="w-50 accient-date"
                  format="dd/MM/yyyy"
                  :typeable="true"
                  placeholder="dd/mm/yyyy"
                  @selected="changeDate"
                  v-model="booking.accidentDate">
                </datepicker>
                  <div style="display: flex;align-items: center; padding-left: 10px;">
                    <toggle-button  @change="onChartToggleChange(4)" class="togglePeriodOfTime" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.overPeriodofTime"/>  Over a period of time
                  </div>
              </div>
            </div>
            <div class="box-row" :class="disableCaseField ? 'disabled-element' : ''">
              <div class="box-col box-col-left left-label required">Type of Claim</div>
              <div class="box-col box-col-right">
                <div v-if="!claimFocus && (booking.claimTypeId == '' || booking.claimTypeId == undefined)" @click="claimFocus = true" class="parent">
                  <input class="child w-50" type="text" disabled placeholder="Click to choose the Type of Claim" :class="hasError && !validateTypeOfClaim ? 'is-invalid' : ''" />
                </div>
                <select :disabled="disableCaseField" v-model="booking.claimTypeId" @change="changedClaimantType($event)" v-else :class="hasError && !validateTypeOfClaim ? 'is-invalid' : ''" class="w-50 textbox">
                  <option value="" hidden>Please choose type of claim...</option>
                  <option v-for="claimType in claimTypes" :value="claimType.id">{{ claimType.name }}</option>
                </select>
                <div class="text-danger" v-if="hasError && !validateTypeOfClaim">
                  Please choose type of claim
                </div>
              </div>
            </div>
            <div class="box-row">
              <div  class="box-col box-col-left left-label">Affected Body Areas</div>
              <div class="box-col box-col-right box-col-center">
                <vue-tags-input
                  class="pr-10"
                  v-model="bodyAreaTag"
                  :tags="bodyAreaTags"
                  placeholder="Add Affected Body Areas"
                  :autocomplete-items="filteredBodyAreaItems"
                  @tags-changed="(newTags) => (bodyAreaTags = newTags)"
                  @focus="openDigram()"
               />
              </div>
              <div @click="openBodyDiagram" class="center-item">
                <img src="@/assets/images/choose.svg" alt="" class="right-element align-self-center is-icon pr-3"/>
              </div>
              <AddBodyDiagramModal style="top: 0px" ref="addBodyDiagramModal" />
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Other Body Areas</div>
              <div class="box-col box-col-right box-col-center">
                <vue-tags-input
                  v-model="otherBodyAreaTag"
                  :tags="otherBodyAreaTags"
                  :placeholder="this.booking.caseId == '' ? 'Add Other Body Areas':''"
                  @tags-changed="(newTags) => (otherBodyAreaTags = newTags)" />
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Conditions</div>
              <div class="box-col box-col-right box-col-center">
                <vue-tags-input
                  ref="conditions"
                  v-model="personConditionTag"
                  :tags="personConditionTags"
                  :add-only-from-autocomplete="true"
                  placeholder="Add Conditions"
                  :autocomplete-items="filteredPersonConditionsItems"
                  @tags-changed="updateConditions"
                />
              </div>
            </div>

            <div class="box-row">
              <div class="box-col box-col-left left-label">
                Interpreter Details
              </div>
              <div class="box-col box-col-right">
                <input
                  type="text"
                  v-model="interpreterDetails"
                  maxlength="255"
                  placeholder="Click to add interpreter details if required"
                />
                <div v-if="hasError && !validInterpreterDetails" class="text-danger">Please do not start with '=' or  '-'  or  '+' or  '@' or  '%' and do not contains '--' or '||' or '@@' </div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">
                Surrogate Details
              </div>
              <div class="box-col box-col-right">
                <input
                  type="text"
                  v-model="surrogateDetails"
                  maxlength="255"
                  placeholder="Click to add surrogate details if required"
                />
                <div v-if="hasError && !validSurrogateDetails" class="text-danger">Please do not start with '=' or  '-'  or  '+' or  '@' or  '%' and do not contains '--' or '||' or '@@' </div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Paperwork Due</div>
              <div class="box-col box-col-right">
                <datepicker
                  v-model="booking.paperworkDue"
                  class="w-50"
                  format="dd/MM/yyyy"
                  :typeable="true"
                  input-class="textbox w-90"
                  placeholder="dd/mm/yyyy"></datepicker>
                <!-- <span class="right-element">LOI Received</span> -->
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Send Report To</div>
              <div class="box-col box-col-right">
                <el-select @change="selectedSendTo" placeholder="Select a Client"  class="w-100" v-model="booking.reportTo" filterable clearable>
                  <el-option v-for="contact in listBillTo" :key="contact.value" :value="contact.value" :label="contact.name">{{ contact.name }}</el-option>
                </el-select>
              </div>
            </div>
            <div v-if="showSendReportToOther" class="box-row" >
              <div  class="box-col box-col-left left-label">Send report Email</div>
              <div class="box-col box-col-right">
                <input class="textbox" type="text" placeholder="Send report to this email address" v-model="reportToText"/>
                <div v-if="hasError && !validateSendEmail" class="text-danger">Email is incorrect.</div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Payment Details</div>
              <div class="box-col box-col-right d-flex align-items-center">
                <img
                  v-if="client.paymentDeferralOption"
                  class="is-icon"
                  src="@/assets/images/payment_deferral_icon.svg"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Payment deferral"
                  @click="toggleFeeDeferralRequired()"
                  :class="
                    booking.feeDeferralRequired == true
                      ? ''
                      : 'disabled-element'
                  "
                />
                <img
                  class="ml-2"
                  src="@/assets/images/prepayment_required_icon.svg"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Prepayment required"
                  :class="
                    booking.prepaymentRequired == true ? '' : 'disabled-element'
                  "
                />
                <!-- <img class="ml-4" src="@/assets/images/finance_black_icon.svg" @click="togglePaid()" :class="booking.paid == true ? '' : 'disabled-element'" /> -->
                <img
                  class="ml-4 is-icon"
                  src="@/assets/images/specialist_black_icon.svg"
                  @click="toggleSpecialistConfirmedHours()"
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Specialist confirmed hours"
                  :class="
                    booking.specialistConfirmedHours == true
                      ? ''
                      : 'disabled-element'
                  "
                />
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Bill To</div>
              <div class="box-col box-col-right">
                <el-select @change="selectedBillTo" placeholder="Select a Client" class="w-100" v-model="booking.billTo" filterable clearable>
                  <el-option v-for="contact in listBillTo" :key="contact.value" :value="contact.value" :label="contact.name">{{ contact.name }}</el-option>
                </el-select>
              </div>
            </div>
            <div v-if="showBillToEmail" class="box-row">
              <div class="box-col box-col-left left-label">Bill to Email</div>
              <div class="box-col box-col-right">
                <input class="textbox" type="text" placeholder="Bill to this email address" v-model="billToText"/>
                <div v-if="hasError && !validateBillEmail" class="text-danger">Email is incorrect.</div>
              </div>
            </div>
            <div class="box-row" v-for="(hour, index) in preapprovedHours">
              <div class="box-col box-col-left left-label">
                {{ index == 0 ? "Pre-Approved Hours" : "" }}
              </div>
              <div class="box-col box-col-right d-flex align-items-center">
                {{ hour.service }}:
                {{
                  hour.numberOfHours +
                  (hour.numberOfHours == 1 ? " Hour" : " Hours")
                }}
                <div>
                  <v-btn class="add-icon add-black-icon" style="background-color: #c4c4c4; color: #6686B6F" @click="openServiceTimeModalPreApproved" v-if="index == 0">Add New</v-btn>
                </div>
              </div>
            </div>
            <div class="box-row" v-if="preapprovedHours.length == 0">
              <div class="box-col box-col-left left-label">
                Pre-Approved Hours
              </div>
              <div class="box-col box-col-right d-flex align-items-center">
                <div>
                  <v-btn class="add-icon add-black-icon" style="background-color: #c4c4c4; color: #6686B6F" @click="openServiceTimeModalPreApproved">Add New</v-btn>
                </div>
              </div>
            </div>
            <div class="box-row" v-for="(hour, index) in booking.bookingHours">
              <div class="box-col box-col-left left-label">
                {{ index == 0 ? "Additional Time" : "" }}
              </div>
              <div class="box-col box-col-right d-flex align-items-center">
                {{ hour.service }}:
                {{
                  hour.numberOfHours +
                  (hour.numberOfHours == 1 ? " Hour" : " Hours")
                }}
                <div>
                  <v-btn class="add-icon add-black-icon" style="background-color: #c4c4c4; color: #6686B6F" @click="openServiceTimeModal"v-if="index == 0">Add New</v-btn>
                </div>
              </div>
            </div>
            <div class="box-row" v-if="booking.bookingHours.length == 0">
              <div class="box-col box-col-left left-label">Additional Time</div>
              <div class="box-col box-col-right d-flex align-items-center">
                <div>
                  <v-btn class="add-icon add-black-icon" style="background-color: #c4c4c4; color: #6686B6F" @click="openServiceTimeModal">Add New</v-btn>
                </div>
              </div>
            </div>
            <AdminAddServiceTimeModal
              ref="adminAddServiceTimeModal"
              :bookingProps="booking"
              :roleProps="roleProps"
              fromProps="create"
            />
            <RequestActionModal ref="requestActionModal" />
          </div>
        </div>
        <div v-if="displayBookingDetails" class="box-row item-right box-col-next">
          <div v-if="displayBookButton"><v-btn dark @click="createBooking()" dark>Book</v-btn></div>
          <div v-else><v-btn dark @click="tapNext()">Next</v-btn></div>
        </div>

        <div class="box" v-if="displayBookingSummery" :key="summaryKey">
          <div class="box-heading col-space-between">
            <div>Booking Summary</div>
          </div>
          <div class="box-body">
            <!-- <div class="box-row" v-if="!this.isWaitlisted">
              <div class="box-col box-col-left">Private</div>
              <div class="box-col box-col-right">
                <toggle-button data-placement="right" data-toggle="tooltip" title="No other user will see the details of this booking" @change="onChartToggleChange(5)" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.private" />
              </div>
            </div> -->
            <!-- renamed OTFCE -->
            <div class="box-row" v-if="!this.hideOtherStatus">
              <div class="box-col box-col-left">Is a Plus Service required for this booking? <img class="help ml-2" data-html="true" rel="tooltip" data-toggle="tooltip" data-placement="right"
                title="A combined service conducted by the examiner and an OT. It is a modified FCE known as FCElite which provides further robustness to the examiner's report."
                src="@/assets/images/infoicon.svg" style="width: 25px;" /></div>
              <div class="box-col box-col-right">
                <toggle-button @change="onChartToggleChange(6)" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.otfceRequired" />
              </div>
            </div>
            <div class="box-row" v-if="!this.hideOtherStatus">
              <div class="box-col box-col-left">Do you need a phone call with the doctor after the appointment? (additional charges may apply)</div>
              <div class="box-col box-col-right">
                <toggle-button @change="onChartToggleChange(7)" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.callClientAfterAppointment"/>
              </div>
            </div>
            <div class="box-row" v-if="!this.hideOtherStatus && !hideAttendance">
              <div class="box-col box-col-left">Red Health to confirm attendance with Claimant</div>
              <div class="box-col box-col-right">
                <toggle-button @change="onChartToggleChange(8)" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.redHealthMayContactClaimant"/>
              </div>
            </div>
            <div class="box-row" v-if="this.client.paymentDeferralOption">
              <div class="box-col box-col-left">Is Fee Deferral required?</div>
              <div class="box-col box-col-right">
                <toggle-button @change="onChartToggleChange(9)" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.feeDeferralRequired"/>
              </div>
            </div>
            <div class="box-row" v-if="this.booking.id">
              <div class="box-col box-col-left">Reason for Reschedule</div>
              <div class="box-col box-col-right">
                  <textarea v-model="reason" placeholder="Enter Reschedule reason" rows="1" maxlength="100"/>
                  <div class="text-danger" v-if="hasError && !validateReschedule">
                    Please enter the Reschedule reason
                  </div>
              </div>
            </div>
            <div  v-if="booking.bookingTypeId != 6 && booking.bookingTypeId != 8 && !hideWarning" class="box-row box-warning">
              <div class="box-col Warning-left">
                Warning: non-attendance by the claimant may attract a fee.
              </div>
            </div>
          </div>
        </div>
        <div class="box-row item-right" v-if="displayBookingSummery">
          <div style="padding-right:20px"><v-btn  class="btn-light" @click="tapEdit()">Back</v-btn></div>
          <div><v-btn dark @click="createBooking()" dark>Book</v-btn></div>
        </div>

        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="alert alert-danger hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> {{ errorMessage }}
        </div>

        <div class="box remove-min-height" v-if="displayBookingDetails">
          <div class="box-heading d-flex align-items-center">
            Tests Ordered
            <div class="right-element">
              <v-btn class="add-icon" dark @click="openTestsModal"
                >Add New</v-btn
              >
              <AddSpecialistBookingTestModal
                ref="addSpecialistBookingTestModal"
                :bookingProps="booking"
                :testsProps="test"
                fromProps="create"
              />
            </div>
          </div>

          <div
            v-if="booking.bookingTests.length > 0"
            v-for="(bookingTest, index) in booking.bookingTests"
          >
            <div class="box-body">
              <div class="box-row">
                <div class="box-col col">{{ bookingTest.testName }}</div>
                <div class="box-col col">{{ bookingTest.orderedFrom }}</div>
                <div class="box-col col">
                  <div class="left-label" v-if="bookingTest.dateOrdered != ''">
                    Ordered: {{ bookingTest.dateOrdered }}
                  </div>
                </div>
                <div class="box-col col">
                  <div class="left-label" v-if="bookingTest.dateReceived != ''">
                    Received: {{ bookingTest.dateReceived }}
                  </div>
                </div>
                <div class="box-col col center-title">
                  <img
                    class="right-align is-icon"
                    src="@/assets/images/edit_icon.svg"
                    @click="setTestAndOpenModal($event, bookingTest, index)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else class="box-row"></div>
        </div>

        <div class="box" v-if="displayBookingDetails">
          <div class="box-heading tab">
            <div
              class="tab-heading tab-selected px-5"
              id="heading-notes"
              @click="openTab(0)">Notes</div>
            <div
              class="tab-heading px-5"
              id="heading-files"
              @click="openTab(1)">Files</div>
            <div
                class="tab-heading px-5"
                id="heading-billing"
                @click="openTab(2)">Billing Notes</div>
            <div class="right-element">
              <v-btn class="add-icon" dark @click="openAddNew">Add New</v-btn>
              <AddNoteModal :isBilling="isBilling"
                ref="addNoteModal"
                :bookingProps="booking"
                fromProps="admin"
              />
            </div>
          </div>
          <div class="box-body tab-content tab-content-boxes hidden-element">
            <div class="box-row overflow-hidden" v-for="(note, index) in booking.bookingNotes" :key="index">
              <div class="box-col">
                {{ note.userName }}
              </div>
              <div class="box-col">{{ formatDate(note.noteDateTime, "DD/MM/YYYY hh:mm A", "YYYY-MM-DD HH:mm") }}</div>
              <div
                class="box-col"
                data-toggle="tooltip"
                data-placement="right"
                @click="openEditNoteModal(index, note)"
                :title="note.note">
                {{ note.note }}
              </div>
              <div v-if="!note.isHide" class="is-icon right-element" @click="deleteNote(index)">
                <img class="mr-4" v-if="note.internalOnly == 1" src="@/assets/images/eye-private.svg" />
                <img src="@/assets/images/delete_icon.svg" />
              </div>
            </div>
            <div class="box-row" v-for="note in bookingAction">
              <!-- <div class="box-col box-col-left">{{ note.userName }}</div> -->
              <div class="box-col box-col-left">&nbsp;</div>
              <div class="box-col box-col-left">
                {{ formatDate(note.dateRequested, "DD/MM/YYYY hh:mm A") }}
              </div>
              <div
                class="box-col box-col-right box-col-long"
                style="max-width: 60%"
              >
                <strong>Requested Action: </strong>{{ note.requestedAction }}
              </div>
              <div class="is-icon" style="width: 5%">
                <!-- <img src="@/assets/images/delete_icon.svg" @click="deleteNote(note.id)" /> -->
              </div>
            </div>
          </div>

          <div class="box-body tab-content tab-content-boxes hidden-element">
            <div>
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 15%" class="long-text">File Type</th>
                      <th style="width: 50%" class="long-text">File Name</th>
                      <th style="width: 15%" class="long-text">Uploaded By</th>
                      <th style="width: 10%" class="long-text">Status</th>
                      <th style="width: 10%" class="icon-col">
                        <div class="icon-group">&nbsp;</div>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div
                class="content-scroll-y"
                style="height: auto; max-height: 460px"
              >
                <table class="table remove-min-height">
                  <tbody :key="fileKey">
                    <tr
                      v-for="(file, index) in booking.bookingFiles"
                      :key="index"
                      class="py-0"
                    >
                      <th style="width: 15%" class="long-text">
                        <select disabled
                          class="textbox"
                          v-model="file.fileTypeId"
                          @change="changeBookingFileType(file, file.fileTypeId)"
                          :class="file.isHide ? 'disabled-element' : ''"
                          :disabled="file.isHide">
                          <option value="" hidden>Select File Type</option>
                          <option
                            v-for="fileType in fileTypes"
                            :value="fileType.id">
                            {{ fileType.name }}
                          </option>
                        </select>
                        <!-- <span v-else>{{ file.fileTypeName }}</span> -->
                      </th>
                      <th style="width: 50%" class="long-text">
                        <el-tooltip  effect="dark" :content="file.fileName">
                          <span> {{ file.fileName }}</span>
                        </el-tooltip>
                      </th>
                      <th style="width: 15%" class="long-text">
                        {{ file.uploadedByName }}
                      </th>
                      <th style="width: 10%" class="long-text">
                        <select disabled
                          class="textbox"
                          v-model="file.fileStatus"
                          @change="changeBookingFileStatus(file.bookingFileId)"
                          :class="file.isHide ? 'disabled-element' : ''"
                          :disabled="file.isHide">
                          <option value="0">Draft</option>
                          <option value="1">Final</option>
                        </select>
                        <!-- <span v-else>Final</span> -->
                      </th>
                      <th style="width: 10%; vertical-align: middle;" class="icon-col">
                        <div v-if="!file.isHide" class="icon-group d-flex justify-content-center">
                          <img
                            src="@/assets/images/delete_icon.svg"
                            @click="deleteFile(index)"
                          />
                        </div>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="box-col">
              <div
                class="file-upload-area border-gray-300 mt-5 mb-5"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop">
                <input
                  type="file"
                  multiple
                  name="fields[assetsFieldHandle][]"
                  id="assetsFieldHandle"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  @change="onChange"
                  ref="file"
                  accept=".pdf,.jpg,.jpeg,.png,.docx,.xlsx,.txt,.gif,.ds2,.mp3,.mp4,.mov,.AVI,.MKV" />
                <label for="assetsFieldHandle" class="block cursor-pointer d-flex flex-column align-items-center justify-content-center">
                  <div>Drop files in this area or <span style="text-decoration: underline" class="is-icon">click here</span> to upload files.</div>
                  <div>Files over 200MB in file size may cause errors. If you need to upload files of this size, please contact us on 1300 100 733</div>
                </label>
              </div>
            </div>
          </div>

          <div v-if="isBilling"  class="box-body tab-content tab-content-boxes">
            <div class="box-row" v-for="(note, index) in booking.billingNotes" :key="index">
              <div class="box-col">
                {{ note.userName }}
              </div>
              <div class="box-col">{{ formatDate(note.noteDateTime, "DD/MM/YYYY hh:mm A", "YYYY-MM-DD HH:mm") }}</div>
              <div
                  class="box-col"
                  data-toggle="tooltip"
                  data-placement="right"
                  @click="openEditNoteModal(index, note)"
                  :title="note.note">
                {{ note.note }}
              </div>
              <div v-if="!note.isHide" class="is-icon right-element" @click="deleteNote(index)">
                <img src="@/assets/images/delete_icon.svg" />
              </div>
            </div>
            <div class="box-row" v-for="note in bookingAction">
              <!-- <div class="box-col box-col-left">{{ note.userName }}</div> -->
              <div class="box-col box-col-left">&nbsp;</div>
              <div class="box-col box-col-left">
                {{ formatDate(note.dateRequested, "DD/MM/YYYY hh:mm A") }}
              </div>
              <div
                  class="box-col box-col-right box-col-long"
                  style="max-width: 60%"
              >
                <strong>Requested Action: </strong>{{ note.requestedAction }}
              </div>
              <div class="is-icon" style="width: 5%">
                <!-- <img src="@/assets/images/delete_icon.svg" @click="deleteNote(note.id)" /> -->
              </div>
            </div>
          </div>

          <div class="alert alert-warning confirmation hidden-element">
            <a class="close" title="close" @click="tapButtonConfirmation(false)"
              >×</a
            >
            <b>Warning:</b> Are you sure? Your changes will take effect
            immediately.
            <div class="icon-group">
              <v-btn class="alert-button" @click="tapButtonConfirmation(true)"
              >Yes</v-btn
              >
              <v-btn class="alert-button" @click="tapButtonConfirmation(false)"
              >No</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="Add New Contact"
               :visible.sync="showContactModal"
               @close="cancelDialog">
      <AdminEditClientContact :clientIdProps="client.id" @contactCreated="contactCreated" />
    </el-dialog>
    <DuplicateCaseModal :dialogVisible="dialogVisible" :hasToChooseCase="hasToChooseCase" @selectCase="selectCase" :result="result" @closeModal="closeModal"/>
    <el-dialog title="Select a new case owner"
               :visible.sync="showSelectOwnerModal"
               @close="showSelectOwnerModal = false">
      <div>
        <p>Current case owner is none or inactive, please select a new one:</p>
        <el-select class="mt-2 w-100" v-model="newCase">
          <el-option v-for="item in clientContacts" :key="item.clientContactId" :value="item.clientContactId" :label="item.clientContactId + ' - ' + item.fullName + ' - ' + item.emailAddress">{{ item.clientContactId + " - " + item.fullName + " - " + item.emailAddress }}</el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="showSelectOwnerModal = false">Cancel</el-button>
    <el-button type="primary" @click="onSaveNewOwner">Save</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities";
import AdminNavigation from "@/components/AdminNavigation";
import AdminBookingsMenu from "@/components/AdminBookingsMenu";
import Header from "@/components/Header";
import AddNoteModal from "@/components/AddNoteModal";
import AdminAddServiceTimeModal from "@/components/AdminAddServiceTimeModal";
import AddSpecialistBookingTestModal from "@/components/AddSpecialistBookingTestModal";
import RequestActionModal from "@/components/RequestActionModal";
import ItemTemplate from '@/components/ItemTemplate.vue'
import Dropdown from 'vue-simple-search-dropdown';
import AddBodyDiagramModal from '@/components/AddBodyDiagramModal'
import AddNameModal from '@/components/AddNameModal'
import AddClientContactModal from '@/components/AddClientContactModal'
import AddClientAddressModal from '@/components/AddClientAddressModal'
import AdminEditClientContact from '@/components/AdminEditClientContact'
import DuplicateCaseModal from '@/components/case/DuplicateCaseModal'

export default {
  name: "AdminManageBooking",
  props: ["bookingProps"],
  components: {
    AdminNavigation,
    AdminBookingsMenu,
    Header,
    AddNoteModal,
    AdminAddServiceTimeModal,
    AddSpecialistBookingTestModal,
    RequestActionModal,
    Dropdown,
    AddBodyDiagramModal,
    AddNameModal,
    AddClientContactModal,
    AddClientAddressModal,
    DuplicateCaseModal,
    AdminEditClientContact
  },
  data() {
    return {
      newCase: null,
      hasToChooseCase: false,
      showLinkLocation: true,
      showSelectOwnerModal: false,
      billToText: '',
      reportToText: '',
      dialogVisible: false,
      result: [],
      curIndex: null,
      salutationList: utilities.initSalutation(),
      isBilling: false,
      itemTemplate: ItemTemplate,
      focused: "",
      focusDropdown: false,
      specialistFocus: false,
      bookingFocus: false,
      locationFocus: false,
      bookingTypeFocus: false,
      attendanceStatusFocus: false,
      paperworkStatusFocus: false,
      sendReportToFocus: false,
      onBehalfOfFocus: false,
      billToFocus: false,
      preApprovedHoursFocus: false,
      hoursForFocus: false,
      interpreterRequired: false,
      surrogateRequired: false,
      isValidAccidentDate: true,
      hasError: false,
      test: -1,

      preapprovedHours: [],
      requestedExtraHours: [],

      showSendReportToOther: false,
      showBillToEmail: false,
      listContact: [],
      listBillTo: [],
      originalClientContacts: [],

      overTime: false,
      bodyAreaTag: "",
      otherBodyAreaTag: "",
      personConditionTag: "",
      bodyAreas: [],
      bodyAreaTags: [],
      otherBodyAreaTags: [],
      personConditions: [],
      personConditionTags: [],
      requiresTravel: false,
      videoLinkAvailable: false,
      disableCaseField: false,
      isEditName: false,
      isEditContact: false,
      isEditAddress: false,
      claimFocus: false,
      showClientContact: false,
      addressKey: false,
      nameKey: 0,
      bookingObject: [],
      displayBookingSummery: false,
      displayBookingDetails: true,
      displayBookButton: false,
      summaryKey: 1,
      showBookingStatus: true,
      hideOtherStatus: true,
      hideAttendance: false,
      hideWarning: false,
      showBookingType: false,
      selected: 0,
      values: [
				{ label: 'Confirmed', value: 3, text: 'This will lock in the appointment you have selected' },
				{ label: 'Tentative', value: 2,text: 'This will hold the appointment for you for 24 hours. If you haven\'t confirmed the booking before this time, the appointment will be released, and someone may book that appointment.' },
			],

      specialists: [],
      clients: [],
      specialist: utilities.initSpecialist(),
      client: utilities.initClient(),
      originalListClients: [],
      originalListCases: [],
      originalListLocations: [],
      originalListSpecialists: [],
      listClients: [],
      listCases: [],
      listLocations: [],
      listSpecialists: [],
      item: {},
      cases: [],
      clientCase: utilities.initCase(),
      states: [],
      bookingRequest: [],
      addedBodyArea: [],
      deletedBodyTags: [],
      addedConditions: [],

      filelist: [],
      locations: [],
      clientContacts: [],

      standardFiles: [],
      availableTimes: [],
      minimumAppointment: [],
      user: _.cloneDeep(this.$store.getters["user/getUser"]),

      bookingStatuses: [],
      bookingTypes: [],
      bookingTypesOriginal: [],
      bookingTypesLocal: utilities.getCreateSpecialistsBookingTypes(),
      claimTypes: [],
      booking: utilities.initBooking(),
      validSurrogateDetails: true,
      bookingLogs: [],
      availability: [],
      timelineData: [],
      validInterpreterDetails: true,
      reports: [
        {
          id: 1,
          name: "John",
        },
        {
          id: 2,
          name: "Kate",
        },
      ],
      // deleteId: "",
      deleteIndex: "",
      roleProps: "admin",

      sendToProps: "",
      showContactModal: false,
      successMessage: "",
      errorMessage: "",
      emails: {},
      bookingAction: [],
      fileTypes: [],
      fileKey: 1,
      users: [],
      disabledDates: {},
      timeSlots: [],
      bookingTime: "",
      reason: "",
    };
  },
  computed: {
    validateReferenceNumber() {
      if (!utilities.validateNameText(this.booking.caseReference)) {
        return false
      }
      return true
    },
    validateCaseOwner() {
      if(this.bookingObject.isNewCase && !this.booking.caseOwner) {
        return false
      }
      return true
    },
    validateAttendance() {
      if (this.booking.bookingTypeId == 8 || this.booking.bookingTypeId == 6) {
        return true
      } else if (this.disableCaseField) {
        return true
      } else if (this.booking.claimantConfirmed && this.booking.claimantEmail == '' && this.booking.claimantPhone1 == '' && this.booking.claimantPhone2 == '' && this.booking.claimantMobile == '') {
        return false
      }
      return true
    },
    validateDateOfBirth() {
      if (this.booking.dateOfBirth === '' || this.booking.dateOfBirth === 'Invalid date') {
        return false
      }
      return true
    },
    validateClientContact() {
      if (this.bookingObject.isNewCase && this.booking.clientId == '') {
        return false
      }
      return true
    },
    validateTypeOfClaim() {
      if (this.booking.caseId == '' && (this.booking.claimTypeId == '' || this.booking.claimTypeId == undefined)) {
        return false
      }
      return true
    },

    validateClaimantName() {
      if (this.booking.claimantFirstName == '' || this.booking.claimantSurname == '') {
        return false
      }
      return true
    },

    validateSendEmail() {
      if (this.showSendReportToOther) {
        if(this.reportToText != "" && !utilities.validateEmail(this.reportToText)) {
          return false
        }
      }
      return true
    },

    validateBillEmail() {
      if (this.showBillToEmail) {
        if(this.billToText != "" && !utilities.validateEmail(this.billToText)) {
          return false
        }
      }
      return true
    },

    getAttendanceTitle() {
      var title = "Claimant attendance " + (this.isDate(this.booking.claimantConfirmed) || this.booking.claimantConfirmed ? "" : "not ") + "confirmed"
      return title
    },
    getOTFCETitle() {
      var title = "OTFCE " + (this.booking.otfceRequired ? "" : "not ") + "required"
      return title
    },
    getCanRingClaimantTitle() {
      var title = this.booking.redHealthMayContactClaimant ? "Can ring claimant" : "Can not ring claimant"
      return title
    },
    getConsentReceivedTitle() {
      var title = "Claimant consent " + (this.isDate(this.booking.claimantConsentReceived) || this.booking.claimantConsentReceived ? "" : "not ") + "received"
      return title
    },
    getCallAfterAppointmentTitle() {
      var title = (this.booking.callClientAfterAppointment ? "Phone " : "No ") + "call required"
      return title
    },
    getVideoLinkTestArrangedTitle() {
      var title = "Video link test " + (this.booking.videoLinkTestStatus == 1 || this.booking.videoLinkTestStatus == 2  ? "" : "not ") + "arranged"
      return title
    },
    getHasVideoTitle() {
      var title = "Video link test " + (this.booking.videoLinkTestStatus == 2 ? "" : "not ") + "completed"
      return title
    },

    filteredBodyAreaItems() {
      return this.bodyAreas.filter((i) => {
        return i.text.toLowerCase().indexOf(this.bodyAreaTag.toLowerCase()) !== -1
      })
    },

    filteredPersonConditionsItems() {
      return this.personConditions.filter((i) => {
        return i.text.toLowerCase().indexOf(this.personConditionTag.toLowerCase()) !== -1
      })
    },
    validateBookingType() {
      if (this.booking.bookingTypeId == '') {
        return false;
      }
      return true;
    },

    // validateCase() {
    //   if (this.clientCase.id == "") {
    //     return false;
    //   }
    //   return true;
    // },

    // validateSpecialist() {
    //   if (this.specialist.id == "") {
    //     return false;
    //   }
    //   return true;
    // },

    // validateLocation() {
    //   if (this.booking.locationId == "") {
    //     return false;
    //   }
    //   return true;
    // },

    // validateDateTime() {
    //   if (this.booking.bookingDateTime == "") {
    //     return false;
    //   } else if (this.booking.bookingTime == "") {
    //     return false;
    //   }
    //   return true;
    // },

    validateState() {
      if ((this.booking.bookingTypeId == 4 || this.booking.bookingTypeId == 7) && this.booking.claimantStateDuringBooking == '') {
        return false
      }
      return true
    },

    validateReschedule() {
      if (this.reason == "") {
        return false
      }
      return true
    },

    formattedAddress() {
      return (
        this.clientCase.claimantAddressLine1 +
        (this.clientCase.claimantAddressLine1 != "" &&
        this.clientCase.claimantAddressLine2 != ""
          ? " "
          : "") +
        this.clientCase.claimantAddressLine2 +
        (this.clientCase.claimantAddressLine1 != "" ||
        this.clientCase.claimantAddressLine2 != ""
          ? ", "
          : "") +
        (this.clientCase.claimantCity != "" ? ", " : "") +
        this.clientCase.claimantState +
        " " +
        this.clientCase.claimantPostcode
      );
    },
    additionalHours() {
      return this.booking.bookingHours.filter(
        (x) => x.role === "Specialist" || x.role === "Client"
      );
    },
    interpreterDetails: {
      get: function () {
        return this.booking.interpreterDetails;
      },
      set: function (newValue) {
        if (newValue.trim() !== "") {
          this.booking.interpreterRequired = true
          if(!utilities.validateInput(newValue)) {
            this.validInterpreterDetails = false
          } else {
            this.validInterpreterDetails = true
          }
        } else {
          this.validInterpreterDetails = true
          this.booking.interpreterRequired = false
        }
        this.booking.interpreterDetails = newValue;
      },
    },
    surrogateDetails: {
      get: function () {
        return this.booking.surrogateDetails;
      },
      set: function (newValue) {
        if (newValue.trim() !== "") {
          this.booking.surrogateRequired = true
          if(!utilities.validateInput(newValue)) {
            this.validSurrogateDetails = false
          } else {
            this.validSurrogateDetails = true
          }
        }
        else {
          this.booking.surrogateRequired = false
          this.validSurrogateDetails = true
        }
        this.booking.surrogateDetails = newValue;
      },
    },
  },
  methods: {
    async onSaveNewOwner() {
      const c = this.clientContacts.find(item => item.clientContactId === this.newCase)
      this.booking.caseOwner = c.userId;
      let objs = {
        CaseID: this.clientCase.id,
        ClientID: this.client.id,
        CurrentClientContactID: this.newCase,
        UserID: this.user.id,
        Username: this.user.name,
      }
      this.$store.dispatch("loading/setLoadComponent", true);
      await utilities.changeCaseOwnerByCaseID(objs)
      this.showSelectOwnerModal = false
      await this.createBooking(true)
    },
    changeBookingFileType(file, typeId) {
    },

    changeBookingFileStatus() {
    },

    contactCreated() {
      this.cancelDialog();
      this.changeClient()
    },
    cancelDialog() {
      this.showContactModal = false
    },
    addNewContact() {
      this.showContactModal = true
    },

    onChangeBookingType(e) {
      this.booking.bookingTypeName = this.bookingTypes.find(item => item.id == this.booking.bookingTypeId).name
      this.checkBookingType()
    },

    getInitials(specialist) {
      var initials = ""
      if (specialist.firstName) {
        initials = specialist.firstName.substring(0,1)
      }

      if (specialist.lastName) {
        initials = initials + specialist.lastName.substring(0,1)
      }
      return initials
    },

    defineClearButton() {
      $(".report-to-selecter input").after("<div class='dropdown-input-clear'></div>")
      $(".report-to-selecter .dropdown-input-clear").click((e) => {
        this.$refs.dropdownEmailSendTo.searchFilter = ""
        this.booking.reportTo = ""
        this.showSendReportToOther = false
      })

      $(".bill-to-selecter input").after("<div class='dropdown-input-clear'></div>")
      $(".bill-to-selecter .dropdown-input-clear").click((e) => {
        this.$refs.dropdownEmailBillTo.searchFilter = ""
        this.booking.billTo = ""
        this.showBillToEmail = false
      })
    },

    setTitle() {
      if (this.displayBookingDetails) {
        if (this.bookingObject.waitlisted && this.bookingObject.caseId == '') {
          return "Create a Waitlist Booking - case Details"
        } else if (this.bookingObject.waitlisted && this.bookingObject.caseId != '') {
          return "Create a Waitlist Booking" + this.getCaseTitle()
        } else {
          if (this.bookingObject.caseId != '') {
            return "Create a Booking" + this.getCaseTitle()
          } else {
            return "Create a Booking - case Details"
          }
        }
      } else {
        if (this.bookingObject.caseId != '' && this.bookingObject.waitlisted) {
          return "Waitlist Booking Summary" + this.getCaseTitle()
        } else if (this.bookingObject.waitlisted) {
          return "Waitlist Booking Summary"
        } else if (this.bookingObject.caseId != '') {
          return "Booking Summary" + this.getCaseTitle()
        }  else {
          return "Booking Summary"
        }
      }
    },

    changedBookingStatus(e) {
      if (e == 0) {
        this.selected = 0
        this.booking.bookingStatus = 3
        this.booking.bookingStatusName = "Confirmed"
      } else if (e == 1) {
        this.selected = 1
        this.booking.bookingStatus = 2
        this.booking.bookingStatusName = "Tentative"
      } else {
        this.booking.bookingStatus = 1
        this.booking.bookingStatusName = "Draft"
      }
    },

    tapEdit() {
      var originalBooking = _.cloneDeep(this.booking)
      var originalClient = _.cloneDeep(this.client)
      var showSendReportToOther =  this.showSendReportToOther
      var showBillToEmail = this.showBillToEmail

      this.displayBookingDetails = true
      this.displayBookingSummery = false

      setTimeout(() => {
        this.showSendReportToOther = showSendReportToOther
      }, 100)

      setTimeout(() => {
        this.showBillToEmail = showBillToEmail
      }, 200)

      setTimeout(() => {
        this.booking.reportTo = originalBooking.reportTo
        this.booking.billTo = originalBooking.billTo
        this.booking.caseOwner = originalBooking.caseOwner
        this.client.id = originalClient.id

        // Reload Client
        var selectedClient = this.originalListClients.find((x) => x.id == this.client.id)
        if (selectedClient && this.$refs.clientDropdown) {
          this.$refs.clientDropdown.selected = selectedClient
          this.$refs.clientDropdown.searchFilter = selectedClient.name
        }

        // Reload Client Contact
        var selectedContact = this.listContact.find((x) => x.id == this.booking.caseOwner)
        if (selectedContact && this.$refs.contactDropdown) {
          this.$refs.contactDropdown.selected = selectedContact
          this.$refs.contactDropdown.searchFilter = selectedContact.name
        }

        // Reload Report To
        var sendReportTo = null
        if(!this.showSendReportToOther) {
          sendReportTo = this.listBillTo.find((x) => x.value == this.booking.reportTo)
        } else {
          sendReportTo = this.listBillTo.find((x) => x.id == -1)
          this.reportToText = this.booking.reportTo
        }

        // Reload Bill To
        var billTo = null
        if(!this.showBillToEmail) {
          billTo = this.listBillTo.find((x) => x.value == this.booking.billTo)
        } else {
          billTo = this.listBillTo.find((x) => x.id == -1)
          this.billToText = this.booking.billTo
        }

        this.defineClearButton()
      }, 300)
    },

    setDateInfo() {
      var text = ""
      if (this.booking.bookingTypeId == 7) {
        text = `<b>At:</b> ` + this.formatDate(this.booking.bookingDateTime, "hh:mm A dddd DD/MM/YYYY", "YYYY-MM-DD HH:mm:ss.ssssss")
      } else {
        text = `<b>At:</b> ` + this.formatDate(this.booking.bookingDateTime, "hh:mm A dddd DD/MM/YYYY", "YYYY-MM-DD HH:mm:ss.ssssss") + ' ' + this.getLocationName(this.booking.locationId)
      }
      return text
    },

    openManageAddress() {
      if (!this.disableCaseField) {
        this.$refs.addClientAddressModal.booking = this.booking
        this.$refs.addClientAddressModal.isClient = false
        this.$refs.addClientAddressModal.setBookingAddressDetails()
        this.$refs.addClientAddressModal.handleModal()
      }
    },

    changedClaimantType(event) {
      var item = this.claimTypes.find((x) => x.id == event.target.value)
      this.booking.claimTypeName = item.name
    },

    setName(object) {
      this.booking.claimantFirstName = object.firstName
      this.booking.claimantGivenNames = object.middleName
      this.booking.claimantSurname = object.surname
      this.booking.claimantFullName = utilities.getClaimantFullName(this.booking)
      if (this.booking.claimantFullName != "") {
        this.isEditName = true
      } else {
        this.isEditName = false
      }
      this.nameKey += 1
    },

    getCaseTitle() {
      if (this.clientCase != undefined && this.clientCase.caseReference != '') {
        return  ' - case Ref ' + this.clientCase.caseReference
      }
      return ''
    },
    changeDate() {
      this.booking.overPeriodofTime = false
    },

    onChartToggleChange(expression) {
      switch (expression) {
        case 4:
        this.booking.overPeriodofTime = !this.booking.overPeriodofTime
          if (this.booking.overPeriodofTime) {
            this.booking.accidentDate = ""
          }
          break;
        case 6:
          this.booking.otfceRequired = !this.booking.otfceRequired
          break;
        case 7:
          this.booking.callClientAfterAppointment = !this.booking.callClientAfterAppointment
          break;
        case 8:
          this.booking.redHealthMayContactClaimant = !this.booking.redHealthMayContactClaimant
          if (!this.booking.redHealthMayContactClaimant) {
            this.hideWarning = false
          } else {
            this.hideWarning = true
          }
          break;
        case 9:
          this.booking.feeDeferralRequired = !this.booking.feeDeferralRequired
          if (!this.booking.feeDeferralRequired && !this.client.paymentDeferralOption) {
            this.displayBanner = true
            this.bannerKey += 1
          } else {
            this.displayBanner = false
          }
          break;

      }
    },

    openAddNameModal() {
      if (!this.disableCaseField) {
        if (this.isEditName) {
          this.$refs.addNameModal.editFullName(this.booking.claimantFirstName, this.booking.claimantGivenNames, this.booking.claimantSurname)
        }
        this.$refs.addNameModal.handleModal();
      }
    },

    openManageContactDetails() {
      if (!this.disableCaseField) {
        this.$refs.addClientContactModal.singleCase = this.singleCase
        this.$refs.addClientContactModal.booking = this.booking
        this.$refs.addClientContactModal.setBookingContactDetails()
        this.$refs.addClientContactModal.handleModal()
      }
    },

    getShortLocationName() {
      if (this.booking.locationId && this.locations.length > 0) {
        return this.locations.find((x) => x.id == this.booking.locationId).shortLocation
      }
      return ""
    },

    getSpecialistInfo(locationId, locations) {
      var text = ""
      if (this.specialist.qualificationList) {
        text = this.specialist.qualificationList
        return text
      }

      // if (locationId) {
      //   var value = locations.find((x) => x.id == locationId).shortLocation
      //   if (value) {
      //     return text + ", " + value
      //   }
      // }
      return ""
    },

    getBookingTypeById(typeId) {
      if (typeId) {
        var type = this.bookingTypes.find((x) => x.id == typeId)
        if (type) {
          return type.name
        }
      }
      return ""
    },

    openDigram() {
      this.openBodyDiagram()
    },

    openBodyDiagram() {
      this.$refs.addBodyDiagramModal.handleModal()
      this.$refs.addBodyDiagramModal.filteredBodyAreas = this.bodyAreas
      this.$refs.addBodyDiagramModal.bodyAreas = _.cloneDeep(this.bodyAreaTags)
      this.$refs.addBodyDiagramModal.handleClick()
    },

    setBodyArea(addedBodyAreas) {
      this.bodyAreaTags = []
      this.bodyAreaTags = addedBodyAreas
    },

    deleteBodyAreaItems(items) {
      items.map((x) => {
        this.deletedBodyTags.push(x.tag)
      })
    },

    changeAccientDate() { //removed it from case
      setTimeout(() => {
        this.isValidAccidentDate = this.validateAccidentDate()
      }, 200)
    },
    changeOverTime() { //removed it from case
      if(this.overTime) this.clientCase.accidentDate = ''
      this.isValidAccidentDate = this.validateAccidentDate()
    },

    updateConditions(newTags) { //removed it from case
      if(this.$refs.conditions.newTag.length == 0) newTags.pop()
      this.personConditionTags = newTags
    },

    validateAccidentDate() {
      if ($(".accient-date input").val().length > 0) {
        if((this.clientCase.accidentDate == '' || !moment($(".accient-date input").val(), "DD/MM/YYYY").isValid()) && !this.overTime) {
          return false
        }
      }
      return true
    },

    filterReportTo(text) {
      this.listClients = this.originalClientContacts.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1);
    },

    selectedSendTo(item) {
      if (item === 'Other') {
        this.showSendReportToOther = true
          this.reportToText = ""
      } else {
        this.showSendReportToOther = false
      }
    },

    selectedBillTo(item) {
      if (item === 'Other') {
        this.showBillToEmail = true
        this.billToText = ""
      } else {
        this.showBillToEmail = false
      }
    },

    selectedContact(item) {
      this.$set(this.booking, 'caseOwner', item.id)
    },

    openTab(index) {
      if(index === 2) {
        this.isBilling = true
      } else {
        this.isBilling = false
      }
      utilities.openTab(index)
    },
    openAddNew() {
      if($(".tab-content").eq(1).is(":visible")) this.openAddFile()
      else this.openAddNoteModal()
    },
    openAddNoteModal() {
      this.curIndex = null
      this.$refs.addNoteModal.addNewNote()
      this.$refs.addNoteModal.isNewNote = true
      this.$refs.addNoteModal.handleModal()
    },
    openEditNoteModal(index, noteObj) {
      this.$refs.addNoteModal.internalOnly = noteObj.internalOnly
      this.$refs.addNoteModal.isNewNote = false
      this.$refs.addNoteModal.editCurrentNote(noteObj.note, true)
      this.$refs.addNoteModal.handleModal()
      this.curIndex = index
    },
    adminEditNoteToBooking(notes) {
      const obj = {
        note: notes,
        noteDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        userName: this.user.name,
      }
      if(this.isBilling) {
        this.$set(this.booking.billingNotes, this.curIndex, obj)
      } else {
        obj.internalOnly = this.$refs.addNoteModal.internalOnly
        this.$set(this.booking.bookingNotes, this.curIndex, obj)
      }
    },
    openAddFile() {
      $('#assetsFieldHandle').trigger('click')
    },
    changeLocation() {
      if(this.specialist.id != '') {
        //this.getSpecialistAvailability()
      }
    },

    changeBookingTime() {
      this.availability.map((x) => {
        x.slots.map((i) => {
          if (i.time == this.booking.bookingTime) {
            this.booking.specialistAvailabilityId = x.availabilityId
          }
        })
      })
    },

    clientSelected(item) {
      if (item) {
        this.client.id = item.id
        if (this.client.id) {
          this.showClientContact = true
          this.changeClient()
        }
      }
    },
    filterClients(text) {
      this.listClients = this.originalListClients.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1);
    },

    caseSelected(item) {
      if (item.id) {
        this.item = item
        this.clientCase.id = item.id
        if (this.clientCase.id) {
          this.changeCase()
        }
      }
    },
    filterCases(text) {
      this.listCases = this.originalListCases.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1);
    },

    selectedSpecialist(item) {
      if (item.id) {
        this.specialist.id = item.id
        if (this.specialist.id ) {
          this.changeSpecialist()
        }
      }
    },
    filterSpecialist(text) {
      this.listSpecialists = this.originalListSpecialists.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1);
    },

    selectedLocation(item) {
      if (item.id) {
        this.booking.locationId = item.id
        if (this.booking.locationId ) {
          this.changeLocation()
        }
      }
    },
    filterLocation(text) {
      this.listLocations = this.originalListLocations.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1);
    },

    onChange() {
      this.filelist = [...this.filelist, ...this.$refs.file.files]
      //this.booking.bookingFiles = []
      var i = 0
      this.filelist.map((x) => {
        if (x.index == undefined) {
          x.index = i
        }
        var file = this.booking.bookingFiles.filter((y) => y.index == x.index)
        if (file == 0) {
          this.booking.bookingFiles.push({
            fileName: x.name,
            fileTypeId: this.fileTypes.find((x) => x.name == 'Internal Document').id,
            uploadedBy: this.user.id,
            mimeType: x.type,
            fileStatus: 1,
            uploadedByName: this.user.name,
            dateUploaded: moment().format("YYYY-MM-DD HH:mm:ss"),
            isHide: false,
            index: i,
            file: x
          })
        }
        this.fileKey += 1
        i = i + 1
      })
    },

    checkBookingType() {
      // check if booking type is IME by Video
      if(this.booking.bookingTypeId === 4 || this.booking.bookingTypeId === 15) {
        this.showLinkLocation = true
      } else {
        this.showLinkLocation = false
        this.booking.ClaimantLocation = null
      }
    },

    deleteFile(index) {
      this.filelist.splice(index, 1)
      this.booking.bookingFiles.splice(index, 1)
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      // if (!event.currentTarget.classList.contains('bg-green-300')) {
      //   event.currentTarget.classList.remove('bg-gray-100')
      //   event.currentTarget.classList.add('bg-green-300')
      // }
      event.currentTarget.classList.add("dark-border-hover");
    },
    dragleave(event) {
      // Clean up
      // event.currentTarget.classList.add('bg-gray-100')
      // event.currentTarget.classList.remove('bg-green-300')
      event.currentTarget.classList.remove("dark-border-hover");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
      event.currentTarget.classList.remove("dark-border-hover");
    },
    // async openManageContactModal(e) {
    //   e.preventDefault();
    //   e.stopPropagation();
    //   this.$refs.adminManageContactModal.handleModal()
    // },

    // getCaseOwnerName() {
    //   if (this.client.contacts.length > 0) {
    //     var caseOwner =  this.client.contacts.find((x) => x.userId == this.clientCase.caseOwner)
    //     if (caseOwner) {
    //       return caseOwner.fullName
    //     }
    //   } else {
    //     return ""
    //   }
    // },

    // getCaseOwnerPhoneNumber() {
    //   if (this.client.contacts.length > 0) {
    //     var caseOwner =  this.client.contacts.find((x) => x.userId == this.clientCase.caseOwner)
    //     if (caseOwner) {
    //       return caseOwner.phoneNumber1
    //     }
    //   } else {
    //     return ""
    //   }
    // },

    // getCaseOwnerEmail() {
    //   if (this.client.contacts.length > 0) {
    //     var caseOwner =  this.client.contacts.find((x) => x.userId == this.clientCase.caseOwner)
    //     if (caseOwner) {
    //       return caseOwner.emailAddress
    //     }
    //   } else {
    //     return ""
    //   }
    // },

    async loadData() {
     if (!this.bookingObject.isNewCase) { // Existing case
       this.clientCase = await utilities.getCaseByCaseId(this.bookingObject.caseId);
       if (this.clientCase) {
         this.claimFocus = true
         this.booking.claimTypeId = this.clientCase.claimTypeId
         this.disableCaseField = true
         this.booking.claimantFullName = this.clientCase.claimantFullName
         this.booking.dateOfBirth = this.clientCase.dateOfBirth
         this.booking.claimTypeName = this.clientCase.claimTypeName
         this.booking.claimantFirstName = this.clientCase.claimantFirstName
         this.booking.claimantSurname = this.clientCase.claimantSurname

         this.booking.claimantPhone1 = this.clientCase.claimantPhone1
         this.booking.claimantMobile = this.clientCase.claimantMobile
         this.booking.claimantPhone2 = this.clientCase.claimantPhone2
         this.booking.claimantEmail = this.clientCase.claimantEmail

         if (this.booking.claimantPhone1 != '' || this.booking.claimantMobile != '' || this.booking.claimantPhone2 != '' || this.booking.claimantEmail != '') {
           this.isEditContact = true
         } else {
           this.isEditContact = false
         }

         const apiArr = [utilities.getClientById(this.clientCase.clientId), utilities.getClientContacts(this.clientCase.clientId)]
         const res = await Promise.all(apiArr)
         this.client = res[0]
         if (this.client != undefined && this.client.redHealthMayContactClaimants) {
           this.hideAttendance = false
           this.hideWarning = true
           this.booking.redHealthMayContactClaimant = true
         } else {
           this.hideWarning = false
           this.hideAttendance = true
         }

         this.clientContacts = res[1]
         this.booking.caseOwner = this.clientCase.caseOwner
         this.listBillTo =  _.cloneDeep(utilities.getClientsDropdownAutoCompleteTag(this.clientContacts))
       }
     } else { // New case
       if(this.bookingObject != undefined && this.bookingObject.clientId > 0) {
         this.client = await utilities.getClientById(this.bookingObject.clientId)
         if (this.client != undefined && this.client.redHealthMayContactClaimants) {
           this.hideAttendance = false
           this.hideWarning = true
           this.booking.redHealthMayContactClaimant = true
         } else {
           this.hideAttendance = true
           this.hideWarning = false
         }
       }

       this.disableCaseField = false
     }
      this.$store.dispatch("loading/setLoadComponent", true);

      const apiArray = [utilities.getSpecialistById(this.bookingObject.id), utilities.getClients(), utilities.getLocations(), utilities.getBodyArea(), utilities.getPersonConditions(), utilities.getBookingTypes(), utilities.getClaimTypes(), utilities.getFileTypes()]
      const r = await Promise.all(apiArray)
      this.specialist = r[0]
      this.clients = r[1]
      this.locations = r[2]
      var bodyAreas = r[3]
      var personConditions = r[4]
      this.bookingTypes = r[5];
      this.claimTypes = r[6];
      this.fileTypes = []
      var types = r[7]


      // Load profile photo
      if(this.specialist.photo != '') {
        this.specialist.profileThumbnail = await utilities.getProfileThumbnail(this.specialist.photo)
      }

      this.clients.sort((a, b) => {
        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
      });

      this.originalListClients = _.cloneDeep(utilities.getDropdownAutoCompleteTag(this.clients))
      if (this.bookingObject.isNewCase) {
        this.listClients = this.originalListClients
      }


      this.states = utilities.getStates()
      // this.originalListLocations = _.cloneDeep(utilities.getDropdownLocation(this.locations))
      // this.listLocations =  this.originalListLocations


      this.bodyAreas = utilities.getAutoCompleteBodyAreaTags(bodyAreas)

      this.personConditions = utilities.getAutoCompleteTags(personConditions)
      this.bookingTypes = await utilities.getBookingTypes();
      this.bookingTypesOriginal = _.cloneDeep(this.bookingTypes)
      this.bookingTypes = this.bookingTypes.filter(x=>x.id != 1 && x.id != 11)

      types.map((x) => {
        if (x.name != 'Travel Itinerary' ) {
          this.fileTypes.push(x)
        }
      })

      this.booking.bookingHours = [];
      this.booking.bookingTests = [];
      this.getTimelineData();

      this.disabledDates = {
        ranges: [
          {
            from: moment(utilities.START_DATE, "YYYY-MM-DD"),
            to: moment().subtract(1, 'd'),
          },
          {
            from: moment(),
          }
        ],
      }
      let bookingTypeArr = []
      this.bookingTypes.forEach(item => {
        const filter = this.bookingTypesLocal.find(type => (((type.name.toUpperCase() === item.name.toUpperCase() || type?.otherName === item.name) && type.isInCreateBooking)))
        if(filter && (this.specialist[filter.field] || filter.field === 'serviceSupplementary')) {
          filter.id = item.id
          bookingTypeArr.push(filter)
        }
      })
      this.bookingTypes = bookingTypeArr

      this.checkBookingType()
      this.$store.dispatch("loading/setLoadComponent", false);
    },
    getTimelineData() {
      var timeline = {};
      this.bookingLogs.map((x) => {
        timeline = {
          id: x.bookingLogId,
          userName: x.userName,
          datetime: utilities.formatDate(x.logDate, "DD/MM/YYYY hh:ss A", "YYYY-MM-DD HH:mm:ss.ssssss"),
          body: x.logDetails,
        };
        this.timelineData.push(timeline);
      });
    },
    async updateFileType(bookingFileId) {
      this.$store.dispatch("loading/setLoadComponent", true);
      this.booking.bookingFiles.map((x) => {
        if (x.bookingFileId === bookingFileId) {
          x.fileTypeName = this.fileTypes.find(
            (y) => y.id == x.fileTypeId
          ).name;
        }
      });
      var params = {
        BookingFileID: bookingFileId,
        FileTypeID: this.booking.bookingFiles.find(
          (x) => x.bookingFileId === bookingFileId
        ).fileTypeId,
        UserID: this.user.id,
        Username: this.user.name,
      };
      await utilities.putBookingFileDetails(params);
      this.$store.dispatch("loading/setLoadComponent", false);
    },
    async finalizeFile(bookingFileId) {
      this.$store.dispatch("loading/setLoadComponent", true);
      var params = {
        BookingFileID: bookingFileId,
        FileStatus: 1,
        UserID: this.user.id,
        Username: this.user.name,
      };
      await utilities.putBookingFileDetails(params);
      this.$store.dispatch("loading/setLoadComponent", false);
    },
    getUploadedByName(id) {
      var user = this.users.find((x) => x != undefined && x.id === id);
      return user === undefined ? "" : user.name;
    },
    formatDate(dateToFormat, newFormat = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
      return utilities.formatDate(dateToFormat, newFormat, currentFormat)
    },
    getBookingType(id) {
      var bookingType = this.bookingTypes.find(x => x.id == id)
      return bookingType != undefined ? bookingType.name : ''
    },
    toggleCallAfterAppointment() {
      utilities.disposeTooltip()
      this.booking.callClientAfterAppointment = !this.booking.callClientAfterAppointment
      utilities.initTooltip()
    },
    toggleCanRingClaimant() {
      utilities.disposeTooltip()
      this.booking.redHealthMayContactClaimant = !this.booking.redHealthMayContactClaimant
      utilities.initTooltip()
    },
    toggleAttendance() {
      utilities.disposeTooltip()
      this.booking.claimantConfirmed = !this.booking.claimantConfirmed
      utilities.initTooltip()
    },
    toggleConsentReceived() {
      utilities.disposeTooltip()
      this.booking.claimantConsentReceived = !this.booking.claimantConsentReceived
      utilities.initTooltip()
    },
    toggleOTFCE() {
      // 4915 - Disable OTFCE for FCE Lite and FCE Lite by Video
      if(this.booking.bookingTypeName == 'FCE Lite' || this.booking.bookingTypeName == 'FCE Lite by Video') {
        return false
      }
      utilities.disposeTooltip()
      this.booking.otfceRequired = !this.booking.otfceRequired
      utilities.initTooltip()
    },
    toggleVideoLinkTestArranged() {
      utilities.disposeTooltip()
      this.booking.videoLinkTestStatus = !this.booking.videoLinkTestStatus
      utilities.initTooltip()
    },
    toggleHasVideo() {
      utilities.disposeTooltip()
      this.booking.hasVideoLink = !this.booking.hasVideoLink
      utilities.initTooltip()
    },
    toggleFeeDeferralRequired() {
      utilities.disposeTooltip()
      this.booking.feeDeferralRequired = !this.booking.feeDeferralRequired
      utilities.initTooltip()
    },
    toggleSpecialistConfirmedHours() {
      utilities.disposeTooltip()
      this.booking.specialistConfirmedHours = !this.booking.specialistConfirmedHours
      utilities.initTooltip()
    },
    openServiceTimeModalPreApproved() {
      this.roleProps = ""
      this.$refs.adminAddServiceTimeModal.handleModal()
    },
    openServiceTimeModal() {
      this.roleProps = "adminAsSpecialist"
      this.$refs.adminAddServiceTimeModal.handleModal()
    },
    deleteNote(index) {
      utilities.showAlert(".confirmation", false)
      this.deleteIndex = index
    },
    tapButtonConfirmation(states) {
      utilities.hideAlert(".confirmation")
      if (states) {
        this.isBilling ? this.booking.billingNotes.splice(this.deleteIndex, 1) : this.booking.bookingNotes.splice(this.deleteIndex, 1)
      }
    },

    openTestsModal(e) {
      e.preventDefault()
      e.stopPropagation()
      this.$refs.addSpecialistBookingTestModal.selectedIndex = -1
      this.$refs.addSpecialistBookingTestModal.test = this.test
      this.$refs.addSpecialistBookingTestModal.handleModal()
    },

    setTestAndOpenModal(e, test, index) {
      e.preventDefault()
      e.stopPropagation()
      this.$refs.addSpecialistBookingTestModal.selectedIndex = index
      this.$refs.addSpecialistBookingTestModal.test = test
      this.$refs.addSpecialistBookingTestModal.handleModal()
    },

    checkInterpreterRequired() {
      if (this.booking.interpreterDetails.trim() != "")
        this.booking.interpreterRequired = true;
      else this.booking.interpreterRequired = false;
    },
    checkSurrogateRequired() {
      if (this.booking.surrogateDetails.trim() != "")
        this.booking.surrogateRequired = true;
      else this.booking.surrogateRequired = false;
    },

    getLocationName(id) {
      if (id) {
        return this.locations.find((x) => x.id == id).fullLocation.replace(', Australia', "")
      }
    },

    getLocationState(id) {
      if (id) {
        return this.locations.find((x) => x.id == id).state
      }
    },

    getClaimantTypeName(id) {
      var type = ''
      if (id) {
        type = this.claimTypes.find((x) => x.id == id).name
      }
      return type
    },

    showBookingConfirmation() {
      //If type is File Review
      this.showBookingStatus = true
      this.hideOtherStatus = false
      if (this.booking.bookingTypeId == 6) {
        this.showBookingStatus = false
        this.hideOtherStatus = true
      }

      //If type is phone call
      if (this.booking.bookingTypeId == 7) {
        this.showBookingStatus = true
        this.hideOtherStatus = true
      }

      if (this.bookingObject.waitlisted || this.booking.bookingTypeId == 14 || this.booking.bookingTypeId == 15 ) {
        this.showBookingStatus = false
        this.hideOtherStatus = true
      }

      this.booking.bookingStatus = 3
      this.booking.bookingStatusName = "Confirmed"
      this.displayBookingDetails = false
      this.displayBookingSummery = true
    },

    async tapNext() {
      if (!this.validateDateOfBirth || !this.validateClientContact || !this.validateAttendance || !this.validateClaimantName || !this.validateCaseOwner
          || !this.validateTypeOfClaim || !this.validateReferenceNumber || !this.validateSendEmail || !this.validateBillEmail || !this.validateBookingType || !this.validateState || !this.validInterpreterDetails) {
        this.hasError = true
        this.errorMessage = "Please correct the errors shown above and try again."
        utilities.showAlert(".alert-danger")
        return false
      }

      this.showBookingConfirmation()
      this.summaryKey += 1
      this.hasError = false
      setTimeout(() => {
        utilities.initTooltip()
      }, 300)
    },

    async getDuplicateCase() {
      this.hasToChooseCase = false
      if (!this.clientCase.id) {
        const param = {
          ClientID: this.booking.clientId,
          CaseReference: this.booking.caseReference,
          ClaimantFirstName: this.booking.claimantFirstName,
          ClaimantSurName: this.booking.claimantSurname,
          PageNum: 1,
          PageSize: 50,
          CaseStatus: 1
        }
        this.$store.dispatch("loading/setLoadComponent", true)
        const {clientCase, total} = await utilities.getCasesNew(param)
        const duplicateCase = clientCase.find(item => (item.claimantFullName === this.booking.claimantFullName && item.caseReference === this.booking.caseReference && window.moment(item.dateOfBirth).isSame(window.moment(this.booking.dateOfBirth))))
        if(duplicateCase) this.hasToChooseCase = true
        if (total > 0) {
          this.result = clientCase
          this.dialogVisible = true
        }
        this.$store.dispatch("loading/setLoadComponent", false)
      }
    },
    selectCase(item) {
      this.dialogVisible = false
      if(item) {
        this.clientCase = item
      }
      this.createBooking(true)
    },
    closeModal() {
      this.result = []
      this.dialogVisible = false
      if(this.displayBookingSummery) this.tapEdit()
    },

    async createBooking(canCreate) {
      if (!this.validateDateOfBirth || !this.validateClientContact || !this.validateAttendance || !this.validateClaimantName || !this.validateCaseOwner
        || !this.validateTypeOfClaim || !this.validateSendEmail || !this.validateBillEmail || !this.validateBookingType || !this.validateState) {
        this.hasError = true
        this.errorMessage = "Please correct the errors shown above and try again."
        utilities.showAlert(".alert-danger")
        return false
      }
      if(!canCreate) {
        await this.getDuplicateCase()
        if(this.dialogVisible) return
      }
      this.$store.dispatch("loading/setLoadComponent", true)
      const available = await utilities.getSpecialistAvailabilities(this.booking.specialistId, '', {AvailabilityID: this.booking.specialistAvailabilityId})
      if(available[0] && available[0].Availabilities) {
        const single = available[0].Availabilities.find(item => this.booking.specialistAvailabilityId === item.AvailabilityID)
        console.log(single)
        if(single && (single.AvailabilityStatus !== '2' || single.BookingStatus !== 'A')) {
          if(!this.bookingObject.tentativeBookingID || (this.bookingObject.tentativeBookingID && single.BookingStatus !== 'H')) {
            this.errorMessage = "Booking Time not available now, please go back to select another availability"
            utilities.showAlert(".alert-danger")
            this.$store.dispatch("loading/setLoadComponent", false)
            return
          }
        } else if (single && single?.Location?.LocationID !== this.booking.locationId) {
          this.$msgbox({
            message: `The address for this availability has been updated to ${this.getLocationName(single.Location.LocationID)}. Please click “Confirm” to book the appointment with this new address, or "Cancel" to pick a different availability`,
            title: 'Warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then(() => {
            this.booking.locationId = single.Location.LocationID
            this.createBooking(true)
          }).catch(() => {
            this.$router.replace('/admin-search-specialist')
          });
          this.$store.dispatch("loading/setLoadComponent", false)
          return
        }
      }
      var bodyAreaObjects = []
      var bodyArea = {}
      if (this.bodyAreaTags.length > 0) {
        this.bodyAreaTags.map((x) => {
          var temp = this.addedBodyArea.filter((y) => y.bodyArea == x.text)[0]
          if (temp == undefined) {
              bodyArea = {
                BodyAreaID: x.value
              }
              bodyAreaObjects.push(bodyArea)
          }
        })
      }

      var conditionObject = []
      var condition = {}
      if (this.personConditionTags.length > 0) {
        this.personConditionTags.map((x) => {
          var temp = this.addedConditions.filter((y) => y.name == x.text)
          if (temp == undefined || temp.length == 0) {
              condition = {
                PersonConditionID: x.value
              }
              conditionObject.push(condition)
          }
        })
      }

      var otherBodyArea = ""
      this.otherBodyAreaTags.map((x) => {
        if (otherBodyArea == "") {
          otherBodyArea = x.text
        } else {
          otherBodyArea = otherBodyArea + "," + x.text
        }
      })

      var dateToday = moment()
      //var bookingsss = moment(this.booking.bookingDateTime).format('YYYY-MM-DD') + ' ' + this.booking.bookingTime
      //this.booking.bookingDateTime = moment(bookingsss,'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss')

      if (this.bookingObject.waitlisted) {
        this.booking.bookingStatus = 16
      } else {
        if (this.booking.bookingStatus == '' || this.booking.bookingStatus == 0) {
          this.booking.bookingStatus = 1
        }
      }

      var reportToEmailAddress =
        this.client.contacts.find((x) => x.primaryContact) != undefined
          ? this.client.contacts.find((x) => x.primaryContact).emailAddress
          : this.client.contacts[0] != undefined
          ? this.client.contacts[0].emailAddress
          : ""

      var reportToName =
        this.client.contacts.find((x) => x.primaryContact) != undefined
          ? this.client.contacts.find((x) => x.primaryContact).fullName
          : this.client.contacts[0] != undefined
          ? this.client.contacts[0].fullName
          : "";
      var params = {
        SpecialistID: this.specialist.id,
        DateCreated: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
        CreatedBy: this.user.id,
        BookingStatus: this.booking.bookingStatus,
        BookingStatusName: this.booking.bookingStatusName,
        CaseReference: this.booking.caseReference,
        BookingTypeID: this.booking.bookingTypeId,
        ClaimantLocation: this.booking.ClaimantLocation,
        BookingTypeName: this.getBookingType(this.booking.bookingTypeId),
        CurrentDateTime: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
        AvailabilityID: this.booking.specialistAvailabilityId,
        OTFCERequired: this.booking.otfceRequired,
        RedHealthMayContactClaimant: utilities.convertNumToBool(this.booking.redHealthMayContactClaimant),
        SpecialistConfirmedHours: this.booking.specialistConfirmedHours,
        OnBehalfOf: this.booking.onBehalfOf,
        BillTo: this.showBillToEmail ? this.billToText : this.booking.billTo,
        ReportTo: this.showSendReportToOther ? this.reportToText : this.booking.reportTo,
        // ReportToEmailAddress: reportToEmailAddress, // Passing billTo and reportTo email in above field
        // ReportToName: reportToName,
        VideoLink: this.booking.bookingTypeId == 9 || this.booking.bookingTypeId == 4 || this.booking.bookingTypeId == 12 || this.booking.bookingTypeId == 15 ? 1 : 0,
        VideoLinkTestStatus: this.booking.videoLinkTestStatus,
        LocationName: this.getLocationName(this.booking.locationId),
        LocationState: this.getLocationState(this.booking.locationId),
        BookingEndTime: window.moment(this.booking.bookingEndTime, 'HH:mm:ss').format('HH:mm:ss'),
        StateName: this.getLocationState(this.booking.locationId),
        BookingDateTime: this.booking.bookingDateTime,
        SurrogateDetails: this.booking.surrogateDetails,
        SurrogateRequired: this.booking.surrogateRequired,
        CallClientAfterAppointment: this.booking.callClientAfterAppointment,
        InterpreterDetails: this.booking.interpreterDetails,
        InterpreterRequired: this.booking.interpreterRequired,
        FeeDeferralRequired: this.booking.feeDeferralRequired,
        OverPeriodofTime: utilities.convertNumToBool(this.booking.overPeriodofTime),
        AccidentDate: this.booking.accidentDate ? moment(this.booking.accidentDate, "YYYY-MM-DD").format('YYYY-MM-DD') : "",
        BodyAreaObject: bodyAreaObjects,
        BodyAreaOther: otherBodyArea,
        PersonConditionObject: conditionObject,
        Specialty: this.specialist.qualificationList,
        BookingNoteObject: this.booking.bookingNotes.map((x) => {
          return { Note: x.note, NoteDateTime: x.noteDateTime, InternalOnly: x.internalOnly ? x.internalOnly : 0 };
        }),
        BillingNoteObject: this.booking.billingNotes.map((x) => {
          return { Note: x.note, NoteDateTime: x.noteDateTime };
        }),
        ServiceTimeObject: this.booking.bookingHours
          .map((x) => {
            return {
              Service: x.service,
              NumberHours: x.numberOfHours,
              BookingHoursStatus: 0,
              RequestedBy: this.specialist.id,
              DateRequested: moment().format('YYYY-MM-DD')
            };
          })
          .concat(
            this.preapprovedHours.map((x) => {
              return {
                Service: x.service,
                NumberHours: x.numberOfHours,
                BookingHoursStatus: 3,
                RequestedBy: this.user.id,
                DateRequested: moment().format('YYYY-MM-DD')
              };
            })
          ),
        TestObject: this.booking.bookingTests.map((x) => {
          return {
            TestName: x.testName,
            OrderedFrom: x.orderedFrom,
            DateOrdered: x.dateOrdered != '' ? moment(x.dateOrdered, "DD/MM/YYYY").format("YYYY-MM-DD") : '',
            DateReceived: x.dateReceived != '' ? moment(x.dateReceived, "DD/MM/YYYY").format("YYYY-MM-DD") : '',
          };
        }),
        SpecialistNewBookingNotification:
          this.specialist.settings == undefined ||
          this.specialist.settings.newBookingNotification == undefined
            ? ""
            : this.specialist.settings.newBookingNotification,
        ClientID: this.client.id,
        UserID: this.user.id,
        Username: this.user.name,
        ClaimantFirstName: this.clientCase.claimantFirstName,
        ClaimantGivenNames: this.clientCase.claimantGivenNames,
        ClaimantSurname: this.clientCase.claimantSurname,
        //ClientZohoLink: this.client.zohoLink,
        Salutation: this.clientCase.Salutation,
        PreferredGivenName: this.clientCase.PreferredGivenName,
        ZohoCaseID: this.clientCase.zohoCaseId,
        ZohoClaimantID: this.clientCase.zohoClaimantId,
        SpecialistZohoLink: this.specialist.zohoLink,
        ZohoDateCreated: moment().format(),
        ZohoBookingDateTime: this.booking.bookingDateTime ? moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss").format() : '',
      }

      if(this.clientCase.id) {
        params.CaseID = this.clientCase.id
      }

      if(this.bookingObject.tentativeBookingID) {
        params.TentativePreBookingID = this.bookingObject.tentativeBookingID
      }

      if(this.bookingObject.WaitListPreBookingID) {
        params.WaitListPreBookingID = this.bookingObject.WaitListPreBookingID
      }

      if(this.booking.locationId) {
        params.LocationID = this.booking.locationId
      }
      //if new case pass onlt ID otherwise pass full url

      if(this.clientCase.id != '') {
        params.ClientZohoLink = this.client.zohoLink
      } else {
        var zohoId = this.client.zohoLink.split("/")
        zohoId = zohoId[zohoId.length - 1]
        params.ClientZohoLink = zohoId
      }

      if (this.booking.caseOwner) {
        const obj = this.clientContacts.find(item => item.userId === this.booking.caseOwner)
        console.log(obj)
        if(!obj) {
          this.showSelectOwnerModal = true
          this.$store.dispatch("loading/setLoadComponent", false)
          return
        }
        var contact = this.clientContacts.find((x) => x.userId == this.booking.caseOwner)
        params.ClientContactZohoLink = contact.zohoClientContactId
        params.ClientContactZohoName = contact.fullName
      } else {
        this.showSelectOwnerModal = true
        this.$store.dispatch("loading/setLoadComponent", false)
        return
      }

      // 4202 - Appointments by Video – Handling Location & Links
      var location = this.locations.find((x) => x.id == this.booking.locationId)
      var state = location ? location.state : ""
      params.ClaimantStateDuringBooking = (this.booking.bookingTypeId == 4 || this.booking.bookingTypeId == 7 || this.booking.bookingTypeId == 15) ? this.booking.claimantStateDuringBooking : state
      params.ClaimantBookingDateTime = this.booking.claimantBookingDateTime

      var cb = (res) => {
        this.booking.id = res
      }

      if (this.booking.claimantConfirmed == true) {
        params.ClaimantConfirmed = moment().format("YYYY-MM-DD HH:mm:ss")
      }

      if (this.booking.claimantConsentReceived == true) {
        params.ClaimantConsentReceived = moment().format("YYYY-MM-DD HH:mm:ss")
      }

      if (this.booking.paperworkDue) {
        params.PaperworkDue = moment(this.booking.paperworkDue, "DD/MM/YYYY").format("YYYY-MM-DD")
      }

      var notificationDate = moment().add(2, 'w').format('YYYY-MM-DD')
      if (moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss").isBefore(notificationDate)) {
        params.TwoWeekDiff = true
      } else {
        params.TwoWeekDiff = false
      }

      //Pass this only convert Supplementary service into the booking
      if (this.bookingRequest != undefined && this.bookingRequest.originalBookingId) {
        params.OriginalBookingID = this.bookingRequest.originalBookingId
      }


      if (this.bookingObject.isNewCase) {
        params.ClientID = this.client.id
        params.CaseOwner = this.booking.caseOwner
        params.ClaimTypeID = this.booking.claimTypeId
        params.ClaimTypeName = this.getClaimantTypeName(this.booking.claimTypeId)
        params.ClaimantFirstName = this.booking.claimantFirstName
        params.ClaimantGivenNames = this.booking.claimantGivenNames
        params.ClaimantSurname = this.booking.claimantSurname
        params.DateofBirth = moment(this.booking.dateOfBirth, "YYYY-MM-DD").format('YYYY-MM-DD')
        params.Salutation = this.booking.Salutation
        params.PreferredGivenName = this.booking.PreferredGivenName
        params.CaseReference = this.booking.caseReference
        params.ClaimantPhone = utilities.validatePhonenumber(this.booking.claimantPhone1)
        params.ClaimantPhone = utilities.validatePhonenumber(this.booking.claimantPhone2)
        params.ClaimantMobile = utilities.updateMobileCode(utilities.validatePhonenumber(this.booking.claimantMobile))
        params.ClaimantEmail = this.booking.claimantEmail
        params.ClaimantAddressLine1 = this.booking.claimantAddressLine1
        params.ClaimantAddressLine2 = this.booking.claimantAddressLine2
        params.ClaimantCity = this.booking.claimantCity
        params.ClaimantState = this.booking.claimantState
        params.ClaimantPostcode = this.booking.claimantPostcode
        params.ClaimantCountry = 'AU'

      } else {
        params.CaseOwner = this.booking.caseOwner
      }
      //pass clientContact details into the Zoho
      var contact = this.clientContacts.find((x) => x.userId == this.booking.caseOwner)
      if (contact != undefined) {
          params.CCFirstName = contact.firstName
          params.CCSurname = contact.surname
          params.CCPhone = contact.phoneNumber1
          params.CCEmail = contact.NotificationEmailAddress || contact.emailAddress
          params.CCZohoID = contact.zohoClientContactId
          params.BranchName = contact.branchName
          params.ClientCategory = this.client.clientCategory
          params.ClientState = this.client.state
      }

      if (this.bookingObject.isSupplementary) {
        params.BookingEndTime = ""
        params.BookingDateTime = ""
      }

      if(params.BookingStatus === 3) {
        params.BookingStatusName = 'Booked'
      }

      // Pass it to API
      var result = await utilities.postBooking(params, cb)
      if (result) {
        if(this.filelist.length > 0) {
          let item = []
          await Promise.all(this.booking.bookingFiles.map(async (x) => {
            if(this.booking.id != '') {
              if (!x.isHide) {
                try {
                  const result = await utilities.postPreSign({
                    ft: x.mimeType ? x.mimeType : 'application/octet-stream',
                    fn: x.fileName,
                    id: this.booking.id,
                    FileStatus: x.fileStatus,
                    FileTypeID: x.fileTypeId,
                  })
                  let postData = new FormData()
                  for (let key in result.fields) {
                    postData.append(key, result.fields[key]);
                  }
                  postData.append('file', x)
                  const r = await utilities.postImage(result.url, postData)
                  if(r) {
                    item.push({
                      BookingID: this.booking.id,
                      DateUploaded:window.moment().format('YYYY-MM-DD HH:mm:ss'),
                      FileName: x.fileName,
                      FileStatus: x.fileStatus,
                      FileTypeID: x.fileTypeId,
                      MimeType: x.mimeType ? x.mimeType : 'application/octet-stream',
                      UploadedBy: this.user.id,
                      UploadedByName: this.user.name
                    })
                  }
                } catch (error) {
                  utilities.showErrorMessage(error.response.data?.message || error.response.data || error)
                }
              }
            }
          }))
          if(item.length > 0) {
            await utilities.postFileRecord(item)
          }
        }

        //Call booking request file move API
        if (this.bookingRequest && this.bookingRequest.bookingRequestFile && this.bookingRequest.bookingRequestFile.length > 0) {
           this.postBookingFile()
        }

        //Need to set booking request as completed when convert booking request into the booking.
        if (this.bookingRequest.id) {
          this.putBookingRequest()
        }
        this.$store.dispatch("booking/setBookingObject", null)
        this.successMessage = "Booking details created."
        utilities.showAlert(".alert-success")

        setTimeout(() => {
          this.$router.push({ name: 'admin-manage-booking', query: { id: this.booking.id } })
          this.$store.dispatch("loading/setLoadComponent", false)
        }, 2000)
      } else {
        this.errorMessage = "An error occurred."
        utilities.showAlert(".alert-danger")
        this.$store.dispatch("loading/setLoadComponent", false)
      }
      // Process response
    },

    //Call this APi to move booking request file into to booking
    async postBookingFile() {
      var params = {
        UserID: this.user.id,
        Username: this.user.name,
        BookingID: this.booking.id,
        BookingRequestID: this.bookingRequest.id,
        MoveObject: [],
      }

      this.bookingRequest.bookingRequestFile.map((x) => {
        params.MoveObject.push({
          BookingRequestFileID: x.bookingRequestFileId,
          BookingID: this.booking.id,
          FileName: x.fileName,
          FileTypeID: x.fileTypeId,
          FileStatus: 1,
        })
      })
      await utilities.postBookingFile(params)
    },

    putBookingRequest() {
      var params = {
        UserID: this.user.id,
        Username: this.user.name,
        BookingRequestID: this.bookingRequest.id,
        // Completed
        BookingRequestStatus: 3,
      }
      var callback = (response) => {}
      return utilities.putBookingRequest(params, callback)
    },

    goToCase(caseId) {
      this.$router
        .push({ path: "/admin-manage-case", query: { caseId: caseId } })
        .catch((err) => {});
    },

    requestAction() {
      this.$refs.requestActionModal.handleModal();
    },

    async actionConfirmation(isNew = true) {
      if (isNew) {
        this.successMessage = "Your update has been saved.";
        utilities.showAlert(".alert-success");
      }
      this.bookingAction = await utilities.getBookingActions(this.booking.id);
    },

    // async updateNotify(mode) {
    //   await this.createBooking();
    //   if (mode == "client") {
    //     var params = {
    //       EmailTo: this.emails.client,
    //       Content:
    //         "Your booking with id " + this.booking.id + " has been updated.",
    //       Subject: "RedHealth Booking Updated",
    //     };
    //     var res = await utilities.sendEmailFromBooking(params);
    //     if (res) {
    //       this.successMessage = "Email sent to " + this.sendToProps + ".";
    //       utilities.showAlert(".alert-success");
    //       this.handleModal();
    //     }
    //   } else {
    //     var params = {
    //       EmailTo: this.emails.specialist,
    //       Content:
    //         "Your booking with id " + this.booking.id + " has been updated.",
    //       Subject: "RedHealth Booking Updated",
    //     };
    //     var res = await utilities.sendEmailFromBooking(params);
    //     if (res) {
    //       this.successMessage = "Email sent to " + this.sendToProps + ".";
    //       utilities.showAlert(".alert-success");
    //       this.handleModal();
    //     }
    //   }
    // },
    async changeClient() {
      this.$store.dispatch("loading/setLoadComponent", true);
      utilities.disposeTooltip()

      this.client = _.cloneDeep(this.clients.find((x) => x.id == this.client.id));
      this.booking.clientId = this.client.id

      this.clientContacts = await utilities.getClientContacts(this.client.id)
      this.originalClientContacts = _.cloneDeep(utilities.getClientsContactDropdownAutoCompleteTag(this.clientContacts))

      this.listContact = _.cloneDeep(this.originalClientContacts)
      this.listBillTo =  _.cloneDeep(utilities.getClientsDropdownAutoCompleteTag(this.client.contacts))
      this.listCases = this.originalListCases

      // selected primary contact as a case owner
      var client = this.clientContacts.find((x) => x.isPrimaryContact)
      // Comment this block code because Client contact doesn't have redHealthMayContactClaimants inside, already get it in loadData function
      // if (client != undefined && client.redHealthMayContactClaimants) {
      //   this.hideAttendance = false
      //   this.booking.redHealthMayContactClaimant = true
      // } else {
      //   this.hideAttendance = true
      // }

      if (client != undefined) {
        var selectedContact = this.listContact.find((x) => x.id == client.userId)
        setTimeout(() =>{
          $('.contact-selecter  input').val(selectedContact.name)
        },1000)
        this.booking.caseOwner = selectedContact.id
      }

      if (this.bookingObject.caseOwner != undefined || this.bookingObject.caseOwner != '') {
        var selectedContact1 = this.listContact.find((x) => x.id == this.bookingObject.caseOwner)
        if (selectedContact1) {
          setTimeout(() =>{
            $('.contact-selecter  input').val(selectedContact1.name)
          },1000)
          this.booking.caseOwner = selectedContact1.id
        }
      }
      utilities.initTooltip()
      this.$store.dispatch("loading/setLoadComponent", false);

      setTimeout(() => {
        $(".dropdown-input").attr({
         autocomplete: "off",
        });
      }, 1000)
    },
    async changeCase() {
      this.$store.dispatch("loading/setLoadComponent", true)
      this.clientCase = await utilities.getCaseByCaseId(this.clientCase.id)
      if (this.clientCase.bodyAreas.length > 0) {
        this.bodyAreasTags = utilities.getAutoCompleteBodyAreaTags(this.clientCase.bodyAreas)
      }
      if (this.clientCase.bodyAreaOther.length > 0) {
        this.otherBodyAreasTags = utilities.getAutoCompleteOtherBodyAreaTags(this.clientCase.bodyAreaOther.split(','))
      }

      if (this.clientCase.personCondition.length > 0) {
        this.conditionTags = utilities.getAutoCompleteTags(this.clientCase.personCondition)
      }
      this.$store.dispatch("loading/setLoadComponent", false)
    },

    // updateBookingTime() {
    //   if (this.bookingTime != "manual")
    //     this.booking.bookingTime = this.timeSlots.find(
    //       (x) => x.time == this.bookingTime
    //     ).time;
    // },

    async changeSpecialist() {
      // Enable date and time if specialistId != ''
      this.specialist.settings = await utilities.getSpecialistSeetings(this.bookingObject.id);
      //this.specialist = this.specialists.find((x) => x.id == this.specialist.id)
    },

    changeBookingStatusName() {
      this.booking.bookingStatusName = this.bookingStatuses.find(
        (x) => x.id === this.booking.bookingStatus
      ).name;
    },

    isDate(date) {
      return moment(date, "YYYY-MM-DD").isValid()
    },

    setBookingDetails(object) {
      this.booking.specialistAvailabilityId = object.availabilityId
      this.booking.locationId = object.locationId != undefined ? object.locationId : ''
      this.booking.bookingDate = window.moment(object.bookingDate, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")
      this.booking.bookingTime = window.moment(object.bookingTime, 'hh:mm').format('HH:mm:ss')
      this.booking.bookingEndTime = object.bookingEndTime
      this.booking.specialistAvailabilityId = object.availabilityId
      this.booking.bookingTypeName = object.typeName

      if(object.waitlisted) {
        var dateTime = this.booking.bookingDate + " " + object.bookingTime
        this.booking.bookingDateTime = moment(dateTime, 'DD/MM/YYYY HH:mm').format("YYYY-MM-DD HH:mm:ss")
      } else {
        var bookingDateTime = object.bookingDate + " " + object.bookingTime
        this.booking.bookingDateTime = moment(bookingDateTime, 'YYYY-MM-DD hh:mm:ss A').format("YYYY-MM-DD HH:mm:ss")
      }

      if ((object.clientId !== undefined || object.clientId !== '') && this.listClients.length > 0) {
        var selectedClient = this.listClients.find((x) => x.id == object.clientId)
        $('.client-selecter input').val(selectedClient.name)
        if (selectedClient) {
          this.$refs.clientDropdown.searchFilter = selectedClient.name
          this.clientSelected(selectedClient)
        }
      }
      //this.client.id = selectedClient.id

      this.booking.specialistId = object.id

      // 4911 - Set booking status Attended for File Review, Supplementary, Supplementary by Video, and Medical Negligence Request
      if (this.booking.bookingTypeName == "Medical Negligence Request" || this.booking.bookingTypeName == "Supplementary" || this.booking.bookingTypeName == "File Review") {
        this.booking.bookingStatus = 5
        this.booking.bookingStatusName = "Attended"
        this.displayBookButton = true
        this.booking.bookingTypeId = this.bookingTypes.find(type => type.name === this.booking.bookingTypeName)?.id
      } else {
        this.bookingTypes = this.bookingTypes.filter(type => type.name !== 'Medical Negligence Request' && type.name !== 'Supplementary' && type.name !== 'File Review')
      }

      if(object.hasVideo) {
        this.bookingTypes  = this.bookingTypes.filter(item => item.name !== 'Independent Medical Examination')
      } else if (!object.hasUserVideo && !object.hasVideo) {
        this.bookingTypes  = this.bookingTypes.filter(item => item.name !== 'Independent Medical Examination by Video')
      }

      // 4915 - Set OTFCE is false as default for FCE Lite and FCE Lite by Video
      if(this.booking.bookingTypeName == 'FCE Lite' || this.booking.bookingTypeName == 'FCE Lite by Video') {
        this.booking.otfceRequired = false
      }

      if (this.booking.bookingTypeId == 8) {
        this.hideAttendance = true
        this.booking.claimantConfirmed = false
      }

      //set Search case details
      if (object.isNewCase) {
        if (object.claimantFirstName || object.claimantLastName) {
          this.booking.claimantFirstName = object.claimantFirstName
          this.booking.claimantSurname = object.claimantLastName
          this.booking.claimantFullName = utilities.getClaimantFullName(this.booking)
          if (this.booking.claimantFullName != "") {
            this.isEditName = true
          } else {
            this.isEditName = false
          }
          this.nameKey += 1
        }

        if (object.dateOfBirth) {
          this.booking.dateOfBirth = object.dateOfBirth
        }

        if (object.caseReference) {
          this.booking.caseReference = object.caseReference
        }
      } else {
        this.booking.caseOwnerName = object.caseOwnerName
      }

      this.changeSpecialist()
      // this.requiresTravel = this.bookingProps.requiresTravel != undefined ? this.bookingProps.requiresTravel : false
      // this.videoLinkAvailable = this.bookingProps.videoLinkAvailable != undefined ? this.bookingProps.videoLinkAvailable : 0
      // var selectedSpecialist = this.listSpecialists.find((x) => x.id == this.bookingProps.id)
      // $('.specialist-selecter input').val(selectedSpecialist.name)
      // this.specialist.id = selectedSpecialist.id
    },

    setBookingRequestDetails() {
      this.bookingObject.clientId = this.bookingRequest.clientId
      this.booking.claimantFirstName = this.bookingRequest.claimantFirstName
      this.booking.claimantGivenNames = this.bookingRequest.claimantGivenNames
      this.booking.claimantSurname = this.bookingRequest.claimantSurname
      this.booking.claimantFullName = utilities.getClaimantFullName(this.bookingRequest)
      this.booking.dateOfBirth = this.bookingRequest.dateOfBirth
      this.booking.claimTypeId = this.bookingRequest.claimTypeId
      this.booking.claimTypeName = this.bookingRequest.claimTypeName
      this.booking.bookingTypeId = this.bookingRequest.bookingTypeId
      this.booking.bookingTypeName = this.bookingRequest.bookingTypeName

      if (this.bookingRequest.bodyAreas.length > 0) {
        var temp = []
        this.bookingRequest.bodyAreas.map((x) => {
          var item = {
            id: x.bodyAreaId,
            bodyArea: x.bodyArea
          }
          temp.push(item)
        })
        this.bodyAreaTags = utilities.getAutoCompleteBodyAreaTags(temp)
      }

      if (this.bookingRequest.personConditions.length > 0) {
        var temp = []
        this.bookingRequest.personConditions.map((x) => {
          var item = {
            id: x.personConditionId,
            name: x.personConditionName
          }
          temp.push(item)
        })
        this.personConditionTags = utilities.getAutoCompleteTags(temp)
      }

      if (this.bookingRequest.note != '') {
        var note = {
          userName: this.bookingRequest.userName,
          noteDateTime: this.formatDate(this.bookingRequest.dateSubmitted, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss.ssssss'),
          note: this.bookingRequest.note,
          isHide: true
        }
        this.booking.bookingNotes.push(note)
      }
      if (this.bookingRequest && this.bookingRequest.bookingRequestFile && this.bookingRequest.bookingRequestFile.length > 0) {
        this.bookingRequest.bookingRequestFile.map((x) => {
          var file = {
            fileStatus: 1,
            fileTypeId: x.fileTypeId,
            fileName: x.fileName,
            uploadedByName:x.uploadedByName,
            uploadedBy: x.uploadedBy,
            isHide: true,
          }
          this.booking.bookingFiles.push(file)
        })
      }

      if (this.booking.claimantFullName != "") {
        this.isEditName = true
      } else {
        this.isEditName = false
      }
      this.nameKey += 1
      this.fileKey += 1
    },
  },
  async beforeMount() {
    this.bookingObject = this.$store.getters["booking/getBookingObject"]
    if (this.bookingObject.bookingRequestId != undefined && this.bookingObject.bookingRequestId  != '') {
      this.bookingRequest = await utilities.getBookingRequestById(this.bookingObject.bookingRequestId)
      this.bookingObject.caseId = this.bookingRequest.caseId
      this.clientCase.id = this.bookingRequest.caseId
      if (this.bookingRequest.caseId == '') {
        this.bookingObject.isNewCase = true
      }
    } else {
      this.clientCase.id = this.bookingObject.caseId
    }

    this.$store.dispatch('loading/setLoadComponent', true)
    $("body>.tooltip").remove()
    await this.loadData()
    this.isValidAccidentDate = this.validateAccidentDate()
    $(".report-to").click(() => {
      this.focusDropdown = true
    })

    if (this.bookingObject.noResult) {
      utilities.showDialogInfo('No matching claimants found; please enter the details for the new claimant.')
    }
    if (this.bookingRequest.id) {
      this.setBookingRequestDetails()
    }

    if (this.bookingObject.typeName == "FCE Lite" || this.bookingObject.typeName == "FCE Lite by Video") {
      this.showBookingStatus = false
    }

    this.setBookingDetails(this.bookingObject)
    this.openTab(0)
    utilities.initTooltip()
    this.$store.dispatch('loading/setLoadComponent', false)
  },
  mounted() {
    setTimeout(() => {
      $(".dropdown-input").attr({
       autocomplete: "off",
      })

      this.defineClearButton()
    }, 500)
  },
};
</script>
