import { render, staticRenderFns } from "./AdminManageClientRequest.vue?vue&type=template&id=0f07edb8&"
import script from "./AdminManageClientRequest.vue?vue&type=script&lang=js&"
export * from "./AdminManageClientRequest.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports