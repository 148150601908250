/* eslint-disable */
<template>
  <div class="bookings-requiring-action">
    <AdminNavigation ref="navigation" indexProps="5" />
    <div class="top-panel">
      <Header />
      <div class="header-title-section">
        <div class="header-title"><h1>Bookings Requiring Action</h1></div>
        <div class="header-sub-title">Clients have requested action on the bookings listed below. View quick notes or click through to access the booking. Update the status of the request below.</div>
      </div>
    </div>
    <div class="panel">
      <AdminBookingsMenu indexProps="4" />
      <div class="content">
        <div class="tool-bar row">
          <div class="col d-flex justify-content-end">
            <el-input style="max-width: 200px" clearable class="mr-1" placeholder="Assigned to" @input="search()" v-model="listParam.AssignedToName" />
            <el-input style="max-width: 200px" clearable class="mr-1" placeholder="Client" @input="search()" v-model="listParam.ClientName" />
            <el-input style="max-width: 200px" clearable class="mr-1" placeholder="Client Contact" @input="search()" v-model="listParam.ContactName" />
            <el-input style="max-width: 200px" clearable class="mr-1" placeholder="Claimant Name" @input="search()" v-model="listParam.ClaimantName" />
            <el-select style="max-width: 200px" class="mr-1" placeholder="Status" @change="search()" clearable v-model="listParam.Status" >
              <el-option v-for="item in bookingRequestStatuses" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </div>
        </div>

        <div v-if="fetchedData" class="content-scroll-x">
          <div>
            <div>
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 10%" class="long-text">
                      Booking ID<img v-if="listParam.SortFlag === 1" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(2)" />
                      <img v-else-if="listParam.SortFlag === 2" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(1)"   />
                    </th>
                    <th style="width: 15%" class="long-text">
                      Client Contact<img v-if="listParam.SortFlag === 3" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(4)" />
                      <img v-else-if="listParam.SortFlag === 4" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(3)"   />
                    </th>
                    <th style="width: 15%" class="long-text">
                      Client<img v-if="listParam.SortFlag === 5" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(6)" />
                      <img v-else-if="listParam.SortFlag === 6" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(5)"   />
                    </th>
                    <th style="width: 15%" class="long-text">
                      Claimant<img v-if="listParam.SortFlag === 7" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(8)" />
                      <img v-else-if="listParam.SortFlag === 8" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(7)"   />
                    </th>
                    <th style="width: 15%" class="long-text">
                      Submitted<img v-if="listParam.SortFlag === 9" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(10)" />
                      <img v-else-if="listParam.SortFlag === 10" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(9)"   />
                    </th>
                    <th style="width: 11%" class="long-text">
                      Status<img v-if="listParam.SortFlag === 11" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(12)" />
                      <img v-else-if="listParam.SortFlag === 12" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(11)"   />
                    </th>
                    <th style="width: 14%" class="long-text">
                      Assigned To<img v-if="listParam.SortFlag === 13" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(14)" />
                      <img v-else-if="listParam.SortFlag === 14" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                      <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(13)"   />
                    </th>
                    <th style="width: 5%" class="icon-col">
                      <div class="icon-group">&nbsp;</div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div v-if="allBookings.length > 0" class="content-scroll-y">
              <table class="table">
                <tbody>
                  <tr v-for="(booking, index) in bookings" :key="index" class="is-icon">
                    <td style="width: 10%" class="long-text">
                      <router-link
                        :to="{
                          name: 'admin-manage-booking',
                          query: { id: booking.bookingId },
                        }"
                        >{{ booking.bookingId }}</router-link
                      >
                    </td>
                    <td style="width: 15%" class="long-text">
                      <router-link
                        :to="{
                          name: 'admin-manage-client',
                          query: { id: booking.clientId, type: 'edit-contact' },
                        }"
                        class="d-flex position-relative"
                      >
                        <el-tooltip  effect="dark" :content="booking.clientContactFullName" placement="bottom">
                          <span>{{ booking.clientContactFullName }}</span>
                        </el-tooltip>
                        <span
                          class="is-icon right-element"
                          data-placement="right"
                          data-toggle="tooltip"
                          :title="booking.requestedAction"
                          ><img src="@/assets/images/note_dark_icon.svg"
                        /></span>
                      </router-link>
                    </td>
                    <td style="width: 15%" class="long-text">
                      <router-link
                        :to="{
                          name: 'admin-manage-client',
                          query: { id: booking.clientId },
                        }"
                      >
                        <el-tooltip  effect="dark" :content="booking.clientBranchName" placement="bottom">
                          <span>{{ booking.clientBranchName }}</span>
                        </el-tooltip>
                      </router-link>
                    </td>
                    <td style="width: 15%" class="long-text">
                      <router-link
                        :to="{
                          name: 'admin-manage-case',
                          query: { id: booking.caseId },
                        }"
                      >
                        <el-tooltip  effect="dark" :content="booking.claimantFullName" placement="bottom">
                          <span>{{ booking.claimantFullName }}</span>
                        </el-tooltip>
                      </router-link>
                    </td>
                    <td style="width: 15%">
                      <el-tooltip  effect="dark" :content="formatDate(booking.dateRequested,  'DD/MM/YYYY hh:mm A', 'YYY-MM-DD HH:mm:ss' )" placement="bottom">
                        <span>{{
                          formatDate(
                            booking.dateRequested,
                            "DD/MM/YYYY hh:mm A",
                            "YYYY-MM-DD HH:mm:ss"
                          )
                        }}</span>
                      </el-tooltip>
                    </td>
                    <td style="width: 11%">
                      <select
                        class="w-100 textbox"
                        v-model="booking.status"
                        @change="changeBookingStatus(index)"
                      >
                        <option hidden value="">Select ...</option>
                        <option
                          v-for="(booking, index) in bookingRequestStatuses"
                          :key="index"
                          :value="booking.id"
                        >
                          {{ booking.name }}
                        </option>
                      </select>
                    </td>
                    <td style="width: 14%">
                      <el-select
                        class="w-100"
                        :loading="loading" filterable remote :remote-method="remoteMethod"
                        v-model="booking.assignedTo"
                        @change="changeAssignedTo(index)"
                      >
                        <el-option v-for="(user, index) in users" :key="index" :value="user.id" :label="user.name">
                          {{ user.name }}
                        </el-option>
                      </el-select>
                    </td>
                    <td style="width: 5%">
                      <div class="is-icon">
                        <a :href="booking.zohoLink" target="_blank"
                          ><img
                            src="@/assets/images/zoho_icon.svg"
                            data-placement="right"
                            data-toggle="tooltip"
                            title="Link to Zoho"
                        /></a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else class="p-3">No results found.</div>
          </div>
        </div>

        <div v-if="totalCount > listParam.PageSize" class="pagination">
          Page
          <div class="pagination-group">
            <div class="border-icon pagination-current dropdown">
              <div class="pagination-current-text">{{ listParam.PageNum }}</div>
              <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
              <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                <div class="dropdown-item" v-for="item in (1, Math.ceil(totalCount/listParam.PageSize))" :key="item" :class="listParam.PageNum === item ? 'selected' : ''" :value="item" @click="selectPage(item)">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="pagination-total">of &nbsp; {{ Math.ceil(totalCount/listParam.PageSize) }}</div>
        </div>
        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from "@/assets/js/utilities"
import AdminNavigation from "@/components/AdminNavigation"
import AdminBookingsMenu from "@/components/AdminBookingsMenu"
import Header from "@/components/Header"
import { debounce } from 'lodash';

export default {
  name: "AdminBookingsAwaitingDictation",
  components: {
    AdminNavigation,
    AdminBookingsMenu,
    Header,
  },
  data() {
    return {
      totalCount: 0,
      fetchedData: false,
      successMessage: "",
      loading: false,
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      bookings: [],
      allBookings: [],
      limit: 10,
      pagination: utilities.initPagination(),

      byClientContact: false,
      byClient: false,
      byClaimant: false,
      bySubmitted: false,
      byStatus: false,
      byAssignedTo: false,
      byBookingId: false,

      searchAssignedTo: '',
      searchClient: '',
      searchClientContact: '',
      userParam: {
        PageSize: 10,
        PageNum: 1,
        SortField: 'UserStatus',
        SortBy: 'DESC',
        UserName: null,
        RoleID: '3,6'
      },
      users: [],
      bookingRequestStatuses: [],
      listParam: {
        PageSize: 10,
        PageNum: 1,
        SortFlag: 0,
        AssignedToName: '',
        ClaimantName: '',
        ClientName: '',
        ContactName: '',
        Status: null
      },
    }
  },
  computed: {},
  methods: {
    async remoteMethod(query) {
      this.userParam.UserName = query
      this.loading = true
      let {users} = await utilities.getUsersNew(this.userParam)
      this.users = users.filter(item => item.status)
      this.loading = false
    },
    selectPage(index) {
      this.listParam.PageNum = index
      this.loadBookingActions()
    },
    sortParam(v) {
      this.listParam.SortFlag = v
      this.selectPage(1)
    },
    search: debounce(function () {
      this.selectPage(1)
    }, 800),
    formatDate(dateToFormat, newFormat, currentFormat) {
      return utilities.formatDate(dateToFormat, newFormat, currentFormat)
    },
    async changeBookingStatus(index) {
      this.$store.dispatch("loading/setLoadComponent", true)
      if(this.bookings[index].status != 1 && this.bookings[index].assignedTo == '') {
        // Assigned to person who logged in
        this.bookings[index].assignedTo = this.user.id
        var id = this.bookings[index].id
        var status = Number(this.bookings[index].status)
        var i = this.allBookings.findIndex(x => x.id == id)
        if(i > -1) {
          this.allBookings[i].status = status
          this.allBookings[i].assignedTo = this.user.id
        }
      }
      var result = await this.putBookingAction(index, true)
      if (result) {
        this.successMessage = "Booking action updated successfully."
        utilities.showAlert(".alert-success")
      }
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    async changeAssignedTo(index) {
      this.$store.dispatch("loading/setLoadComponent", true)
      // Change status to assigned
      this.bookings[index].status = 2
      var id = this.bookings[index].id
      var assignedTo = Number(this.bookings[index].assignedTo)
      var i = this.allBookings.findIndex(x => x.id == id)
      if(i > -1) {
        this.allBookings[i].status = 2
        this.allBookings[i].assignedTo = assignedTo
      }
      var result = await this.putBookingAction(index)
      if (result) {
        this.successMessage = "Booking action updated successfully."
        utilities.showAlert(".alert-success")
      }
      this.$store.dispatch("loading/setLoadComponent", false)
    },
    putBookingAction(index, isChangeStatus) {
      var params = {
        UserID: this.user.id,
        Username: this.user.name,

        BookingActionRequestID: this.bookings[index].id,
        AssignedTo: Number(this.bookings[index].assignedTo),
        BookingActionRequestStatus: Number(this.bookings[index].status),
        BookingActionRequestStatusChanged: 0,
        BookingID: this.bookings[index].bookingId,
        DateRequested: this.bookings[index].dateRequested,
        RequestedBy: this.bookings[index].requestedBy,
        RequestedAction: this.bookings[index].requestedAction,
      }
      if(!isChangeStatus) params.BookingActionRequestUserAssigned = params.AssignedTo == 0 ? 0 : 1

      if(this.allBookings[index].status != 3 && this.bookings[index].status == 3) params.BookingActionRequestStatusChanged = 1
      var callback = (response) => {
        // Reload if change to Completed
        if(this.bookings[index].status == 3) this.loadBookingActions()
      }
      return utilities.putBookingAction(params, callback)
    },

    async loadBookingActions() {
      this.$store.dispatch("loading/setLoadComponent", true)
      let {data, total} = await utilities.getBookingActionsNew(this.listParam)
      let bookings = data.filter(x => x.status !== 3)
      this.totalCount = total
      this.allBookings = _.cloneDeep(bookings)
      this.bookings = _.cloneDeep(bookings)
      this.allBookings.filter(item => item.assignedTo).forEach(item => {
        if(this.users.filter(user => user.id === item.assignedTo).length === 0) {
          this.users.push({id: item.assignedTo, name: decodeURIComponent(item.assignedToName)})
        }
      })
      this.$store.dispatch("loading/setLoadComponent", false)
    },

    async loadData() {
      const apiArr = [utilities.getBookingActionsNew(this.listParam), utilities.getUsersNew(this.userParam)]
      const r = await Promise.all(apiArr)
      let {data, total} = r[0]
      const bookings = data.filter(x => x.status != 3)
      this.totalCount = total
      this.allBookings = _.cloneDeep(bookings)
      this.bookings = bookings
      let {users} = r[1]
      this.users = users.filter(item => item.status)
      this.allBookings.filter(item => item.assignedTo).forEach(item => {
        if(this.users.filter(user => user.id === item.assignedTo).length === 0) {
          this.users.push({id: item.assignedTo, name: decodeURIComponent(item.assignedToName)})
        }
      })
      console.log(this.users)
      this.bookingRequestStatuses = utilities.getBookingRequestStatuses()
    },
  },
  async beforeMount() {
    this.$store.dispatch("loading/setLoadComponent", true)
    $("body>.tooltip").remove()
    await this.loadData()
    this.fetchedData = true
    this.$store.dispatch("loading/setLoadComponent", false)
  },
}
</script>
