/* eslint-disable */
<template>
  <div class="ManageBooking">
    <Navigation indexProps="4" />
    <div class="top-panel">
      <Header />
      <div v-if="showContent" class="header-title-section">
        <div class="header-title"><h1 >{{ setTitle() }}</h1></div>
        <div class="header-sub-title">{{ setSubTitle() }}</div>
      </div>
      <div v-else class="header-title-section"><h5>Invalid Booking Information</h5></div>
    </div>
    <div class="panel">
      <ClientBookingMenu indexProps="3" />
      <AlertModal ref="alertModal" />
      <div class="content" v-if="showContent">
        <div class="top-detail pt-2">
          <div class="row m-0">
            <div class="col-4 p-0 center-title">
              <div class="row center-title m-0 display-in-row">
                <div class="col-2 p-0" v-if="booking.bookingDateTime != ''">
                  <div class="border-image">
                    <div class="border-image-number" :key="datekey">{{formatDate(booking.bookingDateTime, 'DD', 'YYYY-MM-DD HH:mm:ss')}}</div>
                    <div class="border-image-text" :key="timeKey">{{formatDate(booking.bookingDateTime, 'MMM, YYYY hh:mm A', 'YYYY-MM-DD HH:mm:ss')}}</div>
                    <!-- <div class="border-image-text" v-else :key="timeKey">{{formatDate(booking.bookingDateTime, 'MMM, YYYY', 'YYYY-MM-DD HH:mm:ss')}}</div> -->
                  </div>
                </div>
                <div class="col center-title ml-4">
                  {{getLocationName()}}
                </div>
              </div>
            </div>
            <div class="col-3 center-title p-0">
              <div v-if="this.singleCase.id == '' || booking.claimantFullName != '' && booking.dateOfBirth != '' && booking.claimTypeName != ''">
                <div class="name" v-if="booking.claimantFullName">{{booking.claimantFullName}}</div>
                <p v-if="booking.dateOfBirth != ''">D.O.B.  {{formatDate(booking.dateOfBirth, 'DD/MM/YYYY')}}</p>
                <!-- <p v-if="booking.commencementDate != ''">case Commenced:  {{formatDate(booking.commencementDate, 'DD/MM/YYYY')}}</p> -->
                <p v-if="booking.claimTypeName != ''">Type:  {{booking.claimTypeName}}</p>
              </div>
            </div>
            <div class="col p-0">
              <div class="row m-0">
                <div class="col-2 p-0">
                  <div class="rounded-image medium-rounded-image m-t-0 m-t-b"  v-if="specialist.profileThumbnail">
                    <div class="profile-image-content"><img :src="specialist.profileThumbnail" /></div>
                  </div>
                  <div class="box-col" v-else>
                    <div class="rounded-image medium-rounded-image m-t-0 m-t-b">
                      <div class="profile-image-content notice-background"><s class="text-initials">{{getInitials(specialist)}}</s></div>
                    </div>
                  </div>
                </div>
                <div class="col center-title ml-4">
                  <div>
                    <div class="name">
                      {{specialist.fullName}}
                      <img v-if="requiresTravel" class="icon-20" src="@/assets/images/travel_black_circle_icon.svg" />
                      <img v-if="videoLinkAvailable == 2" class="icon-20 ml-2" src="@/assets/images/video_black_icon.svg" />
                    </div>
                    <div class="">
                      {{getSpecialistInfo(specialist.practiceLocationId, specialist.locations)}}
                    </div>
                    <div class="">
                      {{getBookingTypeById(this.booking.bookingTypeId)}}
                    </div>
                    <div v-if="booking.videoLinkTestStatus" class="is-icon"><img src="@/assets/images/video_black_icon.svg" /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="box" v-if="displayBookingDetails">
          <div class="box-heading col-space-between">
            <div>Booking details</div>
          </div>
          <div class="box-body">
            <div class="box-row" >
              <div class="box-col box-col-left left-label required">Booking Type</div>
              <div class="box-col box-col-right">
                <select
                    v-model="booking.bookingTypeId"
                    @change="onChangeBookingType"
                    class="textbox w-100">
                  <option value="" hidden>Please choose ...</option>
                  <option v-for="type in allBookingTypes" :key="type.id" :value="type.id">
                    {{ type.clientSideName }}
                  </option>
                </select>
                <div v-if="hasError && !validateBookingType" class="text-danger">
                  Booking Type is required.
                </div>
              </div>
            </div>
            <div class="box-row" v-if="showClientContact">
              <div class="box-col box-col-left left-label required">Client Contact</div>
              <div class="box-col box-col-right align-items-center">
                <Dropdown ref="dropdownClientContact"
                  class="contact-selecter select-dropdown"
                  :options="listContact"
                  v-on:selected="selectedContact"
                  v-on:filter="filterReportTo"
                  :maxItem="10000"
                  placeholder="Select a contact">
                </Dropdown>
                <div class="text-danger mt-5" v-if="hasError && !validateCaseOwner">
                  Client Contact is required.
                </div>
              </div>
            </div>
            <div class="box-row" v-if="!booking.bookingTypeId == 6">
              <div  class="box-col box-col-left">Will Accept Earlier Appointment</div>
              <div class="box-col box-col-right">
                <toggle-button @change="onChartToggleChange(1)" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.willAcceptEarlier"/>
              </div>
            </div>
            <div v-if="booking.bookingTypeId == 4 || booking.bookingTypeId == 7" class="box-row">
              <div class="box-col box-col-left left-label required">Claimant State During Booking</div>
              <div class="box-col box-col-right">
                <div v-if="booking.id == ''" class="pl-2">Which state will your claimant be located in for the appointment?</div>
                <select class="w-50" v-model="booking.claimantStateDuringBooking" :class="hasError && !validateState ? 'is-invalid' : ''">
                  <option value="" hidden>Please choose</option>
                  <option v-for="state in states" :value="state.value">{{ state.text }}</option>
                </select>
                <div v-if="hasError && !validateState" class="text-danger pl-2">State is required.</div>
              </div>
            </div>
            <!-- ************************* -->

            <div class="box-row" v-if="!disableCaseField" :class="disableCaseField ? 'disabled-element' : ''">
              <div class="box-col box-col-left required">Claimant Name</div>
              <div class="box-col box-col-right">
                <div v-if="!isEditName" @click="openAddNameModal" :key="nameKey" class="parent is-icon full-width-div">
                  <input type="text" disabled class="child px-3 placeholder-opacity" placeholder="Please click here to enter the Claimant details"/>
                  <!-- <div class="text-danger" v-if="hasError && !validateClaimantName">
                    Please enter claimant's name
                  </div> -->
                </div>
                <span v-else :key="nameKey" @click="openAddNameModal" class="padding-left-10">
                  {{ this.booking.claimantFullName }}
                </span>
                <div class="text-danger" v-if="hasError && !validateClaimantName">
                  Please enter claimant's name
                </div>
              </div>
              <AddNameModal ref="addNameModal"/>
            </div>
            <div class="box-row" v-if="!disableCaseField" :class="disableCaseField ? 'disabled-element' : ''">
              <div  class="box-col box-col-left">Claimant Salutation</div>
              <div class="box-col box-col-right">
                <el-select :disabled="disableCaseField" class="w-50" v-model="booking.Salutation" filterable clearable placeholder="Claimant Salutation">
                  <el-option v-for="item in salutationList" :key="item.value" :value="item.value" :label="item.value"></el-option>
                </el-select>
              </div>
            </div>
            <div class="box-row" v-if="!disableCaseField" :class="disableCaseField ? 'disabled-element' : ''">
              <div  class="box-col box-col-left">Claimant Preferred Name</div>
              <div class="box-col box-col-right">
                <input :disabled="disableCaseField" class="textbox referance" type="text" placeholder="Claimant Preferred Name" v-model="booking.PreferredGivenName"/>
              </div>
            </div>
            <div class="box-row" v-if="!disableCaseField" :class="disableCaseField ? 'disabled-element' : ''">
              <div class="box-col box-col-left">Date of Birth</div>
              <div class="box-col box-col-right box-col-center">
                <datepicker
                  class="w-50 date-of-birth"
                  :disabled="disableCaseField"
                  v-model="booking.dateOfBirth"
                  format="dd/MM/yyyy"
                  :typeable="true"
                  placeholder="dd/mm/yyyy"></datepicker>
                  <div v-if="hasError && !validateDateOfBirth" class="text-danger">Date of Birth is required.</div>
              </div>
            </div>
            <div class="box-row" v-if="!disableCaseField" :class="disableCaseField ? 'disabled-element' : ''">
              <div  class="box-col box-col-left">Reference Number</div>
              <div class="box-col box-col-right">
                <input :disabled="disableCaseField" class="textbox referance" type="text" placeholder="Reference Number" v-model="booking.caseReference"/>
                <div v-if="hasError && !validateReferenceNumber" class="text-danger">Reference Number can not contain ' " or `</div>
              </div>
            </div>
            <div class="box-row" :class="disableCaseField ? 'disabled-element' : ''">
              <div class="box-col box-col-left">Claimant Contact Details</div>
              <div class="box-col box-col-right">
                <div v-if="!isEditContact" @click="openManageContactDetails" class="parent is-icon full-width-div">
                  <input type="text" class="child placeholder-opacity" disabled placeholder="Please click here to enter contact details" />
                </div>
                <span v-else :key="contactKey" @click="openManageContactDetails" class="padding-left-10">
                  <span v-if="this.booking.claimantMobile" class='text-bold'>M: </span> {{ this.booking.claimantMobile }}
                  <span v-if="this.booking.claimantEmail" class='text-bold'>E: </span> {{ this.booking.claimantEmail }}
                  <span v-if="this.booking.claimantPhone1" class='text-bold'>P: </span> {{ this.booking.claimantPhone1 }}
                  <span v-if="this.booking.claimantPhone2" class='text-bold'>L: </span> {{ this.booking.claimantPhone2 }}
                </span>
                <div v-if="hasError && !validateAttendance && !disableCaseField" class="text-danger" style="position: absolute; z-index: 1; top: 30px;">Contact Details are required.</div>
                <AddClientContactModal ref="addClientContactModal" :typrProps="'fromBooking'"/>
              </div>
            </div>
            <div class="box-row" v-if="!hideAttendance">
              <div  class="box-col box-col-center box-col-left">Red Health to confirm attendance with Claimant</div>
              <div class="box-col box-col-right">
                <toggle-button class="pl-2" @change="onChartToggleChange(3)" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.redHealthMayContactClaimant"/>
              </div>
            </div>
            <div class="box-row" :class="disableCaseField ? 'disabled-element' : ''">
              <div class="box-col box-col-left">Claimant Address</div>
              <div class="box-col box-col-right">
                <div v-if="!isEditAddress" class="parent is-icon full-width-div" @click="openManageAddress" :key="addressKey">
                  <input class="child w-100 placeholder-opacity" disabled placeholder="Please click here to update claimant's address" />
                </div>
                <span class="padding-left-10" v-else @click="openManageAddress" :key="addressKey">{{booking.claimantFullAddress}}</span>
                <div  class="is-icon right-float-element" @click="openManageAddress">
                </div>
                <AddClientAddressModal ref="addClientAddressModal" :typrProps="'fromBooking'"/>
              </div>
            </div>
<!--            <div class="box-row">-->
<!--              <div  class="box-col box-col-left">On behalf of</div>-->
<!--              <div class="box-col box-col-right">-->
<!--                <input class="textbox behalf" type="text" placeholder="On behalf of" v-model="booking.onBehalfOf" />-->
<!--              </div>-->
<!--            </div>-->
            <div class="box-row">
              <div class="box-col box-col-left">Date of Accident/Event</div>
              <div class="box-col box-col-right box-col-center" style="display: flex;">
                <datepicker
                  class="w-50 accient-date"
                  format="dd/MM/yyyy"
                  :typeable="true"
                  :disabled="booking.overPeriodofTime"
                  placeholder="dd/mm/yyyy"
                  @selected="changeDate"
                  v-model="booking.accidentDate"></datepicker>
                  <div style="display: flex;align-items: center; padding-left: 10px;">
                    <toggle-button @change="onChartToggleChange(4)" class="togglePeriodOfTime" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.overPeriodofTime"/>  Over a period of time
                  </div>
              </div>
            </div>
            <div class="box-row" :class="disableCaseField ? 'disabled-element' : ''">
              <div class="box-col box-col-left required">Type of Claim</div>
              <div class="box-col box-col-right">
                <div v-if="!claimFocus && (booking.claimTypeId == '' || booking.claimTypeId == undefined)" @click="claimFocus = true" class="parent">
                  <input class="child w-50" type="text" disabled placeholder="Click to choose the Type of Claim" :class="hasError && !validateTypeOfClaim ? 'is-invalid' : ''" />
                </div>
                <select :disabled="disableCaseField" v-model="booking.claimTypeId" @change="changedClaimantType($event)" v-else :class="hasError && !validateTypeOfClaim ? 'is-invalid' : ''" class="w-50 textbox">
                  <option value="" hidden>Please choose type of claim...</option>
                  <option v-for="claimType in claimTypes" :value="claimType.id">{{ claimType.name }}</option>
                </select>
                <div class="text-danger" v-if="hasError && !validateTypeOfClaim">
                  Please choose type of claim
                </div>
              </div>
            </div>
            <div class="box-row">
              <div  class="box-col box-col-left">Affected Body Areas</div>
              <div class="box-col box-col-right box-col-center">
                <vue-tags-input
                  class="pr-10"
                  v-model="bodyAreaTag"
                  :tags="bodyAreaTags"
                  placeholder="Add Affected Body Areas"
                  :autocomplete-items="filteredBodyAreaItems"
                  @tags-changed="(newTags) => (bodyAreaTags = newTags)"
                  @focus="openDigram()"
               />
              </div>
              <div @click="openBodyDiagram" class="center-item">
                <img src="@/assets/images/choose.svg" alt="" class="right-element align-self-center is-icon pr-3"/>
              </div>
              <AddBodyDiagramModal style="top: 0px" ref="addBodyDiagramModal" />
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Other Body Areas</div>
              <div class="box-col box-col-right box-col-center">
                <vue-tags-input
                  v-model="otherBodyAreaTag"
                  :tags="otherBodyAreaTags"
                  :placeholder="this.booking.caseId == '' ? 'Add Other Body Areas':''"
                  @tags-changed="(newTags) => (otherBodyAreaTags = newTags)" />
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Conditions</div>
              <div class="box-col box-col-right box-col-center">
                <vue-tags-input
                  ref="conditions"
                  v-model="personConditionTag"
                  :tags="personConditionTags"
                  :add-only-from-autocomplete="true"
                  placeholder="Add Conditions"
                  :autocomplete-items="filteredPersonConditionsItems"
                  @tags-changed="updateConditions"
                />
              </div>
            </div>
            <div class="box-row" v-if="booking.bookingTypeId != 6 && booking.bookingTypeId != 7 && booking.bookingTypeId != 8">
              <div  class="box-col box-col-left">Interpreter Required?</div>
              <div class="box-col box-col-right">
                <toggle-button @change="onChartToggleChange(2)" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.interpreterRequired"/>
                <el-checkbox v-if="booking.interpreterRequired" class="ml-3" v-model="booking.OrganizeRequired" size="large" @change="changeFilter">Red Health to organize</el-checkbox>
              </div>
            </div>
            <div class="box-row" v-if="booking.interpreterRequired && !booking.OrganizeRequired">
              <div  class="box-col box-col-left">Interpreter Details</div>
              <div class="box-col box-col-right">
                <input class="textbox" type="text" placeholder="Interpreter Details" v-model="booking.interpreterDetails" />
                <div class="text-danger" v-if="hasError && !validateInterpreter">
                  Please input interpreter details
                </div>
              </div>
            </div>
            <div class="box-row">
              <div  class="box-col box-col-left">Send Report To</div>
              <div class="box-col box-col-right">
                <el-select class="w-100" v-model="booking.reportTo" filterable clearable>
                  <el-option v-for="contact in originalClientContacts" :key="contact.value" :value="contact.value" :label="contact.name">{{ contact.name }}</el-option>
                </el-select>
              </div>
            </div>
            <div v-if="showSendReportToOther" class="box-row" >
              <div  class="box-col box-col-left left-label">Send report Email</div>
              <div class="box-col box-col-right">
                <input class="textbox" type="text" placeholder="Send report to this email address" v-model="booking.reportTo"/>
                <div v-if="hasError && !validateSendEmail" class="text-danger">Email is incorrect.</div>
              </div>
            </div>
            <div class="box-row">
              <div  class="box-col box-col-left">Bill to</div>
              <div class="box-col box-col-right">
                <el-select class="w-100" v-model="booking.billTo" filterable clearable>
                  <el-option v-for="contact in originalClientContacts" :key="contact.value" :value="contact.value" :label="contact.name">{{ contact.name }}</el-option>
                </el-select>
<!--                <Dropdown-->
<!--                  ref="dropdownEmailBillTo"-->
<!--                  class="bill-to-selecter select-dropdown"-->
<!--                  :options="listContact"-->
<!--                  v-on:selected="selectedBillTo"-->
<!--                  v-on:filter="filterReportTo"-->
<!--                  :maxItem="10000"-->
<!--                  placeholder="Select a Client">-->
<!--                </Dropdown>-->
              </div>
            </div>
            <div v-if="showBillToEmail" class="box-row">
              <div class="box-col box-col-left left-label">Bill to Email</div>
              <div class="box-col box-col-right">
                <input class="textbox" type="text" placeholder="Bill to this email address" v-model="booking.billTo"/>
                <div v-if="hasError && !validateBillEmail" class="text-danger">Email is incorrect.</div>
              </div>
            </div>

            <!-- ************************* -->
            <!-- <div class="box-row">
              <div class="box-col box-col-left">Commencement Date</div>
              <div class="box-col box-col-right box-col-center">
                <datepicker
                  class="w-50"
                  format="dd/MM/yyyy"
                  :typeable="true"
                  placeholder="dd/mm/yyyy"
                  v-model="booking.commencementDate"
                  :input-class="hasError && !validateCommencementDate ? 'is-invalid' : ''"
                  ></datepicker>
                <div class="text-danger" v-if="hasError && !validateCommencementDate">
                  Please enter commencement date
                </div>
              </div>
            </div> -->

            <!-- <div class="box-row" v-if="booking.reportTo == -1">
              <div  class="box-col box-col-left">Email Address</div>
              <div class="box-col box-col-right">
                <input class="textbox" type="text" placeholder="Send report to this email address" v-model="booking.reportToEmailAddress"/>
              </div>
            </div> -->

          </div>
        </div>
        <div class="box" v-if="displayBookingDetails">
          <div class="box-heading tab">
            <div class="tab-heading px-5  notes-heading tab-selected" @click="openNotes">Notes</div>
            <div class="tab-heading px-5 files-heading" @click="openFiles">Files</div>
            <div class="col-space-between is-icon right-float-element">
              <v-btn v-if="showAddNote" class="add-icon mr-3" dark @click="openAddNoteModal">Add New</v-btn>
              <v-btn v-else class="add-icon mr-3" dark @click="openFilesBox">Add New</v-btn>
              <AddNoteModal ref="addNoteModal" :bookingProps="booking"/>
            </div>
          </div>
          <div class="box-body notes-content hidden-element">
            <div class="box-row overflow-hidden" v-for="(note, index) in booking.bookingNotes" :key="index">
              <div class="box-col box-col-left">{{user.name}}</div>
              <div class="box-col box-col-left">{{formatDate(note.NoteDateTime, 'DD/MM/YYYY hh:mm A', "YYYY-MM-DD HH:mm:ss.ssssss")}}</div>
              <div @click="editNote(index)" class="box-col box-col-right box-col-long col-center-content text-break" style="max-width: 60%;">
                {{note.Note}}
              </div>
              <div v-if="booking.bookingNotes.length > 0" class="col col is-icon center-title" style="max-width: 10%;" @click="deleteNote(note.id, index)"><img class="right-align" src="@/assets/images/delete_icon.svg" /></div>
            </div>
          </div>

          <div class="box-body files-content hidden-element">
            <div>
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width:15%" class="long-text">
                        File Type
                      </th>
                      <th style="width:50%" class="long-text">
                        File Name
                      </th>
                      <th style="width:15%" class="long-text">
                        Uploaded By
                      </th>
                      <th style="width:10%" class="long-text">
                        Status
                      </th>
                      <th style="width:10%" class="icon-col"><div class="icon-group">&nbsp;</div></th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="content-scroll-y" style="height:auto;max-height:460px;">
                <table class="table remove-min-height">
                    <tbody>
                      <tr v-for="(file, index) in fileParams" :key="index" class="py-0">
                        <td style="width:15%" class="long-text">
                          <el-select v-model="file.FileTypeID" placeholder="Select File Type" class="w-100" @change="changeFileType($event, index)">
                            <el-option v-for="fileType in fileTypes" :value="fileType.id" :key="fileType.id" :label="fileType.name">{{ fileType.name }}</el-option>
                          </el-select>
                        </td>
                        <td style="width:50%" class="long-text">{{file.FileName}}</td>
                        <td style="width:15%" class="long-text">{{file.UploadedByName}}</td>
                        <td style="width:10%" class="long-text">
                          <span style="padding-left: 10px;">Draft</span>
                        </td>
                        <td style="width:10%; vertical-align: middle;" @click="deleteFile(index)" class="long-text">
                          <div class="icon-group d-flex justify-content-center"><img src="@/assets/images/delete_icon.svg" /></div>
                        </td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
            <div class="file-upload-area border-gray-300 mt-5" @dragover="dragover" @dragleave="dragleave" @drop="drop">
              <input
                type="file"
                multiple
                name="fields[assetsFieldHandle][]"
                id="assetsFieldHandle"
                class="w-px h-px opacity-0 overflow-hidden absolute"
                @change="onChange"
                ref="file"
                accept=".pdf,.jpg,.jpeg,.png,.docx,.xlsx,.txt,.gif,.ds2,.mp3,.mp4,.mov,.AVI,.MKV" />
              <label for="assetsFieldHandle" class="block cursor-pointer d-flex justify-content-center flex-column align-items-center">
                <div>Drop files in this area or <span style="text-decoration:underline" class="is-icon">click here</span> to upload files.</div>
                <div>Files over 200MB in file size may cause errors. If you need to upload files of this size, please contact us on 1300 100 733</div>
              </label>
            </div>
          </div>
        </div>

        <div v-if="displayBookingDetails" class="box-row item-right box-col-next">
          <div><v-btn dark @click="tapNext()">Next</v-btn></div>
        </div>
        <div class="box" v-if="displayBookingSummery" :key="summaryKey">
          <div class="box-heading col-space-between">
            <div>Booking Summary</div>
          </div>
          <div class="box-body">
            <!-- <div class="box-row" v-if="this.booking.bookingTypeId != 6 && booking.bookingTypeId != 8">
              <div class="box-col" v-html="setDateInfo()"></div>
            </div> -->
<!--            <div class="box-row" v-if="this.showBookingStatus">-->
<!--              <div class="box-col box-col-left">Booking Status</div>-->
<!--              <div class="box-col box-col-right">-->
<!--                <v-btn-toggle v-model="selected" @change="changedBookingStatus">-->
<!--                  <span v-for="value in values" :key="value.value">-->
<!--                    <el-tooltip class="item" effect="dark" :content="value.text" placement="top-start">-->
<!--                      <v-btn class="radio-toggle">-->
<!--                        {{ value.label }}-->
<!--                      </v-btn>-->
<!--                    </el-tooltip>-->
<!--                  </span>-->
<!--                </v-btn-toggle>-->
<!--              </div>-->
<!--            </div>-->
            <!-- <div class="box-row" v-if="!this.isWaitlisted">
              <div class="box-col box-col-left">Private</div>
              <div class="box-col box-col-right">
                <toggle-button data-placement="right" data-toggle="tooltip" title="No other user will see the details of this booking" @change="onChartToggleChange(5)" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.private" />
              </div>
            </div> -->
            <!-- renamed OTFCE -->
            <div class="box-row" v-if="this.isWaitlisted || !this.hideOtherStatus">
              <div class="box-col box-col-left">Is a Plus Service required for this booking? <img class="help ml-2" data-html="true" rel="tooltip" data-toggle="tooltip" data-placement="right"
                title="A combined service conducted by the examiner and an OT. It is a modified FCE known as FCElite which provides further robustness to the examiner's report."
                src="@/assets/images/infoicon.svg" style="width: 25px;" /></div>
              <div class="box-col box-col-right">
                <toggle-button @change="onChartToggleChange(6)" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.otfceRequired" />
              </div>
            </div>
            <div class="box-row" v-if="!this.hideOtherStatus">
              <div class="box-col box-col-left">Do you need a phone call with the doctor after the appointment? (additional charges may apply)</div>
              <div class="box-col box-col-right">
                <toggle-button @change="onChartToggleChange(7)" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.callClientAfterAppointment"/>
              </div>
            </div>
            <div class="box-row" v-if="!this.hideOtherStatus && !hideAttendance">
              <div class="box-col box-col-left">Red Health to confirm attendance with Claimant</div>
              <div class="box-col box-col-right">
                <toggle-button @change="onChartToggleChange(3)" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.redHealthMayContactClaimant"/>
              </div>
            </div>
            <div class="box-row" v-if="this.client.paymentDeferralOption">
              <div class="box-col box-col-left">Is Fee Deferral required?</div>
              <div class="box-col box-col-right">
                <toggle-button @change="onChartToggleChange(9)" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.feeDeferralRequired"/>
              </div>
            </div>
            <div class="box-row" v-if="this.booking.id">
              <div class="box-col box-col-left required">Reason for Reschedule</div>
              <div class="box-col box-col-right">
                  <textarea v-model="reason" placeholder="Enter Reschedule reason" rows="1" maxlength="100"/>
                  <div class="text-danger" v-if="hasError && !validateReschedule">
                    Please enter the Reschedule reason
                  </div>
              </div>
            </div>
            <div  v-if="booking.bookingTypeId != 6 && booking.bookingTypeId != 8 && !hideWarning" class="box-row box-warning">
              <div class="box-col Warning-left">
                Warning: non-attendance by the claimant will attract a fee.
              </div>
            </div>
          </div>
        </div>
        <div class="box-row item-right" v-if="displayBookingSummery">
          <div style="padding-right:20px"><v-btn :disabled="disableButton" class="btn-light" @click="tapEdit()">Back</v-btn></div>
          <div><v-btn :disabled="disableButton" dark @click="tapSubmit()">Book</v-btn></div>
        </div>

        <div class="aler-green alert alert-success hidden-element" style="min-height: 110px;">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
          <div v-if="showAddCalender" style="display: flex; padding-top: 8px;">
            <div class="icon-group center-element" style="left: 60%;">
              <!-- <v-btn class="alert-button"style="min-width: 210px;"  @click="downloadToCalender()">Add to my Calendar</v-btn> -->
              <v-btn class="alert-button" @click="handleClose()">Ok</v-btn>
            </div>
          </div>
        </div>
        <div class="alert-waitlisted alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="alert alert-danger general-alert-danger hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> {{ errorMessage }}
        </div>
        <div style="width: fit-content; left: 20%; top: 70%" class="tentative-alert alert alert-warning hidden-element">
          <a class="close" title="close" @click="closeDialog">×</a>
          <b>Warning:</b> {{ errorMessage }}
        </div>

        <div class="rescheduled-alert alert alert-warning hidden-element">
          <a class="close" title="close">×</a>
          <b>Warning:</b> {{ errorMessage }}
          <v-btn class="alert-button ml-4" @click="backToHome()">Ok</v-btn>
        </div>

        <div class="confirm alert alert-warning hidden-element">
          <div class="icon-group center-element" style="left: 60%;">
            <v-btn class="alert-button" @click="tapButtonConfirmation(false)">New Case</v-btn>
            <v-btn class="alert-button" @click="tapButtonConfirmation(true)">Existing Case</v-btn>
          </div>
          <a class="close" title="close" @click="tapButtonConfirmation(false)">×</a>
          A case for {{this.booking.claimantFullName +' ' + formatDate(this.booking.dateOfBirth, 'DD/MM/YYYY')}} already exists.  Create a new case or attach to existing?
        </div>

        <!-- <div v-cloak class="box" v-else-if="displayFileSummery">
          <div class="box-heading col-space-between">
            <div>Booking Summary for new case SB1234567</div>
          </div>
          <div class="box-body">
            <div class="box-row">
              <div class="box-col box-col-left">Review Due: Monday 08/12/2020</div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left">Private</div>
              <div class="box-col box-col-right">
                <toggle-button @change="onChartToggleChange(4)" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true"v-model="this.togglePrivate" />
              </div>
            </div>
            <div class="box-row item-right">
              <div style="padding-right:30px"><v-btn dark @click="tapEdit()">Edit</v-btn></div>
              <div><v-btn dark @click="tapNextButton()">Submit</v-btn></div>
            </div>
          </div>
        </div> -->

        <div class="row align-items-center" style="padding: 50px 0px;" v-if="displayBanner && displayBookingSummery" :key="bannerKey">
          <div class="col box-ads">
            <img src="@/assets/images/payment_deferral_promo.jpg" />
          </div>
        </div>
        <AdditionalBookingModal  ref="additionalBookingModal" />
      </div>
    </div>
    <el-dialog  title="Warning" :visible.sync="confirmDialog"
                width="50%">
      <div v-if="currentParam">
        <div style="word-break: break-word">{{`The new appointment slot can be either a VideoLink assessment, or an In Person assessment at ${getLocationName()}. Please select which type of appointment you would like:`}}</div>
        <el-select style="margin-top: 20px; width: 400px" placeholder="Please select a type" v-model="currentParam.BookingTypeID" @change="onChangeSelect">
          <el-option :value="2" label="Independent Medical Examination">Independent Medical Examination</el-option>
          <el-option :value="4" label="Independent Medical Examination By Video">Independent Medical Examination By Video</el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="confirmDialog = false; currentParam = null; tapEdit()">Cancel</el-button>
    <el-button type="primary" @click="confirmChange">OK</el-button>
  </span>
    </el-dialog>
    <Chat />
    <DuplicateCaseModal :dialogVisible="dialogVisible" :hasToChooseCase="hasToChooseCase" @selectCase="selectCase" :result="result" @closeModal="closeModal"/>
  </div>
</template>

<script>
import utilities from '@/assets/js/utilities'
import Navigation from '@/components/Navigation'
import ClientBookingMenu from '@/components/ClientBookingMenu'
import Header from '@/components/Header'
import Chat from '@/components/Chat'
import AddNoteModal from '@/components/AddNoteModal'
import AlertModal from '@/components/AlertModal'
import AddNameModal from '@/components/AddNameModal'
import AddClientContactModal from '@/components/AddClientContactModal'
import AddClientAddressModal from '@/components/AddClientAddressModal'
import AddBodyDiagramModal from '@/components/AddBodyDiagramModal'
import AdditionalBookingModal from '@/components/AdditionalBookingModal'
import Dropdown from 'vue-simple-search-dropdown';
import DuplicateCaseModal from '@/components/case/DuplicateCaseModal'

export default {
  name: 'ManageBooking',
  components: {
    Dropdown,
    Navigation,
    ClientBookingMenu,
    Header,
    Chat,
    DuplicateCaseModal,
    AddNoteModal,
    AlertModal,
    AddNameModal,
    AddClientContactModal,
    AddClientAddressModal,
    AddBodyDiagramModal,
    AdditionalBookingModal
  },
  data() {
    return {
      currentParam: null,
      result: [],
      hasToChooseCase: false,
      dialogVisible: false,
      editingIndex: null,
      salutationList: utilities.initSalutation(),
      booking: utilities.initBooking(),
      originalBooking: utilities.initBooking(),
      dateOfBirth:"",
      commencemenrDate:"",
      dateAccident: "",
      fillOrder: "",
      currentValue: '',
      placeholder: "Add",
      successMessage: "",
      errorMessage: "",
      hasError: false,
      bookingTypesLocal: utilities.getCreateSpecialistsBookingTypes(),
      isEditContact: false,
      isEditAddress: false,
      toggleEarlierAppointment: false,
      toggleWithClaimant: false,
      togglePeriodOfTime: false,
      toggleOTFCE: false,
      togglePrivate: false,
      toggleAfterAppointment: false,
      toggleAttendance:false,
      displayBanner: false,
      displayBookingSummery: false,
      displayBookingDetails: true,
      showWarning: false,
      isEditName: false,
      showAddCalender: true,
      loaded: false,
      showBookingStatus: true,
      hideOtherStatus: true,
      claimFocus: false,
      isWaitlisted: false,
      showContent: true,
      hideAttendance: false,
      hideWarning: false,
      showMsg1: false,
      showMsg2: false,
      disableButton: false,
      requiresTravel: false,
      showAddNote: true,
      disableCaseField: false,
      isMultipleLocation: false,
      isSameLocation: false,
      videoLinkAvailable: 1,
      newBookingId: 0,
      reason: '',
      qualificationId: '',
      originalSpecialistId: '',
      originalBookingDateTime: '',
      locations: [],
      originalClientContacts: [],
      bookingProps: [],

      bodyAreaTag: "",
      otherBodyAreaTag: "",
      personConditionTag: "",
      bodyAreas: [],
      bodyAreaTags: [],
      otherBodyAreaTags: [],
      deletedBodyTags: [],
      personConditions: [],
      personConditionTags: [],
      deletedConditionTags: [],
      // Validate Dates
      isValidDateOfBirth: true,
      isValidAccidentDate: true,
      isFromSpecialist: false,
      hideInfo: false,
      showClientContact: false,
      originalClaimantConfirmed: '',

      clientContacts: [],
      claimTypes: [],
      singleCase: [],
      filelist: [],
      fileParams: [],
      specialist: [],
      addedBodyArea: [],
      addedConditions: [],
      allBookingTypes: [],
      specialistTimes: [],
      availableTimes:[],
      cases: [],
      searchCases: [],
      minimumAppointment: [],
      bookingLog: [],
      videoLinkLocation: [],
      relatedServices: [],
      states: [],

      showBillToEmail: false,
      showSendReportToOther: false,
      listContact: [],
      originalSpecialistFullName: '',
      specialistAvailabilityId: 0,
      addressKey: 0,
      noteKey: 0,
      nameKey: 0,
      confirmDialog: false,
      contactKey : 1,
      datekey: 2,
      timeKey: 3,
      selected: 0,
      summaryKey: 1,
      selectedCount: 0,
      timer: null,
      bannerKey: 1,
      values: [
				{ label: 'Confirmed', value: 3, text: 'This will lock in the appointment you have selected' },
				{ label: 'Tentative', value: 2, text: 'This will hold the appointment for you for 24 hours. If you haven\'t confirmed the booking before this time, the appointment will be released, and someone may book that appointment.' },
			],
      user: _.cloneDeep(this.$store.getters["user/getUser"]),
      fileTypes: [],
    }
  },
  watch: {
    booking: {
      handler(value) {
        if(this.loaded) {
          this.$store.dispatch("setNewObject", value)
        }
     },
     deep: true,
   },
   bodyAreaTags: {
     handler(value) {
       localStorage.setItem('storedBodyArea', JSON.stringify(this.bodyAreaTags))
     },
     deep: true,
   },
   otherBodyAreaTags: {
     handler(value) {
         localStorage.setItem('storedOtherBodyArea', JSON.stringify(this.otherBodyAreaTags))
     },
     deep: true,
   },
   personConditionTags: {
     handler(value) {
         localStorage.setItem('storedPersonCondition', JSON.stringify(this.personConditionTags))
     },
     deep: true,
   },
  },
  computed: {
    validateReferenceNumber() {
      if (!utilities.validateNameText(this.booking.caseReference)) {
        return false
      }
      return true
    },
    validateInterpreter() {
      if(this.booking.interpreterRequired && !this.booking.OrganizeRequired && !this.booking.interpreterDetails) {
        return false
      }
      return true
    },
    validateCaseOwner() {
      if(this.booking.caseOwner === null || this.booking.caseOwner === undefined || this.booking.caseOwner === "") {
        return false
      }
      return true
    },
    validateBookingType() {
      if (this.booking.bookingTypeId == '') {
        return false;
      }
      return true;
    },
    validateBillEmail() {
      if (this.showBillToEmail) {
        if(this.booking.billTo != "" && !utilities.validateEmail(this.booking.billTo)) {
          return false
        }
      }
      return true
    },

    validateSendEmail() {
      if (this.showSendReportToOther) {
        if(this.booking.reportTo != "" && !utilities.validateEmail(this.booking.reportTo)) {
          return false
        }
      }
      return true
    },
    filteredBodyAreaItems() {
      return this.bodyAreas.filter((i) => {
        return i.text.toLowerCase().indexOf(this.bodyAreaTag.toLowerCase()) !== -1
      })
    },

    filteredPersonConditionsItems() {
      return this.personConditions.filter((i) => {
        return i.text.toLowerCase().indexOf(this.personConditionTag.toLowerCase()) !== -1
      })
    },

    validateDateOfBirth() {
      if (this.booking.dateOfBirth == '') {
        return false
      }
      return true
    },

    validateAttendance() {
      if (this.booking.bookingTypeId == 8 || this.booking.bookingTypeId == 6) {
        return true
      } else if (this.disableCaseField) {
        return true
      } else if (this.booking.claimantConfirmed && this.booking.claimantEmail == '' && this.booking.claimantPhone1 == '' && this.booking.claimantPhone2 == '' && this.booking.claimantMobile == '') {
        return false
      }
      return true
    },

    validateTypeOfClaim() {
      if (this.booking.caseId == '' && this.booking.claimTypeId == undefined) {
        return false
      }
      return true
    },

    validateClaimantName() {
      if (this.booking.claimantFirstName == '' || this.booking.claimantSurname == '') {
        return false
      }
      return true
    },

    validateReschedule() {
      if (this.booking.id != '' && this.reason == '') {
        return false
      }
      return true
    },

    validateState() {
      if ((this.booking.bookingTypeId == 4 || this.booking.bookingTypeId == 7) && this.booking.claimantStateDuringBooking == '') {
        return false
      }
      return true
    }
  },

  methods: {
    changeFilter(v) {
      if(v) {
        this.booking.interpreterDetails = ''
      }
    },
    onChangeBookingType(e) {
      this.booking.bookingTypeName = this.allBookingTypes.find(item => item.id == this.booking.bookingTypeId).name
    },
    closeDialog() {
      utilities.hideAlert(".tentative-alert")
    },
    getInitials(specialist) {
      var initials = ""
      if (specialist.firstName) {
        initials = specialist.firstName.substring(0,1)
      }

      if (specialist.lastName) {
        initials = initials + specialist.lastName.substring(0,1)
      }
      return initials
    },

    defineClearButton() {
      $(".report-to-selecter input").after("<div class='dropdown-input-clear'></div>")
      $(".report-to-selecter .dropdown-input-clear").click((e) => {
        this.$refs.dropdownEmailSendTo.searchFilter = ""
        this.booking.reportTo = ""
        this.showSendReportToOther = false
      })

      $(".bill-to-selecter input").after("<div class='dropdown-input-clear'></div>")
      $(".bill-to-selecter .dropdown-input-clear").click((e) => {
        this.$refs.dropdownEmailBillTo.searchFilter = ""
        this.booking.billTo = ""
        this.showBillToEmail = false
      })
    },

    selectedContact(item) {
      this.$set(this.booking, 'caseOwner', item.id)
    },

    filterReportTo(text) {
      this.listClients = this.originalClientContacts.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1);
    },

    selectAccientDate() {
      this.booking.overPeriodofTime = false
    },

    changeAccientDate() {
      setTimeout(() => {
        this.isValidAccidentDate = this.validateAccidentDate()
      }, 200)
    },

    selectedBillTo(item) {
      if (item.id == -1) {
        this.booking.billTo = ""
        this.showBillToEmail = true
      } else {
        this.booking.billTo = item.value
        this.showBillToEmail = false
      }
      let billTo = this.originalClientContacts.find((x) => x.value == this.booking.billTo)
      if (billTo) {
        this.$refs.dropdownEmailBillTo.selected = billTo
        this.$refs.dropdownEmailBillTo.searchFilter = billTo.name
      }
    },

    selectedSendTo(item) {
      if (item.id == -1) {
        this.booking.reportTo = ""
        this.showSendReportToOther = true
      } else {
        this.showSendReportToOther = false
        this.booking.reportTo = item.value
      }
    },
    updateConditions(newTags) {
      if(this.$refs.conditions.newTag.length == 0) newTags.pop()
      this.personConditionTags = newTags
    },

    changeDate() {
      this.booking.overPeriodofTime = false
    },

    editNote(index) {
      let note = this.booking.bookingNotes[index]
      note = JSON.parse(JSON.stringify(note))
      note.note = note.Note
      this.editingIndex = index
      if ((note.userId == this.user.id) || !note.userId) {
        this.$refs.addNoteModal.internalOnly = 1
        this.$refs.addNoteModal.editCurrentNote(note)
        this.$refs.addNoteModal.handleModal()
      }
    },

    getClaimantTypeName(id) {
      var type = ''
      if (id != undefined || id != '' && this.claimTypes.length == 0) {
        return
      }
      if (id) {
        type = this.claimTypes.find((x) => x.id == id).name
      }
      return type
    },

    changedClaimantType(event) {
      var item = this.claimTypes.find((x) => x.id == event.target.value)
      this.booking.claimTypeName = item.name
    },

    setFirstAvailableDateTime() {
      var selectedDate = this.specialistTimes[0]
      selectedDate.date = moment(selectedDate.date, "YYYY-MM-DD").format("DD/MM/YYYY")
      //var slots = selectedDate.slots
      var slots = []
      if (this.isSameLocation) {
        this.booking.locationId = selectedDate.locationId
        this.specialistTimes.map((x) =>{
          if (x.fillOrder == "FtL") {
            slots.push(x.slots[0])
          } else if (x.fillOrder == "LtF") {
            slots.push(x.slots[x.slots.length - 1])
          } else {
            x.slots.map((i) => {
              slots.push(i)
            })
          }
        })
      } else {
        if (this.specialistTimes.length > 1) {
          this.specialistTimes.map((x) =>{
            if (x.fillOrder == "FtL") {
              slots.push(x.slots[0])
            } else if (x.fillOrder == "LtF") {
              slots.push(x.slots[x.slots.length - 1])
            } else {
              x.slots.map((i) => {
                slots.push(i)
              })
            }
          })
        } else {
          slots = selectedDate.slots
        }
      }
      for (var i = 0; i < slots.length; i++) {
        var x = slots[i]
        if (x.status == "A") {
          x.time = moment(x.time, 'HH:mm').format('hh:mm A')
          this.availableTimes.push(x)
        }
      }
      if (this.fillOrder == '' && this.availableTimes.length > 0) {
        this.booking.bookingDate = selectedDate.date
        this.booking.bookingTime = this.availableTimes[0].time
        var bookingDateTime = selectedDate.date + " " + this.booking.bookingTime
        this.booking.bookingDateTime = moment(bookingDateTime, 'DD/MM/YYYY hh:mm A').format("YYYY-MM-DD HH:mm:ss")
      } else {
        this.booking.bookingTime = moment(this.booking.bookingTime, 'hh:mm:ss').format("hh:mm A")
      }
      // this.booking.bookingEndTime = endTime
      this.datekey +=1
      this.timeKey +=1
    },

    changedBookingStatus(e) {
      if (e == 0) {
        this.selected = 0
        this.booking.bookingStatus = 3
        this.booking.bookingStatusName = "Booked"
      } else if (e == 1) {
        this.selected = 1
        this.booking.bookingStatus = 2
        this.booking.bookingStatusName = "Tentative"
      } else {
        this.booking.bookingStatus = 1
        this.booking.bookingStatusName = "Draft"
      }
    },

    onChangeBookingDate(e) {
      this.availableTime = []
      this.booking.bookingTime = ""
      var times = this.specialistTimes.find((x) => x.date == event.target.value)
      if (times) {
        times.slots.map((x) => {
          if (x.status == "A") {
            this.availableTime.push(x)
          }
        })
      }
    },

    onChangeTime(e) {
      var bookingDateTime = this.booking.bookingDate + " " + event.target.value
      //var endTime = moment(bookingDateTime, 'DD/MM/YYYY HH:mm').add(59, 'minutes').format('HH:mm')
      this.booking.bookingDateTime = moment(bookingDateTime, 'DD/MM/YYYY hh:mm A').format("YYYY-MM-DD HH:mm:ss")
      //this.booking.bookingEndTime = endTime
      this.datekey +=1
      this.timeKey +=1
      this.calculateBookingEndTime()
    },

    downloadToCalender() {
      this.$ics.addEvent('en-us', this.booking.claimantFullName + ' appointment with ' + this.specialist.fullName, '', this.getLocationName(), moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm'), this.booking.bookingEndTime)
      this.$ics.download('Appointment-Details-' + moment().unix())
    },

    backToHome() {
      this.$router.push({path:'/'}).catch((err) => {})
    },


    openDigram() {
      this.openBodyDiagram()
    },

    setDateInfo() {
      var text = ""
      if (this.booking.bookingTypeId == 7) {
        text = `<b>At:</b> ` + this.formatDate(this.booking.bookingDateTime,'hh:mm A dddd DD/MM/YYYY', "YYYY-MM-DD HH:mm:ss")
      } else if(this.isWaitlisted == 1)  {
        text = `<b>Date & Time:</b> ` + this.formatDate(this.booking.bookingDateTime,'dddd DD/MM/YYYY', "YYYY-MM-DD HH:mm:ss") + ' (WAITLIST) ' + this.getLocationName()
      } else {
        text = `<b>At:</b> ` + this.formatDate(this.booking.bookingDateTime,'hh:mm A dddd DD/MM/YYYY', "YYYY-MM-DD HH:mm:ss") + ' ' + this.getLocationName()
      }
      return text
    },

    formatDate(date, format = "YYYY-MM-DD", currentFormat = "YYYY-MM-DD") {
      if (date != '') {
        return utilities.formatDate(date, format, currentFormat)
      }
      return ''
    },

    getSpecialistInfo(locationId, locations) {
      var text = ""
      if (this.specialist.qualificationList) {
        text = this.specialist.qualificationList
        return text
      }

      // if (locationId) {
      //   var value = locations.find((x) => x.id == locationId).shortLocation
      //   if (value) {
      //     return text + ", " + value
      //   }
      // }
      return ""
    },

    getLocationName(fullLocation = false) {
      if (this.booking.locationId && this.locations.length > 0) {
        if (fullLocation) {
          return this.locations.find((x) => x.id == this.booking.locationId).fullLocation.replace(', Australia', "")
        } else {
          return this.locations.find((x) => x.id == this.booking.locationId).shortLocation
        }

      }
      return ""
    },
    getLocationNameOld(fullLocation = false) {
      if (this.originalBooking.locationId && this.locations.length > 0) {
        if (fullLocation) {
          return this.locations.find((x) => x.id == this.originalBooking.locationId).fullLocation.replace(', Australia', "")
        } else {
          return this.locations.find((x) => x.id == this.originalBooking.locationId).shortLocation
        }

      }
      return ""
    },


    getLocationState(id) {
      if (id) {
        return this.locations.find((x) => x.id == id).state
      }
    },

    setTitle() {
      if (this.displayBookingDetails) {
        if (this.booking.id) {
          return "New Booking Details"
        } else if (this.isWaitlisted && this.booking.caseId == '') {
          return "Create a Waitlist Booking"
        } else if (this.isWaitlisted && this.booking.caseId) {
          return "Create a Waitlist Booking - case Ref " + (this.singleCase.caseReference != undefined ? this.singleCase.caseReference : "")
        } else {
          if (this.booking.caseId) {
            return "Create a Booking - case Ref " + (this.singleCase.caseReference != undefined ? this.singleCase.caseReference : "")
          } else {
            return "Create a Booking - case Details"
          }

        }
      } else {
        if (this.booking.id) {
          return "New Booking Details - case Ref " + this.singleCase.caseReference
        } else if (!this.isWaitlisted && this.booking.caseId) {
          return "Booking Summary - case Ref " + this.singleCase.caseReference
        } else if (this.isWaitlisted && this.booking.caseId) {
          return "Waitlist Booking Summary - case Ref " + this.singleCase.caseReference
        } else if (this.isWaitlisted) {
          return "Waitlist Booking Summary"
        } else {
          return "Booking Summary"
        }
      }
    },

    setSubTitle() {
      var subtitle = ""
      if (this.booking.caseId) {
        subtitle = "Enter booking details for the existing case."
      } else {
        subtitle = "Enter the details for the new case."
      }
      return subtitle
    },

    onChartToggleChange(index) {
      switch (index) {
        case 1:
          this.booking.willAcceptEarlier = !this.booking.willAcceptEarlier
          break;
        case 2:
          this.booking.interpreterRequired = !this.booking.interpreterRequired
          this.booking.OrganizeRequired = false
          break;
        case 3:
          this.booking.redHealthMayContactClaimant = !this.booking.redHealthMayContactClaimant
          if (!this.booking.redHealthMayContactClaimant) {
            this.hideWarning = false
          } else {
            this.hideWarning = true
          }
          break;
        case 4:
        this.booking.overPeriodofTime = !this.booking.overPeriodofTime
          if (this.booking.overPeriodofTime) {
            this.booking.accidentDate = ""
          }
          break;
        case 5:
          this.booking.private = !this.booking.private
          break;
        case 6:
          this.booking.otfceRequired = !this.booking.otfceRequired
          break;
        case 7:
          this.booking.callClientAfterAppointment = !this.booking.callClientAfterAppointment
          break;
        case 8:
          this.booking.redHealthMayContactClaimant = !this.booking.redHealthMayContactClaimant
          break;
        case 9:
          this.booking.feeDeferralRequired = !this.booking.feeDeferralRequired
          if (!this.booking.feeDeferralRequired && !this.client.paymentDeferralOption && this.client.this.client.clientAlignment == "Plaintiff") {
            this.displayBanner = true
            this.bannerKey += 1
          } else {
            this.displayBanner = false
          }
          break;
        default:
      }
    },

    changeVideoLinkAvailable() {
      this.booking.videoLinkTestStatus = !this.booking.videoLinkTestStatus
    },

    getBookingTypeById(typeId) {
      if (typeId) {
        var type = this.allBookingTypes.find((x) => x.id == typeId)
        if (type) {
          return type.name
        }

      }
      return ""
    },

    deleteFile(index) {
      this.fileParams.splice(index, 1)
    },
    changeFileType(e, index) {
      this.$set(this.fileParams[index], 'FileTypeID', e)
      this.$set(this.booking.bookingFiles[index], 'FileTypeID', e)
    },

    async tapButtonConfirmation(states) {
      utilities.hideAlert('.confirm')
      if (states) {
        this.singleCase = this.searchCases[0]
        this.booking.caseId = this.singleCase.id
        this.callGetCaseAPI()
      } else {
        this.showBookingCinfirmation()
      }
    },

    openBodyDiagram() {
      this.$refs.addBodyDiagramModal.handleModal()
      this.$refs.addBodyDiagramModal.filteredBodyAreas = this.bodyAreas
      this.$refs.addBodyDiagramModal.bodyAreas = _.cloneDeep(this.bodyAreaTags)
      this.$refs.addBodyDiagramModal.handleClick()
    },

    setBodyArea(addedBodyAreas) {
      this.bodyAreaTags = []
      this.bodyAreaTags = addedBodyAreas
    },

    deleteBodyAreaItems(items) {
      items.map((x) => {
        this.deletedBodyTags.push(x.tag)
      })
    },

    async tapNext() {
      if (!this.validateDateOfBirth || !this.validateReferenceNumber || !this.validateAttendance || !this.validateClaimantName || !this.validateTypeOfClaim || !this.validateBillEmail || !this.validateSendEmail || !this.validateState || !this.validateBookingType || !this.validateInterpreter) {
        this.hasError = true
        this.errorMessage = "Please correct the errors shown above and try again."
        utilities.showAlert(".general-alert-danger")
        return
      }
      this.showBookingCinfirmation()
      this.summaryKey +=1

      this.hasError = false
      this.checkRescheduleStatus()
      setTimeout(() => {
        utilities.initTooltip()
      },300)
    },

    checkRescheduleStatus() {
      this.showMsg1 = false
      this.showMsg2 = false
      if (this.booking.id) {
        var timeLeft = moment().diff(moment(this.originalBookingDateTime, "YYYY-MM-DD HH:mm:ss"), 'hours');
        if (timeLeft < 48) {
          var showMeg = false
          this.bookingLog.map(x => {
            if (x.logDetails.includes("(7)")) {
                showMeg = true
            }
          })

          if (this.booking.timesRescheduled != '' && this.booking.timesRescheduled > 0) {
              showMeg = true
          }

          if (this.client.redRescheduleEnabled && !showMeg) {
            this.showMsg1 = true
          } else if (!this.client.redRescheduleEnabled || showMeg) {
            this.showMsg2 = true
          }
        }
      }
    },

    showBookingCinfirmation() {
      //If type is File Review
      this.showBookingStatus = true
      this.hideOtherStatus = false
      if (this.booking.bookingTypeId == 6) {
        this.showBookingStatus = false
        this.hideOtherStatus = true
      }

      //If type is phone call
      if (this.booking.bookingTypeId == 7) {
        this.showBookingStatus = true
        this.hideOtherStatus = true
      }

      this.booking.bookingStatus = 3
      this.booking.bookingStatusName = "Booked"
      this.displayBookingDetails = false

      this.displayBookingSummery = true

      if (this.isWaitlisted) {
        this.showBookingStatus = false
        this.hideOtherStatus = true
      }
    },

    calculateBookingEndTime() {
      if (this.booking.bookingTypeId == 6 || this.booking.bookingTypeId == 8 || this.isWaitlisted) {
        return ''
      }

      let endTime = window.moment(this.bookingProps.bookingEndTime, 'HH:mm:ss').format('HH:mm:ss')
      this.booking.bookingEndTime = endTime
      return this.booking.bookingEndTime
    },

    async getDuplicateCase() {
      this.hasToChooseCase = false
      if (!this.singleCase.id) {
        const param = {
          ClientID: this.booking.clientId,
          CaseReference: this.booking.caseReference,
          ClaimantFirstName: this.booking.claimantFirstName,
          ClaimantSurName: this.booking.claimantSurname,
          PageNum: 1,
          PageSize: 50,
          CaseStatus: 1
        }
        this.$store.dispatch("loading/setLoadComponent", true)
        const {clientCase, total} = await utilities.getCasesNew(param)
        const duplicateCase = clientCase.find(item => (item.claimantFullName === this.booking.claimantFullName && item.caseReference === this.booking.caseReference && window.moment(item.dateOfBirth).isSame(window.moment(this.booking.dateOfBirth))))
        if(duplicateCase) this.hasToChooseCase = true
        if (total > 0) {
          this.result = clientCase
          this.dialogVisible = true
        }
        this.$store.dispatch("loading/setLoadComponent", false)
      }
    },
    selectCase(item) {
      if(item) {
        this.singleCase = item
      }
      this.dialogVisible = false
      if (this.booking.id != '') {
        this.disableCaseField = true
      } else if (this.booking.caseId) {
        this.disableCaseField = true
      }
      this.tapSubmit(true)
    },
    closeModal() {
      this.result = []
      this.dialogVisible = false
      if(this.displayBookingSummery && this.booking.id == '') this.tapEdit()
    },

    getLocationNameById(id) {
      if (id) {
        return this.locations.find((x) => x.id == id).fullLocation.replace(', Australia', "")
      }
    },

    async tapSubmit(canCreate) {
      if (!this.validateReschedule) {
        this.hasError = true
        return
      }

      if(!canCreate) {
        await this.getDuplicateCase()
        if(this.dialogVisible) return
      }

      this.$store.dispatch("loading/setLoadComponent", true)
      const available = await utilities.getSpecialistAvailabilities(this.booking.specialistId, '', {AvailabilityID: this.booking.specialistAvailabilityId})
      if(available[0] && available[0].Availabilities) {
        const single = available[0].Availabilities.find(item => this.booking.specialistAvailabilityId === item.AvailabilityID)
        if(!single || single.AvailabilityStatus !== '2' || single.BookingStatus !== 'A') {
          if(!this.bookingProps.tentativeBookingID || (this.bookingProps.tentativeBookingID && single.BookingStatus !== 'H')) {
            this.errorMessage = "Booking Time not available now, please go back to select another availability"
            utilities.showAlert(".alert-danger")
            this.$store.dispatch("loading/setLoadComponent", false)
            return
          }
        } else if (single.Location.LocationID !== this.booking.locationId) {
          this.$msgbox({
            message: `The address for this availability has been updated to ${this.getLocationNameById(single.Location.LocationID)}. Please click “Confirm” to book the appointment with this new address, or "Cancel" to pick a different availability`,
            title: 'Warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
          }).then(() => {
            this.booking.locationId = single.Location.LocationID
            this.tapSubmit(true)
          }).catch(() => {
            this.$router.replace('/search-specialist')
          });
          this.$store.dispatch("loading/setLoadComponent", false)
          return
        }
      }
      var bodyAreaObjects = []
      var bodyArea = {}
      if (this.bodyAreaTags.length > 0) {
        this.bodyAreaTags.map((x) => {
          var temp = this.addedBodyArea.filter((y) => y.bodyArea == x.text)[0]
          if (temp == undefined) {
              bodyArea = {
                BodyAreaID: x.value
              }
              bodyAreaObjects.push(bodyArea)
          }
        })
      }

      var conditionObject = []
      var condition = {}
      if (this.personConditionTags.length > 0) {
        this.personConditionTags.map((x) => {
          var temp = this.addedConditions.filter((y) => y.name == x.text)
          if (temp == undefined || temp.length == 0) {
              condition = {
                PersonConditionID: x.value
              }
              conditionObject.push(condition)
          }
        })
      }

      if (this.isWaitlisted) {
        this.booking.bookingStatus = 16
      } else {
        if (this.booking.bookingStatus == '' || this.booking.bookingStatus == 0) {
          this.booking.bookingStatus = 1
        }
      }

      var speZohoId = ''
      if(this.specialist.zohoLink != '') {
        speZohoId = this.specialist.zohoLink.split("/");
        speZohoId = speZohoId[speZohoId.length - 1];
      }

      var zohoId = ''
      if(this.booking.zohoLink != undefined && this.booking.zohoLink != '') {
        zohoId = this.booking.zohoLink.split("/");
        zohoId = zohoId[zohoId.length - 1];
      }

      var bookingEndTime = this.calculateBookingEndTime()
      this.booking.bookingEndTime = bookingEndTime

      // var clientZohoLink = ''
      // if(this.client.zohoLink != '') {
      //   var zohoId = this.client.zohoLink.split("/")
      //   zohoId = zohoId[zohoId.length - 1]
      //   clientZohoLink = zohoId
      // }

      var params = {
        CaseID: this.singleCase.id,
        ZohoCaseID: this.singleCase.zohoCaseId,
        CaseReference: this.booking.caseReference,
        SpecialistID: this.booking.specialistId,
        SpecialistZohoLink: speZohoId,
        ClientID: this.user.clientId,
        CurrentDateTime: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
        ZohoDateCreated: moment().format(),
        BookingStatus: this.booking.bookingStatus,
        BookingStatusName: this.booking.bookingStatusName,
        BookingTypeID: this.booking.bookingTypeId,
        BookingTypeName: this.booking.bookingTypeName,
        onBehalfOf: this.booking.onBehalfOf,
        Private: this.booking.private,
        OTFCERequired: this.booking.otfceRequired,
        WillAcceptEarlier: this.booking.willAcceptEarlier,
        // ReportToEmailAddress: this.booking.reportToEmailAddress, // directly pass other email address into the above field
        ClaimTypeID: this.booking.claimTypeId,
        ClaimTypeName: this.booking.claimTypeName,
        DateFrom: this.booking.dateFrom,
        DateTo: this.booking.dateTo,
        BookingDateTime: this.booking.bookingDateTime,
        ZohoBookingDateTime: moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss").format(),
        BookingEndTime : bookingEndTime,
        ClaimantPhone1: utilities.validatePhonenumber(this.booking.claimantPhone1),
        ClaimantPhone2: utilities.validatePhonenumber(this.booking.claimantPhone2),
        ClaimantMobile: utilities.updateMobileCode(utilities.validatePhonenumber(this.booking.claimantMobile)),
        ClaimantEmail:this.booking.claimantEmail,
        ClaimantAddressLine1: this.booking.claimantAddressLine1,
        ClaimantAddressLine2: this.booking.claimantAddressLine2,
        ClaimantCity: this.booking.claimantCity,
        ClaimantState: this.booking.claimantState,
        PreferredGivenName: this.booking.PreferredGivenName,
        Salutation: this.booking.Salutation,
        ClaimantPostcode: this.booking.claimantPostcode,
        ClaimantCountry: 'AU',
        LocationID: this.booking.locationId,
        LocationName: this.getLocationName(true),
        StateName: this.getLocationState(this.booking.locationId),
        VideoLinkTestStatus: utilities.convertBoolToNum(this.booking.videoLinkTestStatus),
        ClaimantFirstName: this.booking.claimantFirstName,
        ClaimantGivenNames: this.booking.claimantGivenNames,
        ClaimantSurname: this.booking.claimantSurname,
        DateofBirth: moment(this.booking.dateOfBirth).format('YYYY-MM-DD'),
        FeeDeferralRequired: utilities.convertBoolToNum(this.booking.feeDeferralRequired),
        AvailabilityID: this.specialistAvailabilityId,
        OverPeriodofTime: utilities.convertNumToBool(this.booking.overPeriodofTime),
        AccidentDate: this.booking.accidentDate ? moment(this.booking.accidentDate, "YYYY-MM-DD").format('YYYY-MM-DD') : "",
        BodyAreaObject: bodyAreaObjects,
        PersonConditionObject: conditionObject,
        ZohoLink: zohoId,
        BookingFileObject: [],
        UserID: this.user.id,
        Username: this.user.name,
        CaseOwner: this.user.id,
        VideoLink: this.booking.bookingTypeId == 4 ? 1 : 0,
        Specialty: this.specialist.qualificationList
      }

      if(this.bookingProps.tentativeBookingID) {
        params.TentativePreBookingID = this.bookingProps.tentativeBookingID
      }

      if(this.bookingProps.WaitListPreBookingID) {
        params.WaitListPreBookingID = this.bookingProps.tentativeBookingID
      }

      if (this.bookingProps.isNewCase && (this.booking.caseOwner != undefined || this.booking.caseOwner != '')) {
        params.CaseOwner = this.booking.caseOwner
      }

      if (this.booking.bookingTypeId == 6 || this.booking.bookingTypeId == 8) {
        params.ZohoBookingDateTime = moment().format()
      }

      if (this.booking.commencementDate != undefined && this.booking.commencementDate != '') {
        params.CommencementDate =  moment(this.booking.commencementDate, "YYYY-MM-DD").format('YYYY-MM-DD')
      }

      if (this.booking.id == '') {
        params.DateCreated = utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss'))
        params.CreatedBy = this.user.id
      }

      if (this.singleCase.id) {
        params.ClientZohoLink = this.client.zohoLink
      } else {
        if(this.client.zohoLink != '') {
          var zohoId = this.client.zohoLink.split("/")
          zohoId = zohoId[zohoId.length - 1]
          params.ClientZohoLink = zohoId
        }
      }

      var otherBodyArea = ""
      this.otherBodyAreaTags.map((x) => {
        if (otherBodyArea == "") {
          otherBodyArea = x.text
        } else {
          otherBodyArea = otherBodyArea + "," + x.text
        }
      })
      params.BodyAreaOther = otherBodyArea

      if (this.videoLinkAvailable == 2) {
        params.BookingTypeID = 4
        params.BookingTypeName = 'Independent Medical Examination by Video'
        params.VideoLink = 1
      }

      if (this.booking.caseOwner) {
        var contact = this.clientContacts.find((x) => (x.userId === this.booking.caseOwner && x.active))
        const user = this.clientContacts.find(x => this.user.id === x.userId && x.active)
        if(!contact && !user) {
          this.errorMessage = "Can not find this case owner, please try again or contact the administrator"
          utilities.showAlert(".alert-danger")
          this.$store.dispatch("loading/setLoadComponent", false)
          return
        } else if (user && !contact) {
          params.CaseOwner = this.user.id
          let objs = {
            CaseID: params.CaseID,
            ClientID: this.user.clientId,
            CurrentClientContactID: user.clientContactId,
            UserID: this.user.id,
            Username: this.user.name,
          }
          await utilities.changeCaseOwnerByCaseID(objs)
        }
        params.ClientContactZohoLink = user.zohoClientContactId
        params.ClientContactZohoName = user.fullName
      }

      //pass clientContact details into the Zoho
      var contact = this.clientContacts.find((x) => x.userId === params.CaseOwner)
      if (contact != undefined) {
          params.CCFirstName = contact.firstName
          params.CCSurname = contact.surname
          params.CCPhone = contact.phoneNumber1
          params.CCEmail = contact.NotificationEmailAddress || contact.emailAddress
          params.CCZohoID = contact.zohoClientContactId
          params.BranchName = contact.branchName
          params.ClientCategory = this.client.clientCategory
          params.ClientState = this.client.state
      }

      // 4202 - Appointments by Video – Handling Location & Links
      var location = this.locations.find((x) => x.id == this.booking.locationId)
      var state = location ? location.state : ""
      params.ClaimantStateDuringBooking = (this.booking.bookingTypeId == 4 || this.booking.bookingTypeId == 7) ? this.booking.claimantStateDuringBooking : state
      params.ClaimantBookingDateTime = this.booking.claimantBookingDateTime

      if (this.booking.id) {
        this.$store.dispatch("loading/setLoadComponent", false)
        if(this.bookingProps.hasVideo && this.originalBooking.bookingTypeId === 2) {
          this.$msgbox({
            title: 'Warning',
            message: 'By rescheduling this booking to the selected appointment slot, the booking will be changed to a VideoLink booking. Click "Ok" to confirm the change, or "Cancel" to keep the current appointment.',
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
          }).then(() => {
            this.booking.bookingTypeId = 4
            this.booking.bookingTypeName = this.allBookingTypes.find(item => item.id === 4)?.name || ''
            params.BookingTypeID = this.booking.bookingTypeId
            params.BookingTypeName = this.booking.bookingTypeName
            this.getContinue(params)
          }).catch(() => {
            this.getContinue(params)
            return
          })
        } else if (!this.bookingProps.hasVideo && !this.bookingProps.hasUserVideo && this.originalBooking.bookingTypeId === 4) {
          this.$msgbox({
            title: 'Warning',
            message: `By rescheduling this booking to the selected appointment slot, the booking will be changed to an In Person booking at ${ decodeURIComponent(this.getLocationName())}. Click "Ok" to confirm the change, or "Cancel" to keep the current appointment.`,
            showCancelButton: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
          }).then(() => {
            this.booking.bookingTypeId = 2
            this.booking.bookingTypeName = this.allBookingTypes.find(item => item.id === 2)?.name || ''
            params.BookingTypeID = this.booking.bookingTypeId
            params.BookingTypeName = this.booking.bookingTypeName
            this.getContinue(params)
          }).catch(() => {
            this.tapEdit()
            return
          })
        } else if ((this.originalBooking.bookingTypeId === 4 || this.originalBooking.bookingTypeId === 2) && this.bookingProps.hasUserVideo) {
          this.currentParam = params
          this.confirmDialog = true
        } else {
          await this.getContinue(params)
        }
      } else {
        params.BillTo = this.booking.billTo
        params.ReportTo = this.booking.reportTo
        params.BookingNoteObject = this.booking.bookingNotes
        params.RedHealthMayContactClaimant = this.booking.redHealthMayContactClaimant
        params.CallClientAfterAppointment = this.booking.callClientAfterAppointment
        params.OrganizeRequired = this.booking.OrganizeRequired ? 1 : 0
        params.InterpreterRequired = utilities.convertBoolToNum(this.booking.interpreterRequired)
        params.InterpreterDetails = this.booking.interpreterDetails
        //
        var notificationDate = moment().add(2, 'w').format('YYYY-MM-DD')
        if (moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss").isBefore(notificationDate)) {
          params.TwoWeekDiff = true
        } else {
          params.TwoWeekDiff = false
        }
        await this.callBookingAPI(params)
      }
    },

    async confirmChange() {
      await this.getContinue(this.currentParam)
      this.confirmDialog = false
      this.currentParam = null
    },

    onChangeSelect(v) {
      if(v === 2) {
        this.currentParam.BookingTypeName = "Independent Medical Examination"
      } else {
        this.currentParam.BookingTypeName = "Independent Medical Examination by Video"
      }
    },

    async getContinue(params) {
      this.$store.dispatch("loading/setLoadComponent", true)
      var tempNote = []
      this.booking.bookingNotes.map((x) => {
        if (x.id == '') {
          tempNote.push(x)
        }
      })
      if (tempNote.length > 0) {
        params.BookingNoteObject = tempNote
      }

      if (this.fileParams.length > 0) {
        let item = []
        await Promise.all(this.fileParams.map(async (x) => {
          if(this.booking.id != '') {
            try {
              const result = await utilities.postPreSign({
                ft: x.MimeType ? x.MimeType : 'application/octet-stream',
                fn: x.FileName,
                id: this.booking.id,
                FileStatus: 0,
                FileTypeID: x.FileTypeID,
              })
              let postData = new FormData()
              for (let key in result.fields) {
                postData.append(key, result.fields[key]);
              }
              postData.append('file', x)
              await utilities.postImage(result.url, postData)
              item.push({
                BookingID: this.booking.id,
                DateUploaded: window.moment().format('YYYY-MM-DD HH:mm:ss'),
                FileName: x.FileName,
                FileStatus: 0,
                FileTypeID: x.FileTypeID,
                MimeType: x.MimeType ? x.MimeType : 'application/octet-stream',
                UploadedBy: this.user.id,
                UploadedByName: this.user.name
              })
            } catch (error) {
              utilities.showErrorMessage(error.response.data?.message || error.response.data || error)
            }
          }
        }))
        await utilities.postFileRecord({
          items: item
        })
      }

      params.BookingID = this.booking.id
      params.BookingStatus = this.booking.bookingStatus
      params.IsReschedule = true
      if (this.booking.bookingStatus == 8) {
        params.BookingStatusName = 'Booked'
      }
      // if (this.booking.claimantConfirmed != this.originalClaimantConfirmed) {
      //   params.RedHealthMayContactClaimant = this.booking.claimantConfirmed
      // }
      var callback = (response) => {
        this.disableButton = true
        // Clear changing object
        this.$store.dispatch("booking/setBookingObject", null)
        utilities.clearChangingObject()
        if (this.booking.id) {
          this.successMessage = "Your booking has been rescheduled"
          utilities.showAlert(".alert-waitlisted", false)
          if (this.showMsg1) {
            this.errorMessage = "Please note: if this new appointment date and time is cancelled or the claimant fails to attend a cancellation fee may apply to this booking."
            utilities.showAlert(".rescheduled-alert", false)
            this.$store.dispatch("loading/setLoadComponent", false)
          } else if (this.showMsg2) {
            this.errorMessage = "A rescheduling fee has been applied to this booking."
            utilities.showAlert(".rescheduled-alert", false)
            this.$store.dispatch("loading/setLoadComponent", false)
          } else {
            setTimeout(() => {
              this.$router.push({path: '/'}).catch((err) => {
              })
              this.$store.dispatch("loading/setLoadComponent", false)
            }, 2300)
          }
          window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight)
        } else {
          this.$store.dispatch("loading/setLoadComponent", false)
        }
      }
      await utilities.putBooking(params, callback)
      this.postBookingRescheduleLog()
      this.callRescheduleEmail()
    },

    handleClose() {
      utilities.hideAlert('.aler-green')
    },

    async callBookingAPI(objectParams) {
      if(objectParams.BookingStatus == 3) {
        objectParams.BookingStatusName = 'Booked'
      }
      var callback = (response) => {
        this.newBookingId = response
        this.disableButton = true
      }

      var result = await utilities.postBooking(objectParams, callback)
      if (result) {
        if(this.fileParams.length > 0) {
          let item = []
          await Promise.all(this.fileParams.map(async (x) => {
            if(this.newBookingId != '') {
                    try {
                const result = await utilities.postPreSign({
                  ft: x.MimeType ? x.MimeType : 'application/octet-stream',
                  fn: x.FileName,
                  id: this.newBookingId,
                  FileStatus: 0,
                  FileTypeID: x.FileTypeID,
                })
                let postData = new FormData()
                for (let key in result.fields) {
                  postData.append(key, result.fields[key]);
                }
                postData.append('file', x)
                const r = await utilities.postImage(result.url, postData)
                if(r) {
                  item.push({
                  BookingID: this.newBookingId,
                  DateUploaded: window.moment().format('YYYY-MM-DD HH:mm:ss'),
                  FileName: x.FileName,
                  FileStatus: 0,
                  FileTypeID: x.FileTypeID,
                  MimeType: x.MimeType ? x.MimeType : 'application/octet-stream',
                  UploadedBy: this.user.id,
                  UploadedByName: this.user.name
                })}
              } catch (error) {
                utilities.showErrorMessage(error.response.data?.message || error.response.data || error)
              }
            }
          }))
          if(item.length > 0) {await utilities.postFileRecord(item)}
        }

        // Clear changing object
        this.$store.dispatch("booking/setBookingObject", null)
        utilities.clearChangingObject()
        var dissmisAlert = false

        if (this.booking.interpreterRequired) {
          var parem = {
            BookingID: this.newBookingId,
            DateRequested: moment().format('YYYY-MM-DD HH:mm:ss'),
            RequestedBy: this.user.id,
            BookingActionRequestStatus: 1,
            RequestedAction: "Interpreter Required",
            UserID: this.user.id,
            Username: this.user.name,
          }
          var callback = (response) => {}
          utilities.postBookingAction(parem, callback)
        }

        if (this.isWaitlisted) {
          dissmisAlert = true
          this.showAddCalender = false
          this.successMessage = "Your booking has been waitlisted."
          utilities.showAlert(".alert-waitlisted")

        } else {
          this.showAddCalender = true
          this.successMessage = "Your booking (ID " + this.newBookingId + ") has been confirmed.  You will receive an email confirmation and we will be in touch shortly to help co-ordinate the " + this.booking.bookingTypeName +"."
          utilities.showAlert(".aler-green", false)

          if (this.relatedServices.length > 0) {
            setTimeout(() => {
              // utilities.showAlert(".aler-relatedServices", false)
              this.$refs.additionalBookingModal.relatedServices = this.relatedServices
              this.$refs.additionalBookingModal.handleModal()
            }, 100)
          } else {
            this.backToHome()
          }

          if (this.booking.bookingStatus == 2) {
            this.errorMessage = "This tentative appointment will be held for 24 hours. Please confirm the appointment before then, to ensure you keep this spot"
            utilities.showAlert(".tentative-alert", false)

          }
        }

        if (dissmisAlert) {
          setTimeout(() => {
            this.$router.push({path:'/'}).catch((err) => {})
            this.$store.dispatch("loading/setLoadComponent", false)
          }, 2300)
        } else {
          this.$store.dispatch("loading/setLoadComponent", false)
        }
      } else {
        this.$store.dispatch("loading/setLoadComponent", false)
      }
    },

    async postBookingRescheduleLog() {
      // var params = {
      //   UserID: this.user.id,
      //   Username: this.user.name,
      //   BookingID: this.booking.id,
      //   LogDetails: 'BookingID ' + this.booking.id + ' has been rescheduled. Reason: ' + this.reason,
      //   LogDate: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
      // }
      const noteObj = {
        Note: `Booking Rescheduled from ${this.originalSpecialistFullName} ${utilities.formatDate(this.originalBookingDateTime, "DD/MM/YYYY HH:mm", "YYYY-MM-DD HH:mm")} to ${this.specialist.fullName} ${moment(this.booking.bookingDateTime).format('DD/MM/YYYY HH:mm')}. Reason: ${this.reason}`,
        NoteDateTime: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
        UserID: this.user.id,
        Username: this.user.name,
        BookingID: this.booking.id,
        BookingDateTime: this.booking.bookingDateTime,
        SpecialistName: this.booking.specialistFullName,
        ClientName: this.booking.clientName + ' - ' + this.booking.branchName,
        LocationName: this.getLocationName(),
        CaseOwnerName: this.clientContacts.find((x) => x.userId == this.booking.caseOwner).fullName,
        BookingTypeName: this.booking.bookingTypeName,
        InternalOnly: 1,
        IsMainEdit: 0,
        IsAdmin: true
      }
      await utilities.postBookingNote(noteObj)
    },

    callRescheduleEmail() {
      var params = {
        BookingID: this.booking.id,
        BookingDateTime: this.booking.bookingDateTime,
      	OldBookingDateTime: this.originalBookingDateTime,
      	SpecialistID: this.booking.specialistId,
      	OldSpecialistID: this.originalBooking.specialistId,
        SpecialistName: this.specialist.fullName,
        OldSpecialistName: this.originalBooking.sFullName,
      	LocationName: this.getLocationName(true),
      	OldLocationName: this.getLocationNameOld(true),
      	BookingTypeName: this.booking.bookingTypeName,
        ClientName: this.booking.clientName,
        BranchName: this.booking.branchName,
        UserID: this.user.id,
      }
      utilities.postRescheduleEmail(params)
    },

    tapEdit() {
      var originalBooking = _.cloneDeep(this.booking)
      var originalClient = _.cloneDeep(this.client)

      this.displayBookingDetails = true
      this.displayBookingSummery = false
      this.openNotes()

      setTimeout(() => {
        this.booking.reportTo = originalBooking.reportTo
        this.booking.billTo = originalBooking.billTo
        this.booking.caseOwner = originalBooking.caseOwner
        this.client.id = originalClient.id

        // Reload Client Contact
        var selectedContact = this.originalClientContacts.find((x) => x.id == this.booking.caseOwner)
        if (selectedContact && this.$refs.dropdownClientContact) {
          this.$refs.dropdownClientContact.selected = selectedContact
          this.$refs.dropdownClientContact.searchFilter = selectedContact.name
        }

        // Reload Report To
        if(this.booking.reportTo != '') {
          var sendReportTo = this.clientContacts.find((x) => x.userId == this.user.id)
          if(sendReportTo) {
            this.booking.reportTo = sendReportTo.emailAddress
          }
        }

        // Reload Bill To
        if(this.booking.billTo != '') {
          var billTo = this.clientContacts.find((x) => x.userId == this.user.id)
          if(billTo) {
            this.booking.billTo = billTo.emailAddress
          }
        }

        this.defineClearButton()
      }, 300)
    },

    openNotes() {
      this.showAddNote = true
      $('.notes-content').show()
      $('.tab-selected').removeClass('tab-selected')
      $('.notes-heading').addClass('tab-selected')
      $('.files-content').hide()
    },

    openFiles() {
      this.showAddNote = false
      $('.notes-content').hide()
      $('.tab-selected').removeClass('tab-selected')
      $('.files-heading').addClass('tab-selected')
      $('.files-content').show()
    },

    addNoteToBooking(note) {
      if(this.editingIndex !== null) {
        let editNote = {
          Note: note,
          NoteDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          InternalOnly: 1
        }
        this.$set(this.booking.bookingNotes, this.editingIndex, editNote)
      } else {
        let newNote = {
          Note: note,
          NoteDateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          InternalOnly: 1
        }
        this.booking.bookingNotes.push(newNote)
      }
      this.openNotes()
    },

    openAddNoteModal(e) {
      this.editingIndex = null
      this.$refs.addNoteModal.addNoteToBooking()
      this.$refs.addNoteModal.handleModal()
    },

    openFilesBox() {
      $('#assetsFieldHandle').trigger('click')
    },

    openManageContactDetails() {
      if (!this.disableCaseField) {
        this.$refs.addClientContactModal.singleCase = this.singleCase
        this.$refs.addClientContactModal.booking = this.booking
        this.$refs.addClientContactModal.setBookingContactDetails()
        this.$refs.addClientContactModal.handleModal()
      }
    },

    openManageAddress() {
      if (!this.disableCaseField) {
        this.$refs.addClientAddressModal.booking = this.booking
        this.$refs.addClientAddressModal.isClient = false
        this.$refs.addClientAddressModal.setBookingAddressDetails()
        this.$refs.addClientAddressModal.handleModal()
      }
    },

    openAddNameModal() {
      if (!this.disableCaseField) {
        if (this.isEditName) {
          this.$refs.addNameModal.editFullName(this.booking.claimantFirstName, this.booking.claimantGivenNames, this.booking.claimantSurname)
        }
        this.$refs.addNameModal.handleModal();
      }
    },

    setName(object) {
      this.booking.claimantFirstName = object.firstName
      this.booking.claimantGivenNames = object.middleName
      this.booking.claimantSurname = object.surname
      this.booking.claimantFullName = utilities.getClaimantFullName(this.booking)
      if (this.booking.claimantFullName != "") {
        this.isEditName = true
      } else {
        this.isEditName = false
      }
      this.nameKey += 1
    },

    dragover(event) {
      event.preventDefault()
      event.currentTarget.classList.add('dark-border-hover')
    },

    dragleave(event) {
      event.currentTarget.classList.remove('dark-border-hover')
    },

    drop(event) {
      event.preventDefault()
      this.$refs.file.files = event.dataTransfer.files
      this.onChange()

      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
      event.currentTarget.classList.remove('dark-border-hover')
    },

    async onChange() {
      this.filelist = [...this.$refs.file.files]
      if(this.filelist.length > 0) {
        this.filelist.map((x) => {
            var params = {
              FileName: x.name,
              FileTypeID: this.fileTypes.find((x) => x.name == 'Letter of Instruction').id,
              UploadedBy: this.user.id,
              UploadedByName: this.user.name,
              MimeType: x.type,
              FileStatus: 0,
              DateUploaded: window.moment().format('YYYY-MM-DD HH:mm:ss'),
              file: x
            }
            this.fileParams.push(params)
            this.booking.bookingFiles.push(params)
        })
      }
    },

    remove(i) {
      this.filelist.splice(i, 1)
    },

    async deleteNote(id, index) {
      this.$confirm({
        content: 'Are you sure you want to delete this note?',
        onConfirm: async () => {
          var temp = _.cloneDeep(this.booking.bookingNotes)
          this.booking.bookingNotes = temp.splice(0, index).concat(temp.splice(index + 1, temp.length - 1))
        }
      })
    },

    async setCaseInfo() {
      if (this.singleCase.caseStatus == '') {
        this.singleCase.caseStatus = 1
      }
      if (this.singleCase.claimantPhone1 != '' || this.singleCase.claimantMobile != '' || this.singleCase.claimantPhone2 != '' || this.singleCase.claimantEmail != '') {
        this.isEditContact = true
      } else {
        this.isEditContact = false
      }

      if (this.singleCase.claimantAddressLine1 != '' || this.singleCase.claimantAddressLine2 != '' || this.singleCase.claimantCity != '') {
        this.isEditAddress = true
      } else {
        this.booking.claimantFullAddress = ""
        this.isEditAddress = false

      }

      if (this.singleCase.claimantFullName != "") {
        this.isEditName = true
      } else {
        this.isEditName = false
      }

      this.booking.caseOwner = this.singleCase.caseOwner
      this.booking.caseReference = this.singleCase.caseReference
      this.booking.claimantFullName = this.singleCase.claimantFullName
      this.booking.claimantFirstName = this.singleCase.claimantFirstName
      this.booking.claimantGivenNames = this.singleCase.claimantGivenNames
      this.booking.claimantSurname = this.singleCase.claimantSurname
      this.booking.dateOfBirth = this.singleCase.dateOfBirth
      this.booking.commencementDate = this.singleCase.commencementDate
      this.booking.claimTypeId = this.singleCase.claimTypeId
      this.booking.claimTypeName = this.singleCase.claimTypeName
      this.booking.onBehalfOf = this.singleCase.onBehalfOf
      this.booking.accidentDate = this.singleCase.accidentDate
      this.booking.overPeriodofTime = this.singleCase.overPeriodofTime
      this.booking.claimantFullAddress = this.singleCase.claimantFullAddress
      this.booking.claimantAddressLine1 = this.singleCase.claimantAddressLine1
      this.booking.claimantAddressLine2 = this.singleCase.claimantAddressLine2
      this.booking.claimantCity = this.singleCase.claimantCity
      this.booking.claimantState = this.singleCase.claimantState
      this.booking.claimantPostcode = this.singleCase.claimantPostcode
      this.booking.claimantCountry = this.singleCase.claimantCountry
      this.booking.claimantMobile = this.singleCase.claimantMobile
      this.booking.claimantPhone1 = this.singleCase.claimantPhone1
      this.booking.claimantPhone2 = this.singleCase.claimantPhone2
      this.booking.claimantEmail = this.singleCase.claimantEmail
      this.booking.BookingStatus = 1
      this.nameKey += 1
    },

    async callGetCaseAPI() {
      this.singleCase = await utilities.getCaseByCaseId(this.booking.caseId)
      if (this.singleCase == undefined) {
        this.showContent = false
        return
      }

      // if (this.singleCase.bodyAreas.length > 0) {
      //   this.addedBodyArea = _.cloneDeep(this.singleCase.bodyAreas)
      //   this.bodyAreaTags = utilities.getAutoCompleteBodyAreaTags(this.singleCase.bodyAreas)
      // }
      //
      // if (this.singleCase.personCondition.length > 0) {
      //   this.addedConditions = _.cloneDeep(this.singleCase.personCondition)
      //   this.personConditionTags = utilities.getAutoCompleteTags(this.singleCase.personCondition)
      // }
      //
      // if (this.singleCase.bodyAreaOther.length > 0) {
      //   var items = this.singleCase.bodyAreaOther.split(',');
      //   this.otherBodyAreaTags = utilities.getAutoCompleteOtherBodyAreaTags(items)
      // }
      // this.booking.bodyAreas = this.bodyAreaTags
      // this.booking.personConditions = this.personConditionTags
      // this.booking.otherBodyAreaTags = this.otherBodyAreaTags

      await this.setCaseInfo()
    },

    setBookingTime() {
      if (this.specialistTimes.length > 0 && !this.isWaitlisted) {
        this.setFirstAvailableDateTime()

      } else if (this.isWaitlisted) {
        var selectedDate = this.specialistTimes[0]
        selectedDate.date = moment(selectedDate.date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")
        var slots = selectedDate.slots
        for (var i = 0; i < slots.length; i++) {
          var x = slots[i]
          x.time = moment(x.time, 'HH:mm').format('hh:mm A')
          this.availableTimes.push(x)
        }

        //if (this.availableTimes.length > 0) {
          //var dateTime = this.booking.bookingDate + " " + this.availableTimes[0].time
          //this.booking.bookingTime = this.availableTimes[0].time
        //} else {
          //var dateTime = this.booking.bookingDate + " 09:00"
          //this.booking.bookingTime = '09:00'
        //}
        var dateTime = this.booking.bookingDate + this.booking.bookingTime
        this.booking.bookingDateTime = moment(dateTime, 'DD/MM/YYYY HH:mm').format("YYYY-MM-DD HH:mm:ss")
      }
    },

    async loadData(object) {
      if (object.videoLinkAvailable == 2) {
        var params = {}
        if (object.regionId.includes('r')) {
          params.regionId = object.regionId.replace('r', '')
        } else {
          params.cityId = object.regionId.replace('c', '')
        }
        this.videoLinkLocation = await utilities.getLocations("",params)
      } else {
        this.booking.locationId = object.locationId != undefined ? object.locationId : ''
      }

      this.states = utilities.getStates()
      this.booking.caseId = object.caseId != undefined ? object.caseId : ''
      this.isWaitlisted = object.waitlisted != undefined ? utilities.convertNumToBool(object.waitlisted) : false
      this.booking.id = object.bookingId != undefined ? object.bookingId : ''
      this.fillOrder = object.fillOrder != undefined ? object.fillOrder : ''
      this.specialistAvailabilityId = object.availabilityId != undefined ? object.availabilityId : 0
      this.requiresTravel = object.requiresTravel != undefined ? object.requiresTravel : false
      this.videoLinkAvailable = object.videoLinkAvailable != undefined ? object.videoLinkAvailable : 0
      this.qualificationId = object.qualificationId != undefined ? object.qualificationId : ''

      if (this.isWaitlisted) {
        this.showBookingStatus = false
        this.hideOtherStatus = false
      }

      if (this.booking.id) {
        this.booking = await utilities.getBookingById(this.booking.id)
        this.originalBooking = _.cloneDeep(this.booking)
        this.booking.bookingNotes = []
        this.originalBookingDateTime = _.cloneDeep(this.booking.bookingDateTime)
        this.originalClaimantConfirmed = _.cloneDeep(this.booking.claimantConfirmed)
        this.originalSpecialistFullName = _.cloneDeep(this.booking.specialistFullName)
        if (this.booking == undefined) {
          this.showContent = false
          return
        }
        this.originalSpecialistId = _.cloneDeep(this.booking.specialistId)
        this.bookingLog = await utilities.getBookingLogsById(this.booking.id)
        if (object.videoLinkAvailable == 2) {
          var params = {}
          if (object.regionId.includes('r')) {
            params.regionId = object.regionId.replace('r', '')
          } else {
            params.cityId = object.regionId.replace('c', '')
          }
          this.videoLinkLocation = await utilities.getLocations("",params)
        } else {
          this.booking.locationId = object.locationId != undefined ? object.locationId : ''
        }
      }

      this.booking.specialistId = object.id != undefined ? object.id : ''
      this.client = await utilities.getClientById(this.user.clientId)
      const apiArr = [utilities.getClaimTypes(), utilities.getLocations(), utilities.getBodyArea(), utilities.getPersonConditions(), utilities.getClientContactsNew({
        PageNum: 1,
        PageSize: 100,
        SortFlag: 0,
        ClientID: this.user.clientId,
      }), utilities.getFileTypes(), utilities.getBookingTypes()]

      const res = await Promise.all(apiArr)
      this.claimTypes = res[0]
      this.locations = res[1]

      this.booking.specialistAvailabilityId = this.specialistAvailabilityId
      var bodyAreas = res[2]
      this.bodyAreas = utilities.getAutoCompleteBodyAreaTags(bodyAreas)

      var personConditions = res[3]
      this.personConditions = utilities.getAutoCompleteTags(personConditions)

      this.booking.videoLinkTestStatus = false

      this.clientContacts = res[4].contacts
      var types = res[5]
      types.map((x) => {
        if (x.name == 'Brief' || x.name == 'Letter of Instruction' || x.name == 'Other') {
          this.fileTypes.push(x)
        }
      })
      this.allBookingTypes = res[6]
      this.originalClientContacts = _.cloneDeep(utilities.getClientsContactDropdownAutoCompleteTag(this.clientContacts))
      this.listContact = _.cloneDeep(this.originalClientContacts)

      if (this.booking.specialistId) {
        this.specialist = await utilities.getSpecialistById(this.booking.specialistId)
        if (this.specialist == undefined) {
          this.showContent = false
          return
        }
        let bookingTypeArr = []
        this.allBookingTypes.forEach(item => {
          const filter = this.bookingTypesLocal.find(type => type.name.toUpperCase() === item.name.toUpperCase() && type.isInCreateBooking && type.isClint)
          if(filter && this.specialist[filter.field]) {
            filter.id = item.id
            bookingTypeArr.push(filter)
          }
        })
        this.allBookingTypes = bookingTypeArr
        if(object.hasVideo) {
          this.allBookingTypes  = this.allBookingTypes.filter(item => item.name !== 'Independent Medical Examination')
          if(this.originalBooking.bookingTypeId === 2) {
            this.booking.bookingTypeId = 4
            this.booking.bookingTypeName = 'Independent Medical Examination by Video'
          }
        } else if (!object.hasUserVideo && !object.hasVideo) {
          this.allBookingTypes  = this.allBookingTypes.filter(item => item.name !== 'Independent Medical Examination by Video')
          this.booking.bookingTypeId = this.allBookingTypes[0].id
          this.booking.bookingTypeName = this.allBookingTypes[0].name
          if(this.originalBooking.bookingTypeId === 4) {
            this.booking.bookingTypeId = 2
            this.booking.bookingTypeName = 'Independent Medical Examination'
          }
        }
        // Load profile photo
        if(this.specialist.photo != '') {
          this.specialist.profileThumbnail = await utilities.getProfileThumbnail(this.specialist.photo)
        }

        // var startDate = object.startDate != undefined ? object.startDate : ''
        // var endDate = object.endDate != undefined ? object.endDate : ''

        this.booking.bookingDate = moment(object.bookingDate, "YYYY-MM-DD").format("DD/MM/YYYY")
        this.booking.bookingTime = moment(object.bookingTime, "hh:mm A").format('HH:mm:ss')

        var bookingDateTime = object.bookingDate + ' ' + object.bookingTime
        this.booking.bookingDateTime = moment(bookingDateTime, 'YYYY-MM-DD hh:mm A').format("YYYY-MM-DD HH:mm:ss")
        this.datekey +=1
        this.timeKey +=1
      }

      if (this.booking.caseId) {
        this.callGetCaseAPI()
        if (this.booking.id != '') {
          this.disableCaseField = true
        } else if (this.booking.caseId) {
          this.disableCaseField = true
        }
      }
      if (object.isNewCase) {
        this.showClientContact = true
        // this.originalClientContacts = _.cloneDeep(utilities.getClientsContactDropdownAutoCompleteTag(this.clientContacts))
        // this.listContact = _.cloneDeep(this.originalClientContacts)

        var selectedContact = this.listContact.find((x) => x.id == this.user.id)
        if (selectedContact != undefined) {
          setTimeout(() =>{
            $('.contact-selecter  input').val(selectedContact.name)
            this.$refs.dropdownClientContact.selected = selectedContact
            this.$refs.dropdownClientContact.searchFilter = selectedContact.name
            this.booking.caseOwner = this.user.id
          },500)
        }
      }

      //set Search case details
      if (object.isNewCase) {
        if (object.claimantFirstName || object.caseReference) {
          this.booking.claimantFirstName = object.claimantFirstName
          this.booking.claimantSurname = object.claimantLastName
          this.booking.claimantFullName = utilities.getClaimantFullName(this.booking)
          if (this.booking.claimantFullName != "") {
            this.isEditName = true
          } else {
            this.isEditName = false
          }
          this.nameKey += 1
        }

        if (object.dateOfBirth) {
          this.booking.dateOfBirth = object.dateOfBirth
        }

        if (object.caseReference) {
          this.booking.caseReference = object.caseReference
        }
      }

      //set send report to data
      if (this.booking.id == '') {
        var sendTo = this.clientContacts.find((x) => x.userId == this.user.id)
        var user = {
          id: sendTo.clientContactId,
          value: sendTo.emailAddress,
          name: sendTo.fullName + '-' + sendTo.emailAddress
        }

        this.booking.billTo = user.value
        this.booking.reportTo = user.value
      }

      if (this.qualificationId && this.qualificationId !== '') {
        var param = {
          qualificationId: this.qualificationId,
          bookingTypeID: this.booking.bookingTypeId
        }
        this.relatedServices = await utilities.relationshipSpecialistQualifications(param)
      }

      this.$store.dispatch("setOriginalObject", this.booking)
      this.$store.dispatch("setNewObject", this.booking)
    },

    saveToLocalStore() {
      localStorage.setItem('storedCase', JSON.stringify(this.singleCase))
      localStorage.setItem('storedClient', JSON.stringify(this.client))
      localStorage.setItem('storedSpecialist', JSON.stringify(this.specialist))
      localStorage.setItem('storedUser', JSON.stringify(this.user))
      localStorage.setItem('storedAddedBodyArea', JSON.stringify(this.addedBodyArea))
      localStorage.setItem('storedAddedConditions', JSON.stringify(this.addedConditions))
    },
  },

  async beforeMount() {
    this.$store.dispatch("loading/setLoadComponent", true)
    var object = this.$store.getters["booking/getBookingObject"]
    if(object != undefined) {
      this.bookingProps = object
      this.isFromSpecialist = object.isFromSpecialist
      await this.loadData(this.bookingProps)
      this.calculateBookingEndTime()
    } else {
      this.showContent = false
    }
    //if (object.isNewCase) {
    this.hideInfo = true
    // }
    if (this.client != undefined && this.client.redHealthMayContactClaimants) {
      this.hideAttendance = false
      this.booking.redHealthMayContactClaimant = true
      this.hideWarning = true
    } else {
      this.hideAttendance = true
      this.hideWarning = false
    }

    if (this.booking.bookingTypeId == 8) {
      this.hideAttendance = true
      this.booking.claimantConfirmed = false
    }

    if (!this.client.paymentDeferralOption && this.client.clientAlignment == "Plaintiff") {
      this.displayBanner = true
    } else {
      this.displayBanner = false
      this.booking.feeDeferralRequired = false
    }
    this.$store.dispatch("loading/setLoadComponent", false)
    this.loaded = true
    this.saveToLocalStore()

    if (this.bookingProps.noResult) {
      utilities.showDialogInfo('No matching claimants found; please enter the details for the new claimant.')
    }

    if (this.bookingProps.bookingId != undefined && this.bookingProps.bookingId != '') {
      this.tapNext()
    }

    $('.behalf').keydown((e) => {
        var code = e.keyCode || e.which;
        if (code === 9) {
          this.openAddNameModal()
        }
    });

    $('.date-of-birth').keydown((e) => {
        var code = e.keyCode || e.which;
        if (code === 9) {
          this.openManageContactDetails()
        }
    });

    $('.accient-date').keydown((e) => {
        var code = e.keyCode || e.which;
        if (code === 9) {
          this.claimFocus = true
        }
    });

    setTimeout(() => {
      $(".dropdown-input").attr({
       autocomplete: "off",
      })
    }, 1000)
    utilities.initTooltip()
  },
  mounted() {
    setTimeout(() => {
      $(".dropdown-input").attr({
       autocomplete: "off",
      })

      this.defineClearButton()
    }, 500)
  },
}
</script>
